import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './translations/translationEN.json';
import translationFR from './translations/translationFR.json';
import translationIT from './translations/translationIT.json';
import translationDE from './translations/translationDE.json';
import translationTK from './translations/translationTK.json';
import translationES from './translations/translationES.json';

const resources: Resource = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
  de: {
    translation: translationDE,
  },
  tr: {
    translation: translationTK,
  },
  es: {
    translation: translationES,
  },
};

i18n
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',

    // we do not use keys in form messages.welcome
    keySeparator: false,
    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },
  });

export default i18n;
