import { useEffect, useState } from 'react';

import useAuth from '../useAuth';

import { ROLES_ALLOWED_TO_ACCESS_CALIBRATION } from '../../constants/security';
import { calibrationApi } from '../../api/calibrationApi';
import { calibrationSelectors } from '../../store/selectors';
import { useSelector } from 'react-redux';
import usePrevious from '../shared/usePrevious';
import useCampaignId from '../campaign/useCampaignId';

export const useCountPendingCalibrationsCount = (): number => {
  const { hasRole } = useAuth();
  const [campaignId] = useCampaignId();
  const refreshFlag = useSelector(calibrationSelectors.getCalibrationsCountRefreshFlag);
  const prevRefreshFlag = usePrevious(refreshFlag);
  const [pendingCalibrationsCount, setCount] = useState(0);

  useEffect(() => {
    if (!hasRole(ROLES_ALLOWED_TO_ACCESS_CALIBRATION) || refreshFlag === prevRefreshFlag) {
      return;
    }
    calibrationApi
      .countPendingCalibrations()
      .then(({ data }) => setCount(data))
      .catch(console.error);
  }, [refreshFlag, campaignId]);

  return pendingCalibrationsCount;
};
