import { ICampaignPreviewData } from '../../../providers/ICampaignPreviewProvider';
import { IKeyObject, IValueId } from './IHelpers';

interface IUrl {
  id: string;
  value: string;
}

export const getValueById = (list: IUrl[], id: string): string => {
  const searchedValue = list.find((element) => element.id === id);

  if (searchedValue) {
    return searchedValue.value;
  } else {
    return '';
  }
};

export const mergeArrays = (first: IValueId[], second: IValueId[]): IValueId[] => {
  if (!first?.length) {
    return second;
  }

  if (!second?.length) {
    return first;
  }

  const acc: IKeyObject<IValueId> = {};
  first.forEach((val) => (acc[val.id] = val));
  second.forEach((val) => (acc[val.id] = val));

  return Object.values(acc);
};
