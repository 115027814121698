import axios from 'axios';
import storageUtils from '../utils/storageUtils';
import routePaths from '../routes/routePaths';

const baseURL: string = process.env.REACT_APP_BASE_URL || '';

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 300000,
});

axiosInstance.interceptors.request.use((config) => {
  const token = storageUtils.getLocalStorageValue('authToken');
  config.headers = Object.assign(
    {
      Authorization: token,
    },
    config.headers,
  );

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      storageUtils.clearLocalUserData();
      const hashCode = error.response?.data?.code === '40101' ? '#40301' : '#40103';
      window.location.href = routePaths.basename + routePaths.login + hashCode;
    }

    return Promise.reject(error);
  },
);
