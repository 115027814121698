import { ISelectOption, ISortList } from '../shared/helpers/IHelpers';

export const CallImportErrorTypeValues = {
  MISSING_LOGIN_CODE: 'MISSING_LOGIN_CODE',
  MISSING_VDN: 'MISSING_VDN',
  MISSING_ALLOCATIONS: 'MISSING_ALLOCATIONS',
  VDN_NOT_CONFIGURED: 'VDN_NOT_CONFIGURED',
  VDN_CONFIGURED_ON_MULTIPLE_CAMPAIGNS: 'VDN_CONFIGURED_ON_MULTIPLE_CAMPAIGNS',
  NULL: 'NULL',
} as const;

export type ICallImportErrorType = typeof CallImportErrorTypeValues[keyof typeof CallImportErrorTypeValues];

export const CallCloudBucketOriginValues = {
  CX_ONE: 'CX_ONE',
  MY_REC: 'MY_REC',
  ROC: 'ROC',
  AMAZON_CONNECT: 'AMAZON_CONNECT',
};

export interface ICallImportErrorReducer {
  importErrorsByType: Record<ICallImportErrorType, IPagedCallImportErrors>;
}

export interface IPagedCallImportErrors {
  values: ICallImportError[] | null;
  page: number;
  totalPages: number;
}

export type TCallCloudBucketOriginType = typeof CallCloudBucketOriginValues[keyof typeof CallCloudBucketOriginValues];

export type TCallImportErrorsSearchInput =
  | 'externalCallId'
  | 'vdnName'
  | 'vdnNumber'
  | 'telephonyLoginCode'
  | 'agent'
  | 'campaign'
  | 'fileName'
  | 'bucketOrigin'
  | 'reportPeriod';

export interface ICallImportErrorFiltersRequest {
  type?: string;
  externalCallId?: string;
  campaignId?: string;
  vdnName?: string;
  vdnNumber?: string;
  fileName?: string;
  agentId?: string;
  origin?: string;
  startDateTime?: string | null;
  endDateTime?: string | null;
  telephonyLoginCode?: string;
}

export interface ICallImportErrorSearchRequest extends ICallImportErrorFiltersRequest {
  pageRequest: {
    page: number;
    size: number;
  };
  sortingOptions: ISortList[];
}

export interface ICallErrorsSearchForm {
  reportPeriod: {
    startDateTime: Date | null;
    endDateTime: Date | null;
  };
  agent?: ISelectOption;
  telephonyLoginCode?: string;
  campaign?: ISelectOption;
  externalCallId?: string;
  vdnName?: string;
  vdnNumber?: string;
  fileName?: string;
  bucketOrigin?: ISelectOption;
}

export interface IMainSearch {
  onSubmit: (values: ICallErrorsSearchForm) => void;
  initialValues?: Partial<Record<ICallImportErrorType, Partial<ICallErrorsSearchForm>>>;
  selectedCallImportErrorType: ICallImportErrorType;
}

export interface ICallImportError {
  id: string;
  created: string;
  bucketOrigin: string;
  fileName: string;
  message: string;
  severity: string;
}
