// Should we group them swagger like ?
export default {
  importUsers: '/import-users',
  admins: '/admins',
  activity: '/activity',
  activities: '/activities',
  internalActivities: '/internal-activities',
  announcements: '/announcements',
  assessments: '/assessments',
  minScoreAssessments: '/min-score-assessments',
  performanceTrend: '/performance-trend',
  assesseeFeedback: '/assessee-feedback',
  unrestrictedSearch: '/unrestricted-search',
  call: '/calls',
  callsWithAnalysis: '/calls-with-analysis',
  callsWithThemeAnalysis: '/calls-with-theme-analysis',
  lists: '/lists',
  segments: '/segments',
  random: '/random',
  callFiles: '/files',
  campaigns: '/campaigns',
  owners: '/owners',
  own: '/own',
  creator: '/creator',
  login: '/login',
  logout: '/logout',
  saml: '/saml',
  forms: '/forms',
  availableChannels: '/available-channels',
  activeForms: '/active-forms',
  user: '/user',
  users: '/users',
  countries: '/countries',
  cases: '/cases',
  assessmentPlan: '/assessment-plans',
  changeCampaign: '/change-campaign',
  channels: '/channels',
  search: '/search',
  adminAccess: '/admin-access',
  markAsLeaver: '/mark-leaver',
  score: '/score',
  scoringComparison: '/scoring-comparison',
  callDurationRange: '/duration-range',
  minDuration: '/min-duration',
  directions: '/directions',
  locations: '/locations',
  availableLocations: '/available-locations',
  languages: '/languages',
  recording: '/recording',
  regions: '/regions',
  availableRegions: '/available-regions',
  callImportErrors: '/call-import-errors',
  vdnName: '/vdns',
  registeredVdns: '/registered-vdns',
  defaultRoleMappings: '/default-role-mappings',
  prefixes: '/prefixes',
  drafts: '/drafts',
  startWork: '/start-work',
  stopWork: '/stop-work',
  trackWork: '/track-work',
  names: '/names',
  improvementArea: '/improvement-area',
  statuses: '/statuses',
  language: '/language',
  review: '/review',
  dispute: '/dispute',
  request: '/request',
  start: '/start',
  validate: '/validate',
  common: '/common',
  scoringTypes: '/scoring-types',
  questionTypes: '/question-types',
  details: '/details',
  history: '/history',
  compute: '/compute',
  gradingRules: '/grading-rules',
  yesNoOptions: '/yes-no-options',
  withForms: '/with-forms',
  settings: '/settings',
  roles: '/roles',
  report: '/report',
  reports: '/reports',
  dashboard: '/dashboard',
  exports: '/exports',
  launch: '/launch',
  searchPreviews: '/search-previews',
  supervisors: '/supervisors',
  performance: '/performance',
  noAssessments: '/no-assessments',
  scoreDifference: '/score-difference',
  allocations: '/allocations',
  acknowledge: '/acknowledge',
  event: '/event',
  edit: '/edit',
  userPermissions: '/user-permissions',
  mailSettings: '/mail-settings',
  scoringTimeline: '/scoring-timeline',
  unacceptableTypeTimeline: '/unacceptable-type-timeline',
  callTimeline: '/call-timeline',
  statusTimeline: '/status-timeline',
  summary: '/summary',
  sections: '/sections',
  answersDistribution: '/answers-distribution',
  agentsRanking: '/agents-ranking',
  teamLeads: '/team-leads',
  team: '/team',
  teams: '/teams',
  types: '/types',
  agents: '/agents',
  disputeComparison: '/dispute-comparison',
  additionalQuestions: '/additional-questions',
  conditionalQuestions: '/conditional-questions',
  conditionalAdditionalQuestions: '/conditional-additional-questions',
  transcript: '/transcript',
  create: '/create',
  calibrationSessions: '/calibration-sessions',
  calibration: '/calibration',
  calibrators: '/calibrators',
  attendance: '/attendance',
  comment: '/comment',
  calibrations: '/calibrations',
  initiate: '/initiate',
  info: '/info',
  session: '/session',
  draft: '/draft',
  publish: '/publish',
  complete: '/complete',
  questions: '/questions',
  userTelephonyLogins: '/user-telephony-logins',
  tickets: '/tickets',
  comments: '/comments',
  resolve: '/resolve',
  changeStatus: '/change-status',
  changeBookmark: '/change-bookmark',
  changeInternalDevelopment: '/change-internal-development',
  changeVisibility: '/change-visibility',
  react: '/react',
  reactions: '/reactions',
  pending: '/pending',
  reimport: '/reimport',
  reimportSources: '/reimport-sources',
  import: '/import',
  errors: '/errors',
  loginCodes: '/telephony-logins',
  transcriptAnalysis: '/transcript-analysis',
  anonymization: '/anonymization',
  mailNotification: '/mail-notification',
  transcripts: '/transcripts',
  analysis: '/analysis',
  clusters: '/clusters',
  topics: '/topics',
  themeLabels: '/theme-labels',
  themes: '/themes',
  metrics: '/metrics',
  alias: '/alias',
  average: '/average',
  count: '/count',
  trend: '/trend',
  negativity: '/negativity',
  silence: '/silence',
  pace: '/pace',
  sites: '/sites',
  auditReport: '/audit-report',
  audit: '/export-audit',
  overtalkCount: '/overtalk-count',
  distribution: '/distribution',
  transcriptAnalysisRequest: '/transcript-analysis-request',
  models: '/models',
  activate: '/activate',
  rules: '/rules',
  deactivate: '/deactivate',
  vdns: '/vdns',
  unusedVdns: '/unused-vdns',
  unplannedUsers: '/unplanned-users',
} as const;
