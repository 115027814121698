import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/images/white-arrow.svg';
import useAuth from '../../hooks/useAuth';
import { getDefaultHomePageFor } from '../../routes/pathConstants';
import Button from '../shared/button/Button';
import { buttonSize, buttonVariant } from '../shared/button/constants';

import style from './ErrorComponent.module.scss';

import { IErrorComponent } from './IErrorBoundary';

const ErrorComponent: FC<IErrorComponent> = ({
  error: { message },
  resetState,
}: IErrorComponent) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userInfo } = useAuth().authInfo;

  const goBackHandler = (): void => {
    history.goBack();
    resetState();
  };

  const goHomeHandler = (): void => {
    history.push(getDefaultHomePageFor(userInfo));
    resetState();
  };

  const buttons = (
    <div className={style.buttonsWrapper}>
      <Button onClick={goBackHandler} variant={buttonVariant.primary} size={buttonSize.small}>
        {t('button.previous')}
      </Button>
      <Button onClick={goHomeHandler} variant={buttonVariant.primary} size={buttonSize.small}>
        {t('button.home')}
      </Button>
    </div>
  );

  const pageNotFoundError = (
    <div className={style.pageNotFound}>
      <p className={style.message}>{t('errorHandler.ops')}</p>
      <p className={style.code}>{message}</p>
      <p className={`${style.message} ${style.status}`}>{t('errorHandler.notFound')}</p>
      <p className={style.info}>{t('errorHandler.wentWrong')}</p>
      <p className={style.info}>{t('errorHandler.canGo')}</p>
      {buttons}
    </div>
  );

  const internalError = (
    <>
      <div className={style.internalError}>
        <p className={style.messageInternal}>{t('errorHandler.ops')}</p>
        <p className={style.codeInternal}>{message}</p>
        <p className={style.statusInternal}>{t('errorHandler.internalError')}</p>
        <p className={style.infoInternal}>{t('errorHandler.wentWrong')}</p>
        <p className={style.infoInternal}>{t('errorHandler.contactSomeone')}</p>
        <p className={style.infoInternal}>{t('errorHandler.canGo')}</p>
        {buttons}
      </div>
      <div className={style.iconWrapper}>
        <Arrow className={style.icon} />
        <Arrow className={style.icon} />
      </div>
    </>
  );

  return (
    <div
      className={`
      ${style.wrapper}
      ${style[`wrapper-${message === '404' ? 'notFound' : 'internal'}`]}
      `}>
      {message === '404' ? pageNotFoundError : internalError}
    </div>
  );
};

export default ErrorComponent;
