import { Role } from '../providers/IPopulatorProvider';

export const EXPORT_POLLING = 20000;

export enum ASSESSMENTS_REPORT_TYPE {
  ASSESSMENTS_OVERVIEW_REPORT = 'ASSESSMENTS_OVERVIEW_REPORT',
  ASSESSMENTS_FORM_LEVEL_REPORT = 'ASSESSMENTS_FORM_LEVEL_REPORT',
}

export const ROLES_ALLOWED_TO_DOWNLOAD_REPORTS: Role[] = [
  Role.QA_MANAGER,
  Role.ADMIN,
  Role.SUPER_ADMIN,
];

export const ROLES_ALLOWED_TO_DOWNLOAD_DASHBOARD_REPORTS: Role[] = [
  ...ROLES_ALLOWED_TO_DOWNLOAD_REPORTS,
  Role.TEAM_LEAD,
  Role.QA,
];

export const ROLES_ALLOWED_TO_DOWNLOAD_TICKET_REPORTS: Role[] = [Role.SUPER_ADMIN];
