import React, { FC, MouseEvent, ReactNode } from 'react';

import styles from './Button.module.scss';

interface IProps {
  children: ReactNode;
  id?: string;
  onClick?: (e?: MouseEvent<HTMLSpanElement>) => void;
  className?: string;
}

const LinkButton: FC<IProps> = ({
  children,
  id,
  onClick = () => {
    return;
  },
  className,
}) => (
  <span id={id} className={`${styles.linkButton} ${className ?? ''}`} onClick={onClick}>
    {children}
  </span>
);

export default LinkButton;
