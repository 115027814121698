// Taken from https://usehooks.com/
import { useEffect, RefObject } from 'react';

const useOutsideClick = (ref: RefObject<HTMLDivElement>, callback: () => void): void => {
  useEffect(() => {
    const listener = (e: MouseEvent): void => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref.current, callback]);
};

export default useOutsideClick;
