import { AxiosPromise } from 'axios';

import { axiosInstance } from './axiosClient';
import apiPaths from './apiPaths';
import { PagedSearch } from '../components/shared/interfaces';
import { IUser } from '../components/campaignManagement/shared/interfaces';
import { Role } from '../providers/IPopulatorProvider';
import { UUID } from '../interfaces';
import { IUserDetails, TUserData } from '../interfaces/user';
import { IUsersDetailsRequest, IUsersDetailsSetAdmins } from '../interfaces/userManagement';

interface IExtraFields {
  date?: string;
  includeLeavers?: boolean;
}

export const usersApi: {
  getUsers: (data: IUsersDetailsRequest) => AxiosPromise<PagedSearch<IUser>>;
  getUsersNames: (campaignId: string, roles: Role[], extraFields?: IExtraFields) => AxiosPromise;
  getSupervisors: (supervisorRole: Role) => AxiosPromise;
  setUsersAsAdmin: (admins: IUsersDetailsSetAdmins[]) => AxiosPromise;
  getUserDetails: (id: UUID) => AxiosPromise<IUserDetails>;
  updateAdminAccess: (id?: string, regionPermissions?: string[]) => AxiosPromise;
  markExternalUserAsLeaver: (id: string) => AxiosPromise;
  createExternalUser: (payload: TUserData) => AxiosPromise;
  editExternalUser: (id: string, data: TUserData) => AxiosPromise;
} = {
  getUsers: (data) => axiosInstance.post(apiPaths.users + apiPaths.search, data),
  getUsersNames: (campaignId: string, roles: Role[], extraFields = {}) =>
    axiosInstance.post(apiPaths.users + apiPaths.search + apiPaths.names, {
      campaignId,
      roles,
      ...extraFields,
    }),
  getUserDetails: (userId: UUID) => axiosInstance.get(`${apiPaths.users}/${userId}`),
  getSupervisors: (supervisorRole) =>
    axiosInstance.get(apiPaths.users + apiPaths.supervisors, { params: { supervisorRole } }),
  setUsersAsAdmin: (admins) => axiosInstance.put(apiPaths.users + apiPaths.admins, admins),
  updateAdminAccess: (id, regionPermissions) =>
    axiosInstance.patch(apiPaths.users + '/' + id + apiPaths.adminAccess, {
      regionPermissions: regionPermissions,
    }),
  markExternalUserAsLeaver: (id) =>
    axiosInstance.put(apiPaths.users + '/' + id + apiPaths.markAsLeaver),
  createExternalUser: (data) => axiosInstance.post(apiPaths.users, data),
  editExternalUser: (id, data) => axiosInstance.put(`${apiPaths.users}/${id}`, data),
};
