import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { assessmentPlanApi } from '../../api/assessmentPlanApi';
import {
  IAssessmentPlanGeneralInfo,
  UserAssessmentPlanType,
} from '../../components/assessmentPlan/IAssessmentPlan';
import usePrevious from '../shared/usePrevious';
import { assessmentPlanSelectors } from '../../store/selectors';
import { IAssessmentType } from '../../interfaces/assessment';

type IReturn = {
  isLoading: boolean;
  isUnbalancedPlan: boolean;
  isUnbalancedPlanByAssessmentType: Partial<Record<IAssessmentType, boolean>>;
};

export const useUnbalancedPlan = (date: string): IReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState<IAssessmentPlanGeneralInfo[]>([]);
  const [isUnbalancedPlan, setIsUnbalancedPlan] = useState(false);
  const [isUnbalancedPlanByAssessmentType, setIsUnbalancedPlanByAssessmentType] = useState<
    Partial<Record<IAssessmentType, boolean>>
  >({
    ASSURANCE_CHECK: false,
    DEEP_DIVE: false,
    CLIENT_REQUESTED: false,
    NON_SCORING: false,
    TARGET_COACHING: false,
    MONTHLY_CHECK: false,
  });
  const refreshFlag = useSelector(assessmentPlanSelectors.getUnbalancedPlanRefreshFlag);
  const prevRefreshFlag = usePrevious(refreshFlag);

  useEffect(() => {
    if (isLoading || !date || refreshFlag === prevRefreshFlag) {
      return;
    }

    setIsLoading(true);
    assessmentPlanApi
      .getPlannedCountReport(date)
      .then(({ data }) => setReport(data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, [date, refreshFlag]);

  useEffect(() => {
    const unbalancedByAssessmentType: Partial<Record<IAssessmentType, boolean>> = {};

    report
      .filter((r) => r.planType === UserAssessmentPlanType.ASSESSEE)
      .forEach((r) => {
        const assessorPlan = report.find(
          (ap) =>
            ap.planType === UserAssessmentPlanType.ASSESSOR &&
            ap.assessmentType === r.assessmentType,
        );

        unbalancedByAssessmentType[r.assessmentType] =
          (!assessorPlan && r.plannedAssessments > 0) ||
          (!!assessorPlan && r.plannedAssessments > assessorPlan.plannedAssessments);
      });

    setIsUnbalancedPlanByAssessmentType(unbalancedByAssessmentType);
    setIsUnbalancedPlan(Object.values(unbalancedByAssessmentType).some((at) => at === true));
  }, [report]);

  return {
    isLoading,
    isUnbalancedPlan,
    isUnbalancedPlanByAssessmentType,
  };
};
