import { AxiosPromise } from 'axios';
import {
  IAgentPerformance,
  IAssessmentPerformanceReportResponse,
  IAssessmentPerformanceTrendResult,
  ICalibrationReportResponse,
  ICalibrationSectionPerformanceSummaryResponse,
  IDashboardRequest,
  IDashboardUnacceptableTypeTimeline,
  IImprovementAreaTimelineSummaryResponse,
  IMinScoreAssessmentPerformanceTrendResult,
  IQuestionSummary,
  IQuestionSummaryReportResponse,
  ISectionPerformanceTrendResult,
  ISectionQuestionSummary,
  ISectionResultsSummary,
  ITeamLeadPerformance,
  ITeamLeadPerformanceResponse,
  IThemeCountReportResponse,
  IThemeLabelTrendReportResponse,
  ITopicCountReportResponse,
  ITopicTimelineReport,
  TAdditionalTranscriptFilter,
  TCalibrationScoreDifferenceResponse,
  TMetricsReport,
  TMetricsTimeLineReport,
  TQuestionPerformanceTrendResult,
  TSectionPerformanceData,
  TUserMetricsReport,
} from '../components/dashboard/interfaces/IDashboard';
import { IValueId } from '../components/shared/helpers/IHelpers';
import { PagedSearch } from '../components/shared/interfaces';
import { ReportingSort } from '../constants/dashboard';
import { UUID } from '../interfaces';
import {
  ICallBaseWithThemeTranscriptAnalysis,
  ICallBaseWithTranscriptAnalysis,
} from '../interfaces/call';
import { SectionDiscriminator } from '../interfaces/form';
import {
  ILaunchExportData,
  IMetricDistributionReport,
  TCallsImportErrorsExport,
  TeamCountReport,
  TLaunchAuditExport,
  TLaunchCalibrationDataExport,
  TLaunchFormDataExport,
} from '../interfaces/report';
import { IPage } from '../interfaces/telephonyLogin';
import apiPaths from './apiPaths';

import { axiosInstance } from './axiosClient';
import { IUserTicketFilter } from '../interfaces/userTicket';
import { IUserAllocationsRequest } from '../components/campaignManagement/shared/interfaces';

const {
  auditReport,
  reports,
  assessments,
  minScoreAssessments,
  performanceTrend,
  calibrations,
  score,
  dashboard,
  scoringComparison,
  summary,
  sections,
  questions,
  scoreDifference,
  answersDistribution,
  launch,
  scoringTimeline,
  unacceptableTypeTimeline,
  statusTimeline,
  agentsRanking,
  teamLeads,
  team,
  teams,
  agents,
  disputeComparison,
  performance,
  noAssessments,
  additionalQuestions,
  exports,
  conditionalQuestions,
  conditionalAdditionalQuestions,
  call,
  callsWithAnalysis,
  callsWithThemeAnalysis,
  errors,
  transcriptAnalysis,
  topics,
  themeLabels,
  callTimeline,
  metrics,
  average,
  negativity,
  pace,
  users,
  audit,
  tickets,
  overtalkCount,
  distribution,
  silence,
  count,
  trend,
  improvementArea,
} = apiPaths;

export type IAdditionalQuestionFilterRequest = {
  selectedAdditionalAnswerIds?: UUID[];
  selectedConditionalAdditionalAnswerIds?: UUID[];
};

type AgentPerformanceTrendRequest = IDashboardRequest & {
  pageRequest: { page: number; size: number };
  teamLeadId: string | null;
};
type TeamPerformanceRequest = IDashboardRequest & { pageRequest: { page: number; size: number } };

export type TThemeValuesRequest = { themeValues: { id: string; values: string[] }[] };
export const reportApi: {
  launchExport: (data: ILaunchExportData) => AxiosPromise<string>;
  launchDashboardExport: (data: TLaunchFormDataExport) => AxiosPromise<string>;
  launchCalibrationDashboardExport: (data: TLaunchCalibrationDataExport) => AxiosPromise<string>;
  launchCallsImportErrorExport: (data: TCallsImportErrorsExport) => AxiosPromise<string>;
  launchUserAuditExport: (data: TLaunchAuditExport) => AxiosPromise<string>;
  launchUserTicketExport: (data: IUserTicketFilter) => AxiosPromise<string>;
  launchAuditReportExport: (data: IUserAllocationsRequest) => AxiosPromise<string>;
  retrieveAuditExport: (filename: string) => AxiosPromise<string>;
  retrieveExport: (filename: string) => AxiosPromise<string>;
  getSectionSummaryReport: (
    filters: IDashboardRequest & IAdditionalQuestionFilterRequest,
  ) => AxiosPromise<ISectionResultsSummary[]>;
  getSectionQuestionsSummary: (
    filters: IDashboardRequest & IAdditionalQuestionFilterRequest,
  ) => AxiosPromise<ISectionQuestionSummary[]>;
  getSectionPerformance: (
    filters: IDashboardRequest & IAdditionalQuestionFilterRequest,
  ) => AxiosPromise<IAssessmentPerformanceReportResponse>;
  getScoreTimeline: (data: IDashboardRequest) => AxiosPromise;
  getTeamLeadAssessmentPerformanceTrend: (
    data: TeamPerformanceRequest,
  ) => AxiosPromise<PagedSearch<IAssessmentPerformanceTrendResult>>;
  getTeamAssessmentPerformanceTrend: (
    data: AgentPerformanceTrendRequest,
  ) => AxiosPromise<PagedSearch<IAssessmentPerformanceTrendResult>>;
  getTeamLeadMinScoreAssessmentPerformanceTrend: (
    data: TeamPerformanceRequest,
  ) => AxiosPromise<PagedSearch<IMinScoreAssessmentPerformanceTrendResult>>;
  getTeamMinScoreAssessmentPerformanceTrend: (
    data: AgentPerformanceTrendRequest,
  ) => AxiosPromise<PagedSearch<IMinScoreAssessmentPerformanceTrendResult>>;
  getTeamLeadSectionsPerformanceTrend: (
    data: TeamPerformanceRequest,
  ) => AxiosPromise<ISectionPerformanceTrendResult<IValueId | undefined>>;
  getAgentsSectionsPerformanceTrend: (
    data: AgentPerformanceTrendRequest,
  ) => AxiosPromise<ISectionPerformanceTrendResult>;
  getTeamLeadQuestionsPerformanceTrend: (
    data: TeamPerformanceRequest,
  ) => AxiosPromise<TQuestionPerformanceTrendResult<IValueId | undefined>>;
  getAgentsQuestionsPerformanceTrend: (
    data: AgentPerformanceTrendRequest,
  ) => AxiosPromise<TQuestionPerformanceTrendResult>;
  getUnacceptableTypeTimeline: (
    data: IDashboardRequest,
  ) => AxiosPromise<IDashboardUnacceptableTypeTimeline[]>;
  getTopicsTimeline: (data: IDashboardRequest) => AxiosPromise<ITopicTimelineReport[]>;
  getCalibrationScoring: (data: IDashboardRequest) => AxiosPromise<ICalibrationReportResponse[]>;
  getCalibrationSectionScoreDifference: (
    data: IDashboardRequest,
  ) => AxiosPromise<ICalibrationSectionPerformanceSummaryResponse[]>;
  getCalibrationQuestionScoreDifference: (
    data: IDashboardRequest,
  ) => AxiosPromise<IQuestionSummaryReportResponse<TCalibrationScoreDifferenceResponse>>;
  getAssessmentQuestionPerformance: (
    data: IDashboardRequest,
  ) => AxiosPromise<
    IQuestionSummaryReportResponse<
      TSectionPerformanceData<
        SectionDiscriminator.PERCENTILE_GROUPINGS | SectionDiscriminator.SUMMED_SCORE
      >
    >
  >;
  getImprovementAreaPerformance: (
    data: IDashboardRequest,
  ) => AxiosPromise<IImprovementAreaTimelineSummaryResponse>;
  getStatusTimeline: (data: IDashboardRequest) => AxiosPromise;
  getSummary: (data: IDashboardRequest) => AxiosPromise;
  getAreaImprovementSummary: (data: IDashboardRequest) => AxiosPromise;
  getAgentRanking: (
    data: IDashboardRequest,
    maxResults: number,
    reportingSort: ReportingSort,
  ) => AxiosPromise<IAgentPerformance>;
  getTeamLeads: (
    data: TeamPerformanceRequest,
    reportingSort: ReportingSort,
  ) => AxiosPromise<PagedSearch<ITeamLeadPerformanceResponse>>;
  getTeamLeadsWithAgentsWithoutAssessments: (
    data: TeamPerformanceRequest,
  ) => AxiosPromise<PagedSearch<TeamCountReport>>;
  getTeamWithoutAssessments: (
    data: IDashboardRequest,
    teamLeadId?: string,
  ) => AxiosPromise<PagedSearch<IValueId>>;
  getTeam: (
    data: IDashboardRequest,
    teamLeadId: string,
    reportingSort: ReportingSort,
  ) => AxiosPromise<PagedSearch<ITeamLeadPerformance>>;
  getScoringComparison: (data: IDashboardRequest) => AxiosPromise;
  getAdditionalQuestions: (data: IDashboardRequest) => AxiosPromise<IQuestionSummary[]>;
  getConditionalQuestions: (
    data: IDashboardRequest,
    questionId: UUID,
  ) => AxiosPromise<IQuestionSummary[]>;
  getConditionalAdditionalQuestions: (
    data: IDashboardRequest,
    questionId: UUID,
  ) => AxiosPromise<IQuestionSummary[]>;
  getTopicsCount(
    filters: IDashboardRequest & TAdditionalTranscriptFilter,
  ): AxiosPromise<ITopicCountReportResponse[]>;
  getThemeLabelsCount(
    filters: IDashboardRequest & TThemeValuesRequest,
  ): AxiosPromise<IThemeCountReportResponse[]>;
  getThemeLabelsTrend(
    filters: IDashboardRequest & TThemeValuesRequest,
  ): AxiosPromise<IThemeLabelTrendReportResponse[]>;
  getNegativityDistribution(
    filters: IDashboardRequest & TAdditionalTranscriptFilter,
  ): AxiosPromise<IMetricDistributionReport[]>;
  getPaceDistribution(
    filters: IDashboardRequest & { interval: number } & TAdditionalTranscriptFilter,
  ): AxiosPromise<IMetricDistributionReport[]>;
  getMetricsSummary(
    filters: IDashboardRequest & TAdditionalTranscriptFilter,
  ): AxiosPromise<TMetricsReport>;
  getSilenceDistribution(
    filters: IDashboardRequest & { interval: number } & TAdditionalTranscriptFilter,
  ): AxiosPromise<IMetricDistributionReport[]>;
  getOvertalkCountPerMinuteDistribution(
    filters: IDashboardRequest & { interval: number } & TAdditionalTranscriptFilter,
  ): AxiosPromise<IMetricDistributionReport[]>;
  getMetricsTimeline: (
    data: IDashboardRequest & TAdditionalTranscriptFilter,
  ) => AxiosPromise<TMetricsTimeLineReport[]>;
  getTeamMetrics: (
    data: IDashboardRequest & TAdditionalTranscriptFilter,
  ) => AxiosPromise<TUserMetricsReport[]>;
  getAgentMetrics: (
    data: IDashboardRequest & TAdditionalTranscriptFilter,
  ) => AxiosPromise<TUserMetricsReport[]>;
  getReportCalls(
    filters: IDashboardRequest & TAdditionalTranscriptFilter & IPage,
  ): AxiosPromise<PagedSearch<ICallBaseWithTranscriptAnalysis>>;
  getReportCallsWithThemes(
    filters: IDashboardRequest & TThemeValuesRequest & IPage,
  ): AxiosPromise<PagedSearch<ICallBaseWithThemeTranscriptAnalysis>>;
} = {
  getUnacceptableTypeTimeline: (data) =>
    axiosInstance.post(reports + assessments + unacceptableTypeTimeline, data),
  getReportCalls: (filters) => axiosInstance.post(reports + callsWithAnalysis, filters),
  getReportCallsWithThemes: (filters) =>
    axiosInstance.post(reports + callsWithThemeAnalysis, filters),
  getMetricsSummary: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + metrics + average, filters),
  getTopicsCount: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + topics + count, filters),
  getThemeLabelsCount: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + themeLabels + count, filters),
  getThemeLabelsTrend: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + themeLabels + trend, filters),
  getNegativityDistribution: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + negativity + distribution, filters),
  getSilenceDistribution: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + silence + distribution, filters),
  getPaceDistribution: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + pace + distribution, filters),
  getOvertalkCountPerMinuteDistribution: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + overtalkCount + distribution, filters),
  getTopicsTimeline: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + topics + callTimeline, filters),
  getMetricsTimeline: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + metrics + callTimeline, filters),
  getTeamMetrics: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + team + metrics, filters),
  getAgentMetrics: (filters) =>
    axiosInstance.post(reports + transcriptAnalysis + agents + metrics, filters),
  getSectionSummaryReport: (filters) =>
    axiosInstance.post(reports + assessments + score + summary, filters),
  launchDashboardExport: (filters) =>
    axiosInstance.post(reports + assessments + dashboard + exports + launch, filters),
  launchCalibrationDashboardExport: (filters) =>
    axiosInstance.post(reports + calibrations + dashboard + exports + launch, filters),
  launchCallsImportErrorExport: (filters) =>
    axiosInstance.post(reports + call + apiPaths.import + errors + exports + launch, filters),
  getCalibrationScoring: (filters) =>
    axiosInstance.post(reports + calibrations + scoringComparison, filters),
  getCalibrationSectionScoreDifference: (filters) =>
    axiosInstance.post(reports + calibrations + sections + scoreDifference, filters),
  getCalibrationQuestionScoreDifference: (filters) =>
    axiosInstance.post(reports + calibrations + questions + scoreDifference, filters),
  getAssessmentQuestionPerformance: (filters) =>
    axiosInstance.post(reports + assessments + questions + performance, filters),
  getImprovementAreaPerformance: (filters) =>
    axiosInstance.post(reports + assessments + improvementArea + performance, filters),
  getSectionPerformance: (filters) =>
    axiosInstance.post(reports + assessments + sections + performance, filters),
  getSectionQuestionsSummary: (filters) =>
    axiosInstance.post(reports + assessments + answersDistribution, filters),
  launchExport: (data) => axiosInstance.post(reports + assessments + exports + launch, data),
  launchAuditReportExport: (data: IUserAllocationsRequest) =>
    axiosInstance.post(reports + auditReport + exports + launch, data),
  retrieveExport: (filename: string) =>
    axiosInstance.get(reports + assessments + exports, {
      params: {
        filename,
      },
    }),
  launchUserAuditExport: (data) => axiosInstance.post(reports + users + audit, data),
  retrieveAuditExport: (filename: string) =>
    axiosInstance.get(reports + exports, {
      params: {
        filename,
      },
    }),
  launchUserTicketExport: (data) => axiosInstance.post(reports + tickets + exports + launch, data),
  getScoreTimeline: (data) => axiosInstance.post(reports + assessments + scoringTimeline, data),
  getTeamLeadAssessmentPerformanceTrend: (data) =>
    axiosInstance.post(reports + performanceTrend + assessments + teams, data),
  getTeamAssessmentPerformanceTrend: (data) =>
    axiosInstance.post(reports + performanceTrend + assessments + agents, data),
  getTeamLeadMinScoreAssessmentPerformanceTrend: (data) =>
    axiosInstance.post(reports + performanceTrend + minScoreAssessments + teams, data),
  getTeamMinScoreAssessmentPerformanceTrend: (data) =>
    axiosInstance.post(reports + performanceTrend + minScoreAssessments + agents, data),
  getTeamLeadSectionsPerformanceTrend: (data) =>
    axiosInstance.post(reports + performanceTrend + sections + teams, data),
  getAgentsSectionsPerformanceTrend: (data) =>
    axiosInstance.post(reports + performanceTrend + sections + agents, data),
  getTeamLeadQuestionsPerformanceTrend: (data) =>
    axiosInstance.post(reports + performanceTrend + questions + teams, data),
  getAgentsQuestionsPerformanceTrend: (data) =>
    axiosInstance.post(reports + performanceTrend + questions + agents, data),
  getStatusTimeline: (data) => axiosInstance.post(reports + assessments + statusTimeline, data),
  getSummary: (data) => axiosInstance.post(reports + assessments + summary, data),
  getAreaImprovementSummary: (data) =>
    axiosInstance.post(reports + assessments + summary + improvementArea, data),
  getAgentRanking: (data, maxResults, reportingSort) =>
    axiosInstance.post(reports + performance + agentsRanking, data, {
      params: { maxResults, reportingSort },
    }),
  getTeamLeads: (data, reportingSort) =>
    axiosInstance.post(reports + performance + teamLeads, data, { params: { reportingSort } }),
  getTeamLeadsWithAgentsWithoutAssessments: (data) =>
    axiosInstance.post(reports + noAssessments + teamLeads, data),
  getTeam: (data, teamLeadId, reportingSort) =>
    axiosInstance.post(reports + performance + teamLeads + `/${teamLeadId}` + team, data, {
      params: { reportingSort },
    }),
  getTeamWithoutAssessments: (data, teamLeadId) =>
    axiosInstance.post(reports + noAssessments + teamLeads + team, { ...data, teamLeadId }),
  getScoringComparison: (data) =>
    axiosInstance.post(reports + assessments + scoringTimeline + disputeComparison, data),
  getAdditionalQuestions: (data) =>
    axiosInstance.post(reports + assessments + additionalQuestions + answersDistribution, data),
  getConditionalQuestions: (data, questionId) =>
    axiosInstance.post(reports + assessments + conditionalQuestions + answersDistribution, {
      ...data,
      questionId,
    }),
  getConditionalAdditionalQuestions: (data, questionId) =>
    axiosInstance.post(
      reports + assessments + conditionalAdditionalQuestions + answersDistribution,
      {
        ...data,
        questionId,
      },
    ),
};
