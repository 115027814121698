import { es, tr, it, enGB, de, fr } from 'date-fns/locale';

import { registerLocale } from 'react-datepicker';

export const datepickerTranslator = (): void => {
  registerLocale('fr', fr);
  registerLocale('en', enGB);
  registerLocale('es', es);
  registerLocale('tr', tr);
  registerLocale('it', it);
  registerLocale('de', de);
};

export const parseDate = (value: Date): string => {
  if (!value) {
    return '';
  }
  const tzOffset = value.getTimezoneOffset() * 60000;
  const localISOTime = new Date(value.getTime() - tzOffset).toISOString();

  return localISOTime.substr(0, localISOTime.length - 5);
};

export const parseDateTime = (value?: Date): Date => {
  if (!value) {
    value = new Date();
  }
  const tzOffset = value.getTimezoneOffset() * 60000;

  return new Date(value.getTime() + tzOffset);
};

export const toDate = (date?: string | null): Date | null => {
  return date && !isNaN(Date.parse(date)) ? new Date(date) : null;
};

export const onlyDate = (date: string): string => {
  return date.substr(0, 10);
};

/**
 * @deprecated use formatDate from dateUtils
 * @param date
 */
export const formatDate = (date: string): string => {
  const justDate = onlyDate(date).split('-');

  return `${justDate[2]}.${justDate[1]}.${justDate[0]} ${date.substr(
    date.length - 8,
    date.length,
  )}`;
};

export const convertToMinute = (second: number): string => {
  if (second < 60) {
    return second < 10 ? `00:0${second}` : `00:${second}`;
  }

  const hours = Math.floor(second / 3600);
  const minutes = Math.floor((second % 3600) / 60);
  const seconds = Math.floor((second % 3600) % 60);

  if (hours === 0) {
    return (
      (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)
    );
  }

  return (
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds)
  );
};

export const validateDateField = (
  startDate: Date | null,
  endDate: Date | null,
  message: string,
): string | undefined => {
  let error;
  startDate?.setHours(0, 0, 0, 0);
  endDate?.setHours(0, 0, 0, 0);

  if (startDate && endDate && startDate > endDate) {
    error = message;
  }

  return error;
};

export const convertToMinuteForPlayer = (time: number): string => convertToMinute(Math.floor(time));
