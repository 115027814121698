import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AGENT_FIRST_PAGINATION_SIZE,
  ASSESSMENT_PLAN_FETCH_SIZE,
  FIRST_PAGINATION_SIZE,
  USER_MANAGEMENT_PAGINATION_SIZE,
} from '../../components/shared/constants';

export const settingsReducerInitialState = {
  pageSizes: {
    assessments: FIRST_PAGINATION_SIZE,
    auditReportUserAllocations: FIRST_PAGINATION_SIZE,
    auditReportVdnConnections: FIRST_PAGINATION_SIZE,
    auditReportDefaultRoleMapping: FIRST_PAGINATION_SIZE,
    calls: FIRST_PAGINATION_SIZE,
    reportCalls: FIRST_PAGINATION_SIZE,
    form: FIRST_PAGINATION_SIZE,
    campaign: FIRST_PAGINATION_SIZE,
    collectiveDashboard: FIRST_PAGINATION_SIZE,
    agentNumber: AGENT_FIRST_PAGINATION_SIZE,
    calibration: FIRST_PAGINATION_SIZE,
    telephonyLogin: FIRST_PAGINATION_SIZE,
    announcement: FIRST_PAGINATION_SIZE,
    tickets: FIRST_PAGINATION_SIZE,
    location: FIRST_PAGINATION_SIZE,
    user: USER_MANAGEMENT_PAGINATION_SIZE,
    assessmentPlanFetchSize: ASSESSMENT_PLAN_FETCH_SIZE,
  },
};

export type TPageSizes = typeof settingsReducerInitialState.pageSizes;

type TChangePageSize = {
  page: keyof TPageSizes;
  size: number;
};

const settingsReducer = createSlice({
  name: 'settingsReducer',
  initialState: settingsReducerInitialState,
  reducers: {
    setPageSize: (state, { payload }: PayloadAction<TChangePageSize>) => {
      state.pageSizes[payload.page] = payload.size;
    },
  },
});

export const settingsActions = settingsReducer.actions;

export default settingsReducer.reducer;
