export type TLanguageValues = typeof LANGUAGE_LIST[number]['value'];

export const LANGUAGE_LIST = [
  { label: 'EN', value: 'en' },
  { label: 'FR', value: 'fr' },
  { label: 'IT', value: 'it' },
  { label: 'DE', value: 'de' },
  { label: 'TK', value: 'tr' },
  { label: 'ES', value: 'es' },
] as const;
