import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';
import routePaths from './routes/routePaths';

import { ErrorBoundary } from './components/errorBoundary/ErrorBoundary';

import App from './App';
import store from './store';

// import i18n (needs to be bundled ;))
import './i18n';

import './assets/styles/base/index.scss';

if (
  process.env.REACT_APP_ENV_TYPE === 'pre-production' ||
  process.env.REACT_APP_ENV_TYPE === 'production'
) {
  require('./monitoring');
}

render(
  <Provider store={store}>
    <BrowserRouter basename={routePaths.basename}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// @ts-ignore Add store on window when running e2e tests
if (window.Cypress) window.store = store;
