import { ISortList } from '../components/shared/helpers/IHelpers';

export const PAGINATION = {
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
  SET_PAGE: 'SET_PAGE',
  SORT: 'SORT',
  SEARCH: 'SEARCH',
  REFRESH: 'REFRESH',
} as const;

type TChangeValuesAction<T> = {
  type: typeof PAGINATION.SEARCH;
  values: T;
  sortingOptions?: ISortList;
};
type TChangePageAction = {
  type: typeof PAGINATION.SET_PAGE;
  page: number;
};
export type TSortAction = {
  type: typeof PAGINATION.SORT;
  id: string;
  isCustomSort?: boolean;
  customSortFilterValue?: string;
};
type TRefreshAction = {
  type: typeof PAGINATION.REFRESH;
};

export type TPagedReducerBaseActions<T> =
  | TChangeValuesAction<T>
  | TChangePageAction
  | TSortAction
  | TRefreshAction;

export const PROPERTY_NAMES = {
  CASE_REFERENCE: 'CASE_REFERENCE',
  DURATION: 'DURATION',
  DATE_TIME: 'DATE_TIME',
  REQUESTED_INITIATED_DATETIME: 'REQUESTED_INITIATED_DATETIME',
  ASSESSMENT_DATE: 'ASSESSMENT_DATE',
  STATUS: 'ASSESSMENT_STATUS',
  NAME: 'NAME',
  CREATION_DATE: 'CREATION_DATE',
  CAMPAIGN_REFERENCE: 'CAMPAIGN_REFERENCE',
  CAMPAIGN_NAME: 'CAMPAIGN_NAME',
  AGENT_NAME: 'AGENT_NAME',
  QA: 'ASSESSOR_NAME',
  TEAM_LEAD_NAME: 'TEAM_LEAD_NAME',
  LAST_MODIFIED_DATE: 'LAST_MODIFIED_DATE',
  NEGATIVE_SCORE_AVG: 'NEGATIVE_SCORE_AVG',
  OVERTALK_COUNT_PER_MINUTE: 'OVERTALK_COUNT_PER_MINUTE',
  PACE_AVG: 'PACE_AVG',
  SILENCE_PER_MINUTE: 'SILENCE_PER_MINUTE',
};

export const ASSESSMENT_WITHOUT_CALL = {
  CLOSE: 'CLOSE',
  SET_CASE: 'SET_CASE',
  OPEN: 'OPEN',
};
