import { ISelectOption } from '../../shared/helpers/IHelpers';

export const STATUSES = [
  'SUBMITTED',
  'IN_REVIEW',
  'IN_PROGRESS',
  'DRAFT',
  'DONE',
  'FEEDBACK_REQUIRED',
  'DISPUTE_REQUESTED',
  'DISPUTE_IN_PROGRESS',
  'DISPUTE_DRAFT',
  'DISPUTE_CLOSED',
];

export const STATUS_DISTRIBUTION_COLORS = [
  '#003D5B',
  '#004650',
  '#007380',
  '#00BED2',
  '#2FD8C4',
  '#25E2CC',
  '#63b4a1',
  '#cc3262',
  '#FFB1B8',
  '#fbca1b',
];

export const AGENT_PERFORMING_NUMBER: ISelectOption<string, number>[] = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
];

export enum AGGREGATION_GRANULARITY {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum ASSESSMENT_FORM_LEVEL_FILTER_TYPE {
  SCORE = 'SCORE',
  PASS_FAIL = 'PASS_FAIL',
  CRITICAL_FAIL = 'CRITICAL_FAIL',
}

export enum FORM_DASHBOARD_SEGMENT {
  SCORING_INFORMATION = 'SCORING_INFORMATION',
  PERFORMANCE = 'PERFORMANCE',
  PERFORMANCE_TREND = 'PERFORMANCE_TREND',
}

export const AGGREGATION_GRANULARITY_OPTIONS: ISelectOption<string, AGGREGATION_GRANULARITY>[] = [
  { label: 'dashboard.aggregation.day', value: AGGREGATION_GRANULARITY.DAY },
  { label: 'dashboard.aggregation.week', value: AGGREGATION_GRANULARITY.WEEK },
  { label: 'dashboard.aggregation.month', value: AGGREGATION_GRANULARITY.MONTH },
];

export const DEFAULT_AGGREGATION_GRANULARITY = AGGREGATION_GRANULARITY_OPTIONS[0];
