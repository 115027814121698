import { IStore } from '../index';
import { TUserId } from '../../interfaces/general';
import {
  IUserAssessmentPlanData,
  UserAssessmentPlanType,
} from '../../components/assessmentPlan/IAssessmentPlan';

export const getAssessmentAssesseePlansData = (state: IStore) =>
  state.assessmentPlanReducer.assesseePlansData;

export const getAssessmentAssesseeCurrentPlanData = (
  state: IStore,
): Record<TUserId, IUserAssessmentPlanData> | undefined =>
  state.assessmentPlanReducer.assesseePlansData[state.assessmentPlanReducer.selectedPlans[0]];

export const getAssessmentAssessorPlansData = (state: IStore) =>
  state.assessmentPlanReducer.assessorPlansData;

export const getAssessmentAssessorCurrentPlanData = (
  state: IStore,
): Record<TUserId, IUserAssessmentPlanData> | undefined =>
  state.assessmentPlanReducer.assessorPlansData[state.assessmentPlanReducer.selectedPlans[0]];

export const getSelectedPlans = (state: IStore) => state.assessmentPlanReducer.selectedPlans;

export const getAssessmentPlans = (state: IStore) => state.assessmentPlanReducer.plans;

export const getPlannedAssesseeUserData = (state: IStore) => {
  return state.assessmentPlanReducer.assesseePlannedUserData;
};

export const getUnplannedAssesseeUserData = (state: IStore) => {
  return state.assessmentPlanReducer.assesseeUnplannedUserData;
};

export const getVisibleAssesseeUserData = (state: IStore) => {
  return state.assessmentPlanReducer.assesseeVisibleUserData;
};

export const getPlannedAssessorUserData = (state: IStore) => {
  return state.assessmentPlanReducer.assessorPlannedUserData;
};

export const getUnplannedAssessorUserData = (state: IStore) => {
  return state.assessmentPlanReducer.assessorUnplannedUserData;
};

export const getVisibleAssessorUserData = (state: IStore) => {
  return state.assessmentPlanReducer.assessorVisibleUserData;
};

export const getAssessorChangedPlanData = (state: IStore) =>
  state.assessmentPlanReducer.assessorChangedPlanData;

export const getAssesseeChangedPlanData = (state: IStore) =>
  state.assessmentPlanReducer.assesseeChangedPlanData;

export const getSelectedAssessees = (state: IStore) =>
  state.assessmentPlanReducer.selectedAssessees;

export const getSelectedAssessors = (state: IStore) =>
  state.assessmentPlanReducer.selectedAssessors;

export const getAssessmentPlanGeneralInfo = (state: IStore) =>
  state.assessmentPlanReducer.assessmentPlanGeneralTable;

export const getShouldUnplannedAssesseesLoad = (state: IStore) =>
  state.assessmentPlanReducer.shouldUnplannedAssesseesLoad;

export const getShouldUnplannedAssessorsLoad = (state: IStore) =>
  state.assessmentPlanReducer.shouldUnplannedAssessorsLoad;

export const getUnbalancedPlanRefreshFlag = (state: IStore) =>
  state.assessmentPlanReducer.unbalancedPlanRefreshFlag;

export const getShowOnlyPlannedAssessees = (state: IStore) =>
  state.assessmentPlanReducer.showOnlyPlanned.includes(UserAssessmentPlanType.ASSESSEE);

export const getShowOnlyPlannedAssessors = (state: IStore) =>
  state.assessmentPlanReducer.showOnlyPlanned.includes(UserAssessmentPlanType.ASSESSOR);
