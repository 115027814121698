import React, { FC } from 'react';

import style from './DividerWithText.module.scss';

type Props = {
  text: string;
};

const DividerWithText: FC<Props> = ({ text }: Props) => {
  return (
    <div className={style.dividerWrapper}>
      <hr className={style.dividerLine} />
      <span className={style.dividerText}>{text}</span>
      <hr className={style.dividerLine} />
    </div>
  );
};

export default DividerWithText;
