import {
  IAgentPerformance,
  IAssessmentPerformanceReport,
  ICalibrationReportResponse,
  IDashboardFilters,
  IDashboardRequest,
  IDashboardScoringTimeline,
  IDashboardStatusTimeline,
  IDashboardSummary,
  IDashboardTimeline,
  IDashboardUnacceptableTypeTimeline,
  IFiltersChanged,
  IImprovementAreaSummary,
  IQuestionSummary,
  IScoringComparison,
  ISectionQuestionSummary,
  ISectionResultsSummary,
  ITeamLeadPerformance,
  IThemeDefinition,
  IThemeLabelCountReport,
  ITopicCountReport,
  ITopicTimeline,
  ITranscriptAnalysisMetricsDistribution,
  ITranscriptAnalysisSummaryMetrics,
  TAdditionalTranscriptFilter,
  TDashboardReportCalls,
  TDashboardReportCallsWithThemes,
  TMetricsTimeLineReport,
  TQuestionIdAnswerIdPair,
  TThemeLabelTrendReport,
  TUserMetricsReport,
} from '../../components/dashboard/interfaces/IDashboard';
import { IKeyObject, ISelectOption } from '../../components/shared/helpers/IHelpers';
import { UUID } from '../../interfaces';
import { ISearchCallsTopic } from '../../interfaces/call';
import { IStore } from '../index';
import { TThemeId } from '../../interfaces/general';

export const getSearchCounter = (state: IStore): boolean | undefined =>
  state.dashboardReducer.filters.searchCounter;
export const getFiltersWithoutIgnoredFields = (ignoredFields: (keyof IDashboardRequest)[]) => (
  state: IStore,
): IDashboardRequest => {
  const filters = { ...state.dashboardReducer.filters } as IDashboardRequest;
  ignoredFields.forEach((field) => delete filters[field]);

  return filters;
};
export const getSelectedAdditionalAnswerIds = (state: IStore): UUID[] =>
  state.dashboardReducer.additionalQuestionFilter;
export const getSelectedConditionalAdditionalAnswersWithQuestions = (
  state: IStore,
): TQuestionIdAnswerIdPair[] =>
  state.dashboardReducer.conditionalAdditionalQuestionAnswerPairFilter;
export const getTopicsTimeLine = (state: IStore): IDashboardTimeline<ITopicTimeline[]> =>
  state.dashboardReducer.transcriptAnalysis.topicsTimeline;
export const getMetricsTimeLine = (state: IStore): IDashboardTimeline<TMetricsTimeLineReport[]> =>
  state.dashboardReducer.transcriptAnalysis.metricsTimeline;
export const getThemeLabelTrend = (
  state: IStore,
): IDashboardTimeline<
  Record<TThemeId, TThemeLabelTrendReport[]>,
  Record<TThemeId, IThemeDefinition>
> => state.dashboardReducer.transcriptAnalysis.themeLabelsTrend;
export const getTeamMetrics = (state: IStore): TUserMetricsReport[] =>
  state.dashboardReducer.transcriptAnalysis.teamMetrics;
export const getReportCalls = (state: IStore): TDashboardReportCalls =>
  state.dashboardReducer.transcriptAnalysis.calls;
export const getReportCallsWithThemes = (state: IStore): TDashboardReportCallsWithThemes =>
  state.dashboardReducer.transcriptAnalysis.callsWithThemes;

export const getSummaryMetrics = (state: IStore): ITranscriptAnalysisSummaryMetrics | undefined =>
  state.dashboardReducer.transcriptAnalysis.summaryMetrics;
export const getGeneralData = (state: IStore): IDashboardSummary | undefined =>
  state.dashboardReducer.generalData;
export const getImprovementAreaSummary = (state: IStore): IImprovementAreaSummary[] | undefined =>
  state.dashboardReducer.improvementAreaSummary;
export const getScoringTimeline = (
  state: IStore,
): IDashboardTimeline<IDashboardScoringTimeline[]> | undefined =>
  state.dashboardReducer.scoringTimeline;
export const getUnacceptableTypeTimeline = (
  state: IStore,
): IDashboardTimeline<IDashboardUnacceptableTypeTimeline[]> | undefined =>
  state.dashboardReducer.unacceptableTypeTimeline;
export const getStatusTimeline = (
  state: IStore,
): IDashboardTimeline<IDashboardStatusTimeline[]> | undefined =>
  state.dashboardReducer.statusTimeline;
export const getSectionsPerformance = (
  state: IStore,
): IDashboardTimeline<IAssessmentPerformanceReport> =>
  state.dashboardReducer.assessmentForm.sectionsPerformance;
export const getSectionResults = (state: IStore): ISectionResultsSummary[] =>
  state.dashboardReducer.sectionResults;
export const getSectionQuestionSummary = (state: IStore): IKeyObject<ISectionQuestionSummary> =>
  state.dashboardReducer.sectionQuestionSummary;
export const getAgentPerformance = (state: IStore): IAgentPerformance | undefined =>
  state.dashboardReducer.agentPerformance;
export const getTeamLeadPerformance = (state: IStore): ITeamLeadPerformance[] =>
  state.dashboardReducer.teamLeadsPerformance;
export const getTeamLeadsWithoutAssessments = (state: IStore): ITeamLeadPerformance[] =>
  state.dashboardReducer.teamLeadsWithoutAssessments;
export const getScoringComparison = (
  state: IStore,
): IDashboardTimeline<IScoringComparison[]> | undefined => state.dashboardReducer.scoringComparison;
export const getCalibrationComparison = (
  state: IStore,
): IDashboardTimeline<ICalibrationReportResponse[]> | undefined =>
  state.dashboardReducer.calibration.calibrationsComparison;
export const getCalibrationSectionPerformances = (state: IStore) =>
  state.dashboardReducer.calibration.sectionsPerformance;
export const getCalibrationQuestionPerformances = (state: IStore) =>
  state.dashboardReducer.calibration.questionsPerformance;
export const getAssessmentFormQuestionPerformancesReport = (state: IStore) =>
  state.dashboardReducer.assessmentForm.questionsPerformance;
export const getAssessmentImprovementAreaPerformanceReport = (state: IStore) =>
  state.dashboardReducer.improvementAreaPerformance;
export const getAdditionalQuestions = (state: IStore): IQuestionSummary[] =>
  state.dashboardReducer.additionalQuestions;
export const getConditionalQuestion = (questionId: UUID) => (state: IStore): IQuestionSummary[] =>
  state.dashboardReducer.conditionalQuestions[questionId] || [];
export const getConditionalQuestions = (questionIds: UUID[]) => (
  state: IStore,
): IQuestionSummary[] =>
  questionIds.reduce((acc, v) => {
    acc.push(...(state.dashboardReducer.conditionalQuestions[v] || []));
    return acc;
  }, [] as IQuestionSummary[]);
export const getPresentationFilters = (state: IStore): IDashboardFilters<ISelectOption> =>
  state.dashboardReducer.presentationFilters;
export const getFilterChangeFlags = (state: IStore): IFiltersChanged =>
  state.dashboardReducer.filtersChanged;
export const getAgentTopicCount = (state: IStore): ITopicCountReport[] =>
  state.dashboardReducer.transcriptAnalysis.topicCount;
export const getThemeLabelsCount = (state: IStore): IThemeLabelCountReport[] =>
  state.dashboardReducer.transcriptAnalysis.themeLabelsCount;
export const getCampaignThemes = (state: IStore): IThemeDefinition[] =>
  state.dashboardReducer.themeData.campaignThemes;
export const getThemeSort = (state: IStore) => state.dashboardReducer.themeData.themeSort;
export const getVisibleThemesConfig = (state: IStore) =>
  state.dashboardReducer.themeData.visibleThemesConfig;
export const getHideNAThemeLabels = (state: IStore) =>
  state.dashboardReducer.themeData.hideNAThemeLabels;
export const getNegativityDistribution = (
  state: IStore,
): ITranscriptAnalysisMetricsDistribution[] =>
  state.dashboardReducer.transcriptAnalysis.negativityDistribution;
export const getPaceDistribution = (state: IStore): ITranscriptAnalysisMetricsDistribution[] =>
  state.dashboardReducer.transcriptAnalysis.paceDistribution;
export const getSilenceDistribution = (state: IStore): ITranscriptAnalysisMetricsDistribution[] =>
  state.dashboardReducer.transcriptAnalysis.silenceDistribution;
export const getOvertalkDistribution = (state: IStore): ITranscriptAnalysisMetricsDistribution[] =>
  state.dashboardReducer.transcriptAnalysis.overtalkDistribution;
export const getAdditionalTranscriptFiltersForIndex = (index: number | undefined) => (
  state: IStore,
): [TAdditionalTranscriptFilter, number] => [
  state.dashboardReducer.additionalTranscriptFilters
    .filter((_, i) => i < (index !== undefined ? index : 100))
    .reduce((acc, v) => ({ ...acc, ...v }), {}),
  state.dashboardReducer.additionalTranscriptFilters.length,
];
export const getAdditionalTranscriptFiltersOrdered = (
  state: IStore,
): TAdditionalTranscriptFilter[] => state.dashboardReducer.additionalTranscriptFilters;
export const getThemeFilters = (state: IStore) => state.dashboardReducer.themeData.themeFilters;
export const getAgentTopicsByIndex = (state: IStore): ISearchCallsTopic[][] | undefined =>
  state.dashboardReducer.transcriptAnalysis.agentTopicsByIndex;
export const getAgentNames = (state: IStore): IKeyObject =>
  state.dashboardReducer.transcriptAnalysis.agentNames;

export const getTeamAssessmentPerformance = (state: IStore) =>
  state.dashboardReducer.userPerformanceTimeline.teamAssessmentPerformanceTimeline;

export const getTeamMinScoreAssessmentPerformance = (state: IStore) =>
  state.dashboardReducer.userPerformanceTimeline.teamMinScoreAssessmentPerformanceTimeline;

export const getTeamSectionPerformanceTrend = (state: IStore) =>
  state.dashboardReducer.userPerformanceTimeline.teamSectionsPerformanceTimeline;
export const getTeamQuestionPerformanceTrend = (state: IStore) =>
  state.dashboardReducer.userPerformanceTimeline.teamQuestionsPerformanceTimeline;
