import { useEffect } from 'react';

import useCampaignId from '../campaign/useCampaignId';
import { useAsyncError } from '../useAsyncError';

import { locationApi } from '../../api/locationApi';
import { useDispatch, useSelector } from 'react-redux';
import { locationSelectors } from '../../store/selectors';
import { locationActions } from '../../store/reducers/locationReducer';
import { prepareLocations } from '../../components/shared/helpers/locationHelper';

const useLoadLocations = (): void => {
  const throwError = useAsyncError();
  const [campaignId] = useCampaignId();
  const availableLocations = useSelector(locationSelectors.getLocations(campaignId));
  const dispatch = useDispatch();

  const getLocations = async (): Promise<void> => {
    try {
      const { data } = await locationApi.getAvailableLocations();
      const locations = data && prepareLocations(data);
      dispatch(locationActions.setLocations({ campaignId, locations }));
    } catch (error) {
      const { status } = error.response;
      throwError(new Error(status));
    }
  };

  useEffect(() => {
    if (!availableLocations) {
      getLocations();
    }
  }, [campaignId]);
};

export default useLoadLocations;
