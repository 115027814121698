import React, { FC, CSSProperties, useState } from 'react';
import Select from 'react-select';

import { LANGUAGE_LIST, TLanguageValues } from './constants';
import { languageApi } from '../../api/languageApi';
import { ISelectOption } from '../shared/helpers/IHelpers';
import i18n from '../../i18n';
import storageUtils from '../../utils/storageUtils';

const color = '#25E2CC';
const customStyles = {
  option: (base: CSSProperties, state: { isSelected: boolean }) => ({
    ...base,
    color: '#003D5B',
    fontSize: '1.2rem',
    backgroundColor: state.isSelected ? color : 'white',
    ':active': {
      backgroundColor: color,
    },
    ':hover': {
      backgroundColor: color,
    },
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    zIndex: 999,
  }),
  control: (base: CSSProperties, state: { isFocused: boolean }) => ({
    ...base,
    fontSize: '1.4rem',
    minWidth: '80px',
    color: '#003D5B',
    boxShadow: 'none',
    borderColor: state.isFocused ? color : 'rgba(41,115,128,.4)',
    '&:hover': {
      borderColor: color,
    },
  }),
  dropdownIndicator: (base: CSSProperties) => ({
    ...base,
    color: '#007380',
    '&:hover': {
      color,
    },
  }),
  menuList: (base: CSSProperties) => ({
    ...base,
    maxHeight: '110% !important',
  }),
};

const LanguageSelector: FC<{}> = () => {
  const [localState, setLocalState] = useState<TLanguageValues>(
    storageUtils.getLocalStorageValue('language'),
  );

  const onLanguageChange = async (
    option: ISelectOption<Uppercase<TLanguageValues>, TLanguageValues>,
  ): Promise<void> => {
    setLocalState(option.value);
    localStorage.setItem('language', option.value);

    try {
      await languageApi.setLanguage(option.value);
      i18n.changeLanguage(option.value);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Select
      options={LANGUAGE_LIST as Readonly<ISelectOption[]>}
      styles={customStyles}
      isClearable={false}
      onChange={(option) =>
        onLanguageChange(option as ISelectOption<Uppercase<TLanguageValues>, TLanguageValues>)
      }
      value={{ label: localState.toUpperCase(), value: localState }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export default LanguageSelector;
