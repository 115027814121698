import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectOption } from '../../components/shared/helpers/IHelpers';

export const commonReducerInitialState = {
  dashboardSearchedFormNames: [] as ISelectOption[],
};

const commonReducer = createSlice({
  name: 'commonReducer',
  initialState: commonReducerInitialState,
  reducers: {
    setDashboardSearchFormNames: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.dashboardSearchedFormNames = payload;
    },
    resetState: () => commonReducerInitialState,
  },
});

export const commonActions = commonReducer.actions;

export default commonReducer.reducer;
