import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthData, IUserInfo } from '../../interfaces/authentication';
import storageUtils from '../../utils/storageUtils';
import JwtDecode from 'jwt-decode';

const token = storageUtils.getLocalStorageValue('authToken');

const userInfo = token ? JwtDecode(token) : null;
export const authReducerInitialState: IAuthData = {
  loggedIn: !!userInfo,
  userInfo: userInfo as IUserInfo | null,
  error: null,
};

const authReducer = createSlice({
  name: 'authReducer',
  initialState: authReducerInitialState,
  reducers: {
    setAuthData: (state, { payload }: PayloadAction<IAuthData>) => payload,
    setAuthLoggedOut: (state) => {
      state.loggedIn = false;
    },
  },
});

export const authActions = authReducer.actions;

export default authReducer.reducer;
