import React, { FC, ReactNode } from 'react';

import style from './Modal.module.scss';

export interface IModal {
  children: ReactNode;
  show: boolean;
  id?: string;
  type?:
    | 'wide'
    | 'default'
    | 'primary'
    | 'warning'
    | 'formList'
    | 'announcementHistory'
    | 'assessmentTrackedTimeHistory'
    | 'formHistory'
    | 'confirmation'
    | 'caseList';
  className?: string;
}

const Modal: FC<IModal> = ({ children, show, type = 'default', className, id }: IModal) => {
  return (
    <div className={show ? `${style.backdrop} ${style['backdrop-active']}` : style.backdrop}>
      <div
        id={id}
        className={`${className ? className : ''} ${style.modal} ${style[`modal-${type}`]}
          ${show ? `${style[`modal-${type}-active`]}` : ''} `}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
