import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportApi } from '../../api/reportApi';
import { EXPORT_POLLING } from '../../constants/report';

import { useAsyncError } from '../../hooks/useAsyncError';
import { TStorageDownloadToken } from '../../interfaces/report';
import { reportDownloadActions } from '../../store/reducers/reportDownloadReducer';
import { reportDownloadSelectors } from '../../store/selectors';
import storageUtils, { storageKeys } from '../../utils/storageUtils';
import { useUpdateEffect } from '../shared/useUpdateEffect';

/**
 * Watches for when a report download can be done
 *
 * should be used together with {@file UseStartDownloadReport}
 *
 * @param token local storage slice used to verify when download can be down
 * @param pollingRate at which rate to check if the report is already processed
 */
export const useTriggerDownloadReport = <T extends any>(
  token: TStorageDownloadToken = storageKeys.formDashboardExportToken,
  pollingRate = EXPORT_POLLING,
) => {
  const dispatch = useDispatch();
  const { ready, triggered } = useSelector(reportDownloadSelectors.getStatus(token));
  const throwError = useAsyncError();
  const [timer, setTimer] = useState<ReturnType<typeof setInterval>>();
  const [downloadPath, setDownloadPath] = useState<string | undefined>();

  const resetDownload = (): void => {
    timer && clearInterval(timer);
    storageUtils.removeLocalStorageValue(token);
    dispatch(reportDownloadActions.reset(token));
  };

  const retrieveExport = async (): Promise<string> => {
    const fileName = storageUtils.getLocalStorageValue(token);
    if (!fileName) {
      timer && clearInterval(timer);
      dispatch(reportDownloadActions.markAsNotTriggered(token));
      return '';
    }

    try {
      const { data } = await reportApi.retrieveExport(fileName);
      if (data) {
        setDownloadPath(data);
        dispatch(reportDownloadActions.markAsReady(token));
        timer && clearInterval(timer);
        return data;
      }
      return '';
    } catch (error) {
      const { status } = error.response;
      resetDownload();
      throwError(new Error(status));
      return '';
    }
  };

  const startPolling = (): void => {
    timer && clearInterval(timer);

    setTimer(
      setInterval(() => {
        retrieveExport();
      }, pollingRate),
    );
  };

  const triggerDownload = (): void => {
    resetDownload();
    window.open(downloadPath);
    setDownloadPath('');
  };

  useEffect(() => {
    if (!triggered) return;

    retrieveExport().then((filePath) => filePath && startPolling());

    return () => timer && clearInterval(timer);
  }, []);

  useUpdateEffect(() => {
    if (!triggered) return;
    startPolling();
  }, [triggered]);

  return { showDownloadReady: ready, triggerDownload };
};
