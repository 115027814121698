import React, { createContext, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInProgressRequest from '../hooks/useInProgressRequest';
import useAuth from '../hooks/useAuth';
import { useAsyncError } from '../hooks/useAsyncError';

import { usersApi } from '../api/usersApi';

import { ContextProps, EPopulator, IUserData, Role } from './IPopulatorProvider';
import { sortOptions, transformToSelect } from '../components/shared/helpers/selectHelpers';
import { mergeArrays } from '../components/shared/helpers/arrayHelpers';
import { QA_EQUIVALENT_ROLES } from '../constants/security';

export const PopulatorContext = createContext<ContextProps | null>(null);

export const PopulatorProvider: FC = ({ children }) => {
  const { i18n } = useTranslation();
  const throwError = useAsyncError();
  const { hasRole } = useAuth();
  const [
    addToInProgressRequest,
    removeFromInProgressRequest,
    isInProgressRequest,
  ] = useInProgressRequest<EPopulator | string>();

  const [users, setUsers] = useState<IUserData | null>(null);

  const getUsers = async (campaignId: string, includeLeavers = false): Promise<void> => {
    if (isInProgressRequest(EPopulator.USERS + campaignId)) {
      return;
    }
    try {
      addToInProgressRequest(EPopulator.USERS + campaignId);
      const agent = await usersApi.getUsersNames(campaignId, [Role.AGENT], { includeLeavers });
      const qa = await usersApi.getUsersNames(campaignId, QA_EQUIVALENT_ROLES, { includeLeavers });
      const teamLead = await usersApi.getUsersNames(campaignId, [Role.TEAM_LEAD], {
        includeLeavers,
      });
      const supervisor = hasRole(Role.AGENT)
        ? await usersApi.getSupervisors(Role.TEAM_LEAD)
        : { data: [] };

      setUsers({
        ...users,
        [campaignId]: {
          AGENT: sortOptions(transformToSelect(mergeArrays(agent.data, teamLead.data))),
          QA: transformToSelect(qa.data),
          TEAM_LEAD: transformToSelect(teamLead.data),
          SUPERVISOR: transformToSelect(supervisor.data),
          includeLeavers,
        },
      });
      removeFromInProgressRequest(EPopulator.USERS + campaignId);
    } catch (error) {
      removeFromInProgressRequest(EPopulator.USERS + campaignId);
      throwError(new Error(error.response?.status));
    }
  };

  const value: ContextProps = React.useMemo(
    () => ({
      users,
      getUsers,
    }),
    [users, i18n.language],
  );

  return <PopulatorContext.Provider value={value}>{children}</PopulatorContext.Provider>;
};
