import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../shared/modal/Modal';
import Button from '../shared/button/Button';
import { buttonVariant } from '../shared/button/constants';
import { IAnnouncement } from '../../interfaces/announcement';

import style from './AnnouncementAcknowledgeModals.module.scss';

type IProps = {
  announcement: IAnnouncement;
  show: boolean;
  onClose: () => void;
};

const AnnouncementDetailsModal: FC<IProps> = ({ announcement, show, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal key={announcement.id} show={show} type="primary" className={style.announcement}>
      <h3 className={style.title}>{t('announcement.details.modal.title')}</h3>
      <div className={`${style.messageWrapper} smallScroll`}>
        <p className={style.message}>{announcement.value}</p>
      </div>
      <Button
        id={'close-announcement-details-modal'}
        onClick={onClose}
        variant={buttonVariant.primary}>
        {t('button.close')}
      </Button>
    </Modal>
  );
};

export default AnnouncementDetailsModal;
