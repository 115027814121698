import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const monitoringInstrumentationKey: string | undefined =
  process.env.REACT_APP_MONITORING_INSIGHTS_KEY;

if (monitoringInstrumentationKey) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: monitoringInstrumentationKey,
      /* ...Other Configuration Options... */
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}
