import { useMemo } from 'react';
import useAuth from './useAuth';
import {
  ASSESSMENTS_WITH_NO_SCORE,
  AssessmentTypeValues,
  IAssessmentType,
} from '../interfaces/assessment';
import { ALL_ROLES } from '../constants/security';
import { Role } from '../providers/IPopulatorProvider';
import { ISelectOption } from '../components/shared/helpers/IHelpers';
import { transformToSelectOption } from '../components/shared/helpers/selectHelpers';
import { useTranslation } from 'react-i18next';

interface IReturn {
  assessmentTypePermissions: IAssessmentType[];
  assessmentTypeOptions: ISelectOption[];
  hasAtLeastOneScoringAssessmentType: boolean;
}

const useUserAssessmentTypePermissions = (): IReturn => {
  const { t, i18n } = useTranslation();
  const { authInfo, hasRoleOrPermission, hasRole } = useAuth();

  return useMemo(() => {
    if (!hasRoleOrPermission(ALL_ROLES, 'ASSESSMENT_READ')) {
      return {
        assessmentTypePermissions: [],
        assessmentTypeOptions: [],
        hasAtLeastOneScoringAssessmentType: false,
      } as IReturn;
    }

    const assessmentTypePermissions = hasRole(Role.RESTRICTED)
      ? authInfo.userInfo?.permissions.assessmentTypePermissions ??
        Object.values(AssessmentTypeValues)
      : Object.values(AssessmentTypeValues);

    return {
      assessmentTypePermissions,
      assessmentTypeOptions: assessmentTypePermissions.map((value) =>
        transformToSelectOption(value, t(`assessment.type.${value}`)),
      ),
      hasAtLeastOneScoringAssessmentType: assessmentTypePermissions.some(
        (type) => !ASSESSMENTS_WITH_NO_SCORE.includes(type),
      ),
    } as IReturn;
  }, [authInfo, i18n]);
};

export default useUserAssessmentTypePermissions;
