import React, { FC, MouseEvent } from 'react';

import { ConcentrixWebhelpMyQALogo } from '../../../assets/images';

import styles from './WebhelpLogo.module.scss';

interface IWebhelpLogo {
  id?: string;
  onClick?: (e?: MouseEvent<HTMLImageElement>) => void;
  size?: 'small' | 'medium';
  className?: string;
}

const HomeLogo: FC<IWebhelpLogo> = ({
  id,
  onClick = () => {
    return;
  },
  size,
  className,
}: IWebhelpLogo) => (
  <img
    src={ConcentrixWebhelpMyQALogo}
    id={id}
    alt="Logo"
    className={`
      ${styles.logo}
      ${styles['logo-' + size]}
      ${className || ''}
    `}
    onClick={onClick}
  />
);

export default HomeLogo;
