import JwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';

import { authApi } from '../api/authApi';
import { ISelectOption } from '../components/shared/helpers/IHelpers';
import { IAuthData, IUserInfo } from '../interfaces/authentication';
import {
  INTERMEDIARY_CAMPAIGN_NAME,
  INTERMEDIARY_CAMPAIGN_REFERENCE,
} from '../pages/utils/ChangeCampaignIntermediary';
import { DataPermissionType, Role } from '../providers/IPopulatorProvider';
import { ROUTE_PATHS } from '../routes/pathConstants';
import { authActions } from '../store/reducers/authReducer';
import { campaignActions } from '../store/reducers/campaignReducer';
import { searchCallsActions } from '../store/reducers/searchCallsReducer';
import { authSelectors } from '../store/selectors';
import storageUtils from '../utils/storageUtils';
import useCampaignId from './campaign/useCampaignId';
import { useAsyncError } from './useAsyncError';
import useInProgressRequest from './useInProgressRequest';
import msalService from '../services/authentication/msalService';
import { APP_CONFIG } from '../constants/appConfig';
import { assessmentActions } from '../store/reducers/assessmentReducer';

type IAuthReturn = {
  authInfo: IAuthData;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  changeCampaign: (id: string, name: string, campaignReference: string) => Promise<void>;
  redirectToChangeCampaign: (id?: string, name?: string, campaignReference?: string) => void;
  handleLogin: (token: string, shouldMockB2CToken?: boolean) => void;
  handleErrorAssignation: (hash: string) => void;
  resetError: () => void;
  hasRole: (roles: Role | Role[]) => boolean;
  hasRoleOrPermission: (
    roles: Role | Role[],
    dataPermission: DataPermissionType | DataPermissionType[],
  ) => boolean;
  getSelectOption: () => ISelectOption;
  loginWithB2CToken: (token: string) => Promise<void>;
};

const MYQA_DEV_B2C_TOKEN_MOCK = 'myqa_dev_b2c_token_mock';

const useAuth = (): IAuthReturn => {
  const setCampaignId = useCampaignId()[1];
  const throwError = useAsyncError();
  const history = useHistory();

  const [
    addToInProgressRequest,
    removeFromInProgressRequest,
    isInProgressRequest,
  ] = useInProgressRequest<string>();

  const dispatch = useDispatch();
  const authInfoRedux = useSelector(authSelectors.getAuthData);

  const handleLogin = (token: string, shouldMockB2CToken?: boolean): void => {
    if (token === '40103' || token === '40301' || token === '50000') {
      dispatch(authActions.setAuthData({ ...authInfoRedux, loggedIn: false, error: token }));
    } else {
      storageUtils.setLocalStorageValue('authToken', token);
      shouldMockB2CToken && storageUtils.setLocalStorageValue('b2cToken', MYQA_DEV_B2C_TOKEN_MOCK);

      const userInfo = JwtDecode(token) as IUserInfo;

      storageUtils.setLocalStorageValue('language', userInfo?.language || 'en');
      dispatch(authActions.setAuthData({ ...authInfoRedux, loggedIn: true, userInfo }));
      dispatch(campaignActions.setTranscriptAnalysisSettings());
      dispatch(assessmentActions.resetState());
      dispatch(searchCallsActions.reset());
    }
  };

  const handleErrorAssignation = (hash: string) => {
    if (hash === '40103' || hash === '40301' || hash === '50000') {
      !authInfoRedux.error &&
        dispatch(authActions.setAuthData({ ...authInfoRedux, loggedIn: false, error: hash }));
    }
  };

  const loginWithB2CToken = async (b2cToken: string): Promise<void> => {
    try {
      storageUtils.setLocalStorageValue('b2cToken', b2cToken);
      const myqaToken = (await authApi.loginWithB2CToken(b2cToken)).data;
      handleLogin(myqaToken);
    } catch (error) {
      const { status } = error && error.response;
      if (error.response.data.code === '40101') {
        dispatch(authActions.setAuthData({ ...authInfoRedux, loggedIn: false, error: '40301' })); // todo
        return;
      }

      throwError(new Error(status));
    }
  };

  // Handles login for the LoginTest component
  const login = async (email: string, password: string): Promise<void> => {
    try {
      await authApi.login(email, password);
    } catch (error) {
      const { status, data } = error && error.response;
      const token = status === 307 && data && data.split('#').pop();

      if (token) {
        handleLogin(token, true);
      } else {
        throwError(new Error(status));
      }
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await authApi.logout();
      const idTokenHint = storageUtils.getLocalStorageValue('b2cToken');
      storageUtils.clearLocalUserData();
      if (APP_CONFIG.IS_B2C_LOGIN_ENABLED && idTokenHint !== MYQA_DEV_B2C_TOKEN_MOCK) {
        await msalService.logout(idTokenHint);
        sessionStorage.clear();
      }
      dispatch(authActions.setAuthLoggedOut());

      setCampaignId(null);
    } catch (error) {
      const { status } = error.response;
      throwError(new Error(status));
    }
  };

  const redirectToChangeCampaign = (
    campaignId?: string,
    campaignName?: string,
    campaignReference?: string,
  ) => {
    const searchExtraFields =
      (campaignId &&
        `?${INTERMEDIARY_CAMPAIGN_NAME}=${campaignName}&${INTERMEDIARY_CAMPAIGN_REFERENCE}=${campaignReference}`) ||
      '';
    history.push(
      generatePath(ROUTE_PATHS.util.changeCampaign, {
        campaignId,
      }) + searchExtraFields,
    );
  };

  const changeCampaign = async (
    campaignId: string,
    campaignName: string,
    campaignReference: string,
  ): Promise<void> => {
    if (!isInProgressRequest('changeCampaign')) {
      try {
        addToInProgressRequest('changeCampaign');
        const { data: newToken } = await authApi.changeCampaign(campaignId);
        handleLogin(newToken);
        setCampaignId(campaignId, campaignName, campaignReference);
      } catch (error) {
        const { status } = error.response;
        throwError(new Error(status));
      } finally {
        removeFromInProgressRequest('changeCampaign');
      }
    }
  };

  const hasRole = (roles: Role | Role[]): boolean => {
    if (!authInfoRedux.userInfo || !authInfoRedux.userInfo.role) {
      return false;
    }
    if (Array.isArray(roles)) {
      return roles.includes(authInfoRedux.userInfo.role);
    }
    return roles === authInfoRedux.userInfo.role;
  };

  const hasRoleOrPermission = (
    roles: Role | Role[],
    permissions: DataPermissionType | DataPermissionType[],
  ): boolean => {
    if (hasRole(roles)) {
      return true;
    }
    if (authInfoRedux.userInfo?.role !== Role.RESTRICTED) {
      return false;
    }
    if (Array.isArray(permissions)) {
      return (
        !!permissions.length &&
        permissions.every((p) => authInfoRedux.userInfo?.permissions.dataPermissions.includes(p))
      );
    }
    return authInfoRedux.userInfo.permissions.dataPermissions.includes(permissions);
  };

  const getSelectOption = (): ISelectOption => {
    return {
      label: `${authInfoRedux.userInfo?.firstName} ${authInfoRedux.userInfo?.lastName}` || '',
      value: authInfoRedux.userInfo?.userId || '',
    };
  };

  const resetError = () => {
    dispatch(authActions.setAuthData({ ...authInfoRedux, error: '' }));
  };

  const authInfo: IAuthData = APP_CONFIG.IS_B2C_LOGIN_ENABLED
    ? {
        ...authInfoRedux,
        loggedIn: authInfoRedux.loggedIn && !!storageUtils.getLocalStorageValue('b2cToken'),
      }
    : authInfoRedux;

  return {
    authInfo,
    resetError,
    login,
    logout,
    changeCampaign,
    handleLogin,
    loginWithB2CToken,
    hasRole,
    getSelectOption,
    redirectToChangeCampaign,
    handleErrorAssignation,
    hasRoleOrPermission,
  };
};

export default useAuth;
