import { IStore } from '../index';
import { formReducerInitialState } from '../reducers/formReducer';

export const getQuestionsData = (state: IStore): typeof formReducerInitialState['questionsData'] =>
  state.formReducer.questionsData;

export const getMailSettings = (state: IStore): typeof formReducerInitialState['mailSettings'] =>
  state.formReducer.mailSettings;

export const getInteractionLanguages = (
  state: IStore,
): typeof formReducerInitialState['interactionLanguages'] => state.formReducer.interactionLanguages;

export const getConditionalQuestionModalData = (
  state: IStore,
): typeof formReducerInitialState['conditionalQuestionModal'] =>
  state.formReducer.conditionalQuestionModal;
