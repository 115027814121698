type TInProgressRequest<T> = [(element: T) => void, (element: T) => void, (element: T) => boolean];

const useInProgressRequest: <T>(initialValue?: T) => TInProgressRequest<T> = <T>(
  initialValue: T,
) => {
  let inProgressRequest = initialValue ? [initialValue] : [];

  const addToInProgressRequest = (element: T): void => {
    inProgressRequest.push(element);
  };

  const removeFromInProgressRequest = (element: T): void => {
    inProgressRequest = inProgressRequest.filter((e) => e !== element);
  };

  const isInProgressRequest = (element: T): boolean => {
    return inProgressRequest.includes(element);
  };

  return [addToInProgressRequest, removeFromInProgressRequest, isInProgressRequest];
};

export default useInProgressRequest;
