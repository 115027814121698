import { IStore } from '..';
import {
  IAnnouncementModals,
  IAssessmentCalibration,
  IAssessmentModals,
  IDeleteModal,
  IDownloadModal,
  ILocationModal,
  IModal,
  ITelephonyLoginModals,
  IUserTicketCreateModal,
} from '../../interfaces/modal';
import {
  IAssessmentPlanAllocateVariance,
  IAssessmentPlanCreateOrUpdate,
  IAssessmentPlanData,
  IAssessmentPlanDistributeEvenly,
  IAssessmentPlanFillAll,
  IAssessmentPlanHistory,
  IAssessmentPlanImportPreviousPlan,
  IAssessmentPlansDiscardWarning,
  IAssessmentPlanDelete,
} from '../../components/assessmentPlan/IAssessmentPlan';
import { IAssessmentType } from '../../interfaces/assessment';
import { IUserTicketComment } from '../../interfaces/userTicket';

export const getTranscriptModal = (state: IStore): IModal => state.modalReducer.transcriptModal;

export const getAssessmentPlanModal = (state: IStore): IModal<IAssessmentPlanData> =>
  state.modalReducer.assessmentPlanModals.createOrEditAssessmentPlanModal;

export const getUserAssessmentPlanHistoryModal = (state: IStore): IModal<IAssessmentType> =>
  state.modalReducer.assessmentPlanModals.historyModal;

export const getUserAssessmentPlanHistoryDetailsModal = (
  state: IStore,
): IModal<IAssessmentPlanHistory> => state.modalReducer.assessmentPlanModals.historyDetailsModal;

export const getAssessmentPlanUntrackUsersModal = (state: IStore): IModal<IAssessmentPlanDelete> =>
  state.modalReducer.assessmentPlanModals.untrackUsersModal;

export const getAssessmentPlansDiscardWarningModal = (
  state: IStore,
): IModal<IAssessmentPlansDiscardWarning> =>
  state.modalReducer.assessmentPlanModals.discardWarningModal;

export const getUserAssessmentPlanFillAllModal = (state: IStore): IModal<IAssessmentPlanFillAll> =>
  state.modalReducer.assessmentPlanModals.fillAllModal;

export const getUserAssessmentPlanImportPreviousPlanModal = (
  state: IStore,
): IModal<IAssessmentPlanImportPreviousPlan> =>
  state.modalReducer.assessmentPlanModals.importPreviousPlanModal;

export const getUserAssessmentPlanDistributeEvenlyModal = (
  state: IStore,
): IModal<IAssessmentPlanDistributeEvenly> =>
  state.modalReducer.assessmentPlanModals.distributeEvenlyModal;

export const getUserAssessmentPlanAllocateVarianceModal = (
  state: IStore,
): IModal<IAssessmentPlanAllocateVariance> =>
  state.modalReducer.assessmentPlanModals.allocateVarianceModal;

export const getUserAssessmentPlanModal = (state: IStore): IModal<IAssessmentPlanCreateOrUpdate> =>
  state.modalReducer.assessmentPlanModals.createOrEditUserAssessmentPlanModal;

export const getDeleteModal = (state: IStore): IModal<IDeleteModal> =>
  state.modalReducer.deleteModal;

export const getCreateListModalState = (state: IStore): boolean =>
  state.modalReducer.createCallList.isOpen;

export const getFormListModal = (state: IStore): IStore['modalReducer']['formListModal'] =>
  state.modalReducer.formListModal;

export const getFormPickerWarningModal = (state: IStore): IModal =>
  state.modalReducer.formPickerWarningModal;

export const getDownloadModal = (state: IStore): IModal<IDownloadModal> =>
  state.modalReducer.downloadCallModal;
export const getAssessmentDisputeModal = (state: IStore): boolean =>
  state.modalReducer.assessmentModals.disputeModal.isOpen;
export const getAssessmentSuccessModal = (state: IStore): boolean =>
  state.modalReducer.assessmentModals.successModal.isOpen;
export const getAssessmentAcknowledgementModal = (state: IStore): boolean =>
  state.modalReducer.assessmentModals.acknowledgementModal.isOpen;
export const getAssessmentReportingModal = (state: IStore): boolean =>
  state.modalReducer.assessmentModals.reportingModal.isOpen;
export const getUserAuditReportingModal = (state: IStore): boolean =>
  state.modalReducer.userAuditModal.isOpen;
export const getAssessmentModals = (state: IStore): IAssessmentModals =>
  state.modalReducer.assessmentModals;
export const getTelephonyLoginModals = (state: IStore): ITelephonyLoginModals =>
  state.modalReducer.telephonyLoginModals;
export const getTelephonyLoginCreateModal = (state: IStore): boolean =>
  state.modalReducer.telephonyLoginModals.createModal.isOpen;
export const getAssessmentCalibrationModal = (state: IStore): IModal<IAssessmentCalibration> =>
  state.modalReducer.assessmentModals.calibrationModal;
export const getTelephonyLoginReportingModal = (state: IStore): boolean =>
  state.modalReducer.telephonyLoginModals.reportingModal.isOpen;
export const getAnnouncementModals = (state: IStore): IAnnouncementModals =>
  state.modalReducer.announcementModals;
export const getAnnouncementCreateModal = (state: IStore): boolean =>
  state.modalReducer.announcementModals.createModal.isOpen;
export const getSaveAsDraftConfirmationModal = (state: IStore): boolean =>
  state.modalReducer.form.saveAsDraftConfirmation.isOpen;
export const getAnnouncementEditModal = (state: IStore): boolean =>
  state.modalReducer.announcementModals.editModal.isOpen;
export const getUserTicketCreateModal = (state: IStore): IUserTicketCreateModal =>
  state.modalReducer.userTicketModals.createModal;
export const getUserTicketReportModal = (state: IStore): boolean =>
  state.modalReducer.userTicketModals.reportModal.isOpen;
export const getAuditReportModal = (state: IStore): boolean =>
  state.modalReducer.auditReportModal.isOpen;
export const getUserTicketReactionsModal = (state: IStore): IModal =>
  state.modalReducer.userTicketModals.reactionsModal;
export const getUserTicketCommentEditModal = (state: IStore): IModal<IUserTicketComment> =>
  state.modalReducer.userTicketModals.commentEditModal;
export const getImprovementAreaModal = (state: IStore): boolean =>
  state.modalReducer.improvementAreaModal.isOpen;
export const getInternalActivityModal = (state: IStore): boolean =>
  state.modalReducer.internalActivityModal.isOpen;
export const getAssessmentTrackedTimeModal = (state: IStore): boolean =>
  state.modalReducer.assessmentModals.trackedTimeModal.isOpen;
export const getLocationModal = (state: IStore): IModal<ILocationModal> =>
  state.modalReducer.locationModal;
