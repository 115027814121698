export const LOGIN_REDIRECT_URI = process.env.REACT_APP_LOGIN_REDIRECT_URI || '';
export const SCOPES = process.env.REACT_APP_SCOPES
  ? process.env.REACT_APP_SCOPES.split(',')
  : undefined;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '52b61dce-8ac1-4775-9761-964ab166983a';
export const AUTHORITY = process.env.REACT_APP_AUTHORITY || '';

export const AUTHORITY_FORGOT_PASSWORD = process.env.REACT_APP_AUTHORITY_FORGOT_PASSWORD;
export const AUTHORITY_DOMAIN = process.env.REACT_APP_AUTHORITY_DOMAIN || '';

export const FORGOT_PASSWORD_ERROR_CODE = 'AADB2C90118';
export const OPENID_SCOPE = 'openid';
export const LOGIN_PROMPT = 'select_account';
