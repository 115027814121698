import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NotFoundIcon } from '../../../assets/images/not-found.svg';

import style from './NoResultsFound.module.scss';

interface IProps {
  label?: string;
  hasValue?: boolean;
}

const NoResultFound: FC<IProps> = ({ children, hasValue = false, label = 'list.noResults' }) => {
  const { t } = useTranslation();

  if (hasValue && children) return <>{children}</>;

  return (
    <div className={style.noResults}>
      <NotFoundIcon />
      {t(label)}
    </div>
  );
};

export default NoResultFound;
