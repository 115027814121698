import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import authReducer, { authReducerInitialState } from './reducers/authReducer';
import calibrationReducer, { calibrationReducerInitialState } from './reducers/calibrationReducer';
import campaignReducer, { campaignReducerInitialState } from './reducers/campaignReducer';
import dashboardReducer, { dashboardReducerInitialState } from './reducers/dashboardReducer';
import formReducer, { formReducerInitialState } from './reducers/formReducer';
import locationReducer, { locationReducerInitialState } from './reducers/locationReducer';
import modalReducer, { modalReducerInitialState } from './reducers/modalReducer';
import reportDownloadReducer, {
  reportDownloadReducerInitialState,
} from './reducers/reportDownloadReducer';
import searchCallsReducer, { searchCallsReducerInitialState } from './reducers/searchCallsReducer';
import settingsReducer, { settingsReducerInitialState } from './reducers/settingsReducer';
import userReducer, { userReducerInitialState } from './reducers/userReducer';
import caseEntityReducer, { caseEntityReducerInitialState } from './reducers/caseEntityReducer';
import assessmentReducer, { assessmentReducerInitialState } from './reducers/assessmentReducer';
import ticketReducer, { ticketReducerInitialState } from './reducers/ticketReducer';
import assessmentPlanReducer, {
  assessmentPlanReducerInitialState,
} from './reducers/assessmentPlanReducer';
import callImportErrorsReducer, {
  callImportErrorReducerInitialState,
} from './reducers/callImportErrorsReducer';
import auditReportReducer, { auditReportReducerInitialState } from './reducers/auditReportReducer';
import callDetailsReducer, { callDetailsReducerInitialState } from './reducers/callDetailsReducer';
import commonReducer, { commonReducerInitialState } from './reducers/commonReducer';

export const globalInitialState: IStore = {
  callImportErrorsReducer: callImportErrorReducerInitialState,
  campaignReducer: campaignReducerInitialState,
  settingsReducer: settingsReducerInitialState,
  formReducer: formReducerInitialState,
  locationReducer: locationReducerInitialState,
  dashboardReducer: dashboardReducerInitialState,
  userReducer: userReducerInitialState,
  modalReducer: modalReducerInitialState,
  reportDownloadReducer: reportDownloadReducerInitialState,
  calibrationReducer: calibrationReducerInitialState,
  authReducer: authReducerInitialState,
  searchCallsReducer: searchCallsReducerInitialState,
  caseEntityReducer: caseEntityReducerInitialState,
  assessmentReducer: assessmentReducerInitialState,
  ticketReducer: ticketReducerInitialState,
  assessmentPlanReducer: assessmentPlanReducerInitialState,
  auditReportReducer: auditReportReducerInitialState,
  callDetailsReducer: callDetailsReducerInitialState,
  commonReducer: commonReducerInitialState,
};

const store = configureStore({
  reducer: combineReducers({
    callImportErrorsReducer,
    campaignReducer,
    assessmentReducer,
    formReducer,
    settingsReducer,
    locationReducer,
    dashboardReducer,
    userReducer,
    reportDownloadReducer,
    modalReducer,
    calibrationReducer,
    authReducer,
    searchCallsReducer,
    caseEntityReducer,
    ticketReducer,
    assessmentPlanReducer,
    auditReportReducer,
    callDetailsReducer,
    commonReducer,
  }),
});

export default store;

export type IStore = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, IStore, null, Action<string>>;
