import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQuestionsAnswered } from '../../components/questionnaire/questionnaireForm/IQuestionnaireForm';
import { UUID } from '../../interfaces';
import {
  ICalibrationDetails,
  ICalibrationReducer,
  IModalController,
  TSession,
} from '../../interfaces/calibration';
import { ICaseDto } from '../../interfaces/call';

export const calibrationReducerInitialState: ICalibrationReducer = {
  sessionId: null,
  calibration: {
    details: null,
    answeredQuestions: [],
  },
  session: null,
  calibrationsCountRefreshFlag: false,
  modals: {
    closeModal: { isOpen: false, path: '' },
    createdModal: { isOpen: false },
    savedDraftModal: { isOpen: false },
    submitModal: { isOpen: false },
  },
};

const calibrationReducer = createSlice({
  name: 'calibrationReducer',
  initialState: calibrationReducerInitialState,
  reducers: {
    setSessionId: (state, { payload }: PayloadAction<UUID>) => {
      state.sessionId = payload;
    },
    setSession: (state, { payload }: PayloadAction<TSession>) => {
      state.session = payload;
    },
    setCalibration: (state, { payload }: PayloadAction<ICalibrationDetails>) => {
      state.calibration.details = payload;
    },
    setCalibratedCase: (state, { payload }: PayloadAction<ICaseDto>) => {
      state.calibration.details!.calibratedCase = payload;
    },
    setAnsweredQuestions: (state, { payload }: PayloadAction<IQuestionsAnswered[]>) => {
      state.calibration.answeredQuestions = payload;
    },
    setCloseModal: (state, { payload }: PayloadAction<Pick<IModalController, 'closeModal'>>) => {
      state.modals.closeModal = payload.closeModal;
    },
    setCreateModal: (state, { payload }: PayloadAction<boolean>) => {
      state.modals.createdModal.isOpen = payload;
    },
    setSaveDraftModal: (state, { payload }: PayloadAction<boolean>) => {
      state.modals.savedDraftModal.isOpen = payload;
    },
    setSubmitModal: (state, { payload }: PayloadAction<boolean>) => {
      state.modals.submitModal.isOpen = payload;
    },
    resetModals: (state) => {
      state.modals = { ...calibrationReducerInitialState.modals };
    },
    resetState: () => calibrationReducerInitialState,
    reloadPendingCalibrationsCount: (state) => {
      state.calibrationsCountRefreshFlag = !state.calibrationsCountRefreshFlag;
    },
    updateAttendance: (state, { payload }: PayloadAction<UUID[]>) => {
      state.session?.calibrations.forEach((c) => {
        c.attendedMeeting = payload.includes(c.calibrator.id);
      });
    },
    updateComment: (state, { payload }: PayloadAction<{ userId: string; comment: string }>) => {
      if (!state.session) return;
      state.session?.calibrations
        .filter(({ calibrator }) => payload.userId === calibrator.id)
        .forEach((c) => (c.comment = payload.comment));

      if (state.session?.mainCalibration.calibrator.id === payload.userId) {
        state.session.mainCalibration.comment = payload.comment;
      }
    },
  },
});

export const calibrationActions = calibrationReducer.actions;

export default calibrationReducer.reducer;
