import { AxiosPromise } from 'axios';
import { PagedSearch } from '../components/shared/interfaces';
import {
  ICalibrationInfo,
  ICalibrationList,
  ICalibrationRequest,
  ICalibrationScore,
  IInitiateCalibration,
  ICalibrationDetails,
  IRequestActions,
  ISession,
  TInitiateCalibrationAssessment,
} from '../interfaces/calibration';
import { axiosInstance } from './axiosClient';
import paths from './apiPaths';
import { UUID } from '../interfaces';
import { IKeyObject } from '../components/shared/helpers/IHelpers';

export const calibrationApi: {
  getCalibrations: (
    calibrations: ICalibrationRequest,
  ) => AxiosPromise<PagedSearch<ICalibrationList>>;
  editAttendance: (calibrationId: UUID, calibratorIds: string[]) => AxiosPromise<void>;
  updateComment: (calibrationId: UUID, comment: string) => AxiosPromise<void>;
  initiateCalibration: (calibration: IInitiateCalibration) => AxiosPromise<UUID>;
  initiateCalibrationFromAssessment: (
    calibration: TInitiateCalibrationAssessment,
  ) => AxiosPromise<UUID>;
  initiateRequestedCalibration: (
    calibrationId: UUID,
    sessionType: string | undefined,
    calibratorIds: UUID[],
  ) => AxiosPromise<UUID>;
  addCalibratorsToCalibrationSession: (
    calibrationSessionId: UUID,
    calibratorIds: UUID[],
  ) => AxiosPromise<UUID>;
  requestCalibration: (assessmentId: UUID) => AxiosPromise<UUID>;
  getCalibrationInfo: (calibrationId: UUID) => AxiosPromise<ICalibrationInfo>;
  getCalibration: (calibrationId: UUID) => AxiosPromise<ICalibrationDetails>;
  deleteCalibration: (calibrationId: UUID) => AxiosPromise<ICalibrationDetails>;
  getSession: (calibrationId: UUID) => AxiosPromise<ISession>;
  getSessionTypes: () => AxiosPromise<string[]>;
  countPendingCalibrations: () => AxiosPromise<number>;
  getCalibrationScore: (
    questions: IKeyObject<string>,
    calibrationId: UUID,
  ) => AxiosPromise<ICalibrationScore>;
  saveAsDraft: (request: IRequestActions, calibrationId: UUID) => AxiosPromise;
  submitCalibration: (request: IRequestActions, calibrationId: UUID) => AxiosPromise;
  publish: (sessionId: UUID) => AxiosPromise;
  complete: (sessionId: UUID) => AxiosPromise;
} = {
  getCalibrations: (calibration) =>
    axiosInstance.post(paths.calibrationSessions + paths.search, calibration),
  initiateCalibration: (calibration) =>
    axiosInstance.post(paths.calibrationSessions + paths.initiate, calibration),
  initiateRequestedCalibration: (calibrationId, sessionType, calibratorIds) =>
    axiosInstance.post(paths.calibrationSessions + `/${calibrationId}` + paths.initiate, {
      calibratorIds,
      sessionType,
    }),
  addCalibratorsToCalibrationSession: (calibrationId, calibratorIds) =>
    axiosInstance.put(paths.calibrationSessions + `/${calibrationId}` + paths.calibrators, {
      calibratorIds,
    }),
  editAttendance: (calibrationId, calibratorIds) =>
    axiosInstance.put(paths.calibrationSessions + `/${calibrationId}` + paths.attendance, {
      calibratorIds,
    }),
  updateComment: (calibrationId, comment) =>
    axiosInstance.put(paths.calibrationSessions + `/${calibrationId}` + paths.comment, {
      comment,
    }),
  countPendingCalibrations: () => axiosInstance.get(paths.calibrationSessions + paths.pending),
  initiateCalibrationFromAssessment: (calibration) =>
    axiosInstance.post(paths.calibrationSessions + paths.assessments + paths.initiate, calibration),
  requestCalibration: (assessmentId) =>
    axiosInstance.post(paths.calibrationSessions + paths.request, null, {
      params: { assessmentId },
    }),
  getCalibrationInfo: (calibrationId) =>
    axiosInstance.get(paths.calibrationSessions + `/${calibrationId}` + paths.info),
  getCalibration: (calibrationId) =>
    axiosInstance.get(paths.calibrationSessions + `/${calibrationId}` + paths.calibration),
  deleteCalibration: (calibrationId) =>
    axiosInstance.delete(paths.calibrationSessions + `/${calibrationId}`),
  getSession: (calibrationId) => axiosInstance.get(paths.calibrationSessions + `/${calibrationId}`),
  getSessionTypes: () => axiosInstance.get(paths.calibrationSessions + paths.types),
  getCalibrationScore: (questions, calibrationId) =>
    axiosInstance.post(
      paths.calibrationSessions + `/${calibrationId}` + paths.calibration + paths.score,
      {
        questionAnswers: questions,
      },
    ),
  saveAsDraft: (request, calibrationId) =>
    axiosInstance.post(
      paths.calibrationSessions + `/${calibrationId}` + paths.calibration + paths.draft,
      request,
    ),
  submitCalibration: (request, calibrationId) =>
    axiosInstance.post(
      paths.calibrationSessions + `/${calibrationId}` + paths.calibration,
      request,
    ),
  publish: (sessionId) =>
    axiosInstance.post(paths.calibrationSessions + `/${sessionId}` + paths.publish),
  complete: (sessionId) =>
    axiosInstance.post(paths.calibrationSessions + `/${sessionId}` + paths.complete),
};
