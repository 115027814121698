import { AxiosPromise } from 'axios';

import { axiosInstance } from './axiosClient';
import apiPaths from './apiPaths';
import { PagedSearch } from '../components/shared/interfaces';
import {
  IAddAnnouncementRequest,
  IAnnouncement,
  IAnnouncementListElement,
  IEditAnnouncementRequest,
  TAnnouncementRequest,
} from '../interfaces/announcement';
import { IAnnouncementHistory } from '../components/announcement/IAnnouncementHistory';

const { announcements, search, user, history } = apiPaths;

export const announcementsApi: {
  getRegionAnnouncements: () => AxiosPromise<IAnnouncement[]>;
  getAnnouncements: (
    announcement: TAnnouncementRequest,
  ) => AxiosPromise<PagedSearch<IAnnouncementListElement>>;
  createAnnouncement: (announcement: IAddAnnouncementRequest) => AxiosPromise;
  editAnnouncement: (announcement: IEditAnnouncementRequest) => AxiosPromise;
  getAnnouncementHistory: (announcementId: string) => AxiosPromise<IAnnouncementHistory[]>;
} = {
  getRegionAnnouncements: () => {
    return axiosInstance.get(announcements + user);
  },
  getAnnouncements: (filters) => axiosInstance.post(announcements + search, filters),
  createAnnouncement: (values) => axiosInstance.post(announcements, values),
  editAnnouncement: ({ id, ...data }) => axiosInstance.put(announcements + '/' + id, { ...data }),
  getAnnouncementHistory: (announcementId: string) =>
    axiosInstance.get(announcements + `/${announcementId}` + history),
};
