import { AxiosPromise } from 'axios';
import apiPaths from './apiPaths';
import paths from './apiPaths';

import { axiosInstance } from './axiosClient';
import { PagedSearch } from '../components/shared/interfaces';
import {
  IAssessmentPlanCreate,
  IAssessmentPlanCreateOrUpdate,
  IAssessmentPlanGeneralInfo,
  IAssessmentPlanCreateOrUpdateRequest,
  IAssessmentPlanHistory,
  IAssessmentPlanResponse,
  ICurrentPlannedUserAssessmentPlan,
  IPage,
  IUserAssessmentPlanData,
  IUserAssessmentPlanSearch,
  IAssessmentPlanDeleteRequest,
} from '../components/assessmentPlan/IAssessmentPlan';
import { IAssessmentType } from '../interfaces/assessment';
import { IPageable } from '../components/shared/helpers/IHelpers';

export const assessmentPlanApi: {
  searchAssessmentPlans: (payload: IPage) => AxiosPromise<PagedSearch<IAssessmentPlanResponse>>;
  createAssessmentPlan: (plan: IAssessmentPlanCreate) => AxiosPromise<string>;
  editAssessmentPlan: (planId: string, plan: IAssessmentPlanCreate) => AxiosPromise<string>;
  getAssessmentPlanReport: (planId: string) => AxiosPromise<IAssessmentPlanGeneralInfo[]>;
  getPlannedCountReport: (date: string) => AxiosPromise<IAssessmentPlanGeneralInfo[]>;
  getPlannedUserAssessments: (
    plan: IUserAssessmentPlanSearch,
  ) => AxiosPromise<PagedSearch<ICurrentPlannedUserAssessmentPlan>>;
  getPlannedUserAssessmentsReport: (
    plan: IUserAssessmentPlanSearch,
  ) => AxiosPromise<PagedSearch<IUserAssessmentPlanData>>;
  getUnplannedUserAssessments: (
    plan: IUserAssessmentPlanSearch,
  ) => AxiosPromise<PagedSearch<ICurrentPlannedUserAssessmentPlan>>;
  getUnplannedUserAssessmentsReport: (
    plan: IUserAssessmentPlanSearch,
  ) => AxiosPromise<PagedSearch<IUserAssessmentPlanData>>;
  updateAssessmentPlan: (plan: IAssessmentPlanCreateOrUpdateRequest) => AxiosPromise<void>;
  deleteUserPlans: (request: IAssessmentPlanDeleteRequest) => AxiosPromise<void>;
  getUserPlanHistory: (body: {
    planId: string;
    assessmentType: IAssessmentType;
    pageRequest: IPageable;
  }) => AxiosPromise<PagedSearch<IAssessmentPlanHistory>>;
} = {
  searchAssessmentPlans: (payload: IPage) =>
    axiosInstance.post(apiPaths.assessmentPlan + apiPaths.search, payload),
  getUserPlanHistory: (payload) =>
    axiosInstance.post(apiPaths.assessmentPlan + apiPaths.history, payload),
  createAssessmentPlan: (plan: IAssessmentPlanCreate) =>
    axiosInstance.post(apiPaths.assessmentPlan, plan),
  editAssessmentPlan: (planId, plan) =>
    axiosInstance.put(`${apiPaths.assessmentPlan}/${planId}`, plan),
  getAssessmentPlanReport: (planId) =>
    axiosInstance.get(`${apiPaths.assessmentPlan}/${planId}${apiPaths.report}`),
  updateAssessmentPlan: (plan: IAssessmentPlanCreateOrUpdate) =>
    axiosInstance.put(paths.assessmentPlan + paths.users, plan),
  deleteUserPlans: (request: IAssessmentPlanDeleteRequest) =>
    axiosInstance.delete(paths.assessmentPlan + paths.users, { data: request }),
  getPlannedCountReport: (date: string) =>
    axiosInstance.post(apiPaths.assessmentPlan + apiPaths.report, date),
  getPlannedUserAssessments: ({ id, pageRequest, type, userAssessmentPlanType }) =>
    axiosInstance.post(apiPaths.assessmentPlan + '/' + id + apiPaths.users + apiPaths.search, {
      assessmentType: type,
      userAssessmentPlanType: userAssessmentPlanType,
      pageRequest,
    }),
  getPlannedUserAssessmentsReport: ({
    id,
    pageRequest,
    type,
    referencePlanId,
    userAssessmentPlanType,
  }) =>
    axiosInstance.post(apiPaths.assessmentPlan + '/' + id + apiPaths.users + apiPaths.report, {
      assessmentType: type,
      userAssessmentPlanType,
      pageRequest,
      referencePlanId,
    }),
  getUnplannedUserAssessments: ({ id, pageRequest, type, userAssessmentPlanType }) =>
    axiosInstance.post(
      apiPaths.assessmentPlan + '/' + id + apiPaths.unplannedUsers + apiPaths.search,
      {
        assessmentType: type,
        userAssessmentPlanType: userAssessmentPlanType,
        pageRequest,
      },
    ),
  getUnplannedUserAssessmentsReport: ({
    id,
    pageRequest,
    type,
    referencePlanId,
    userAssessmentPlanType,
  }) =>
    axiosInstance.post(
      apiPaths.assessmentPlan + '/' + id + apiPaths.unplannedUsers + apiPaths.report,
      {
        assessmentType: type,
        userAssessmentPlanType,
        pageRequest,
        referencePlanId,
      },
    ),
};
