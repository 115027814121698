import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuditReportReducer } from '../../components/auditReport/IMainSearch';
import { IUserAllocation } from '../../components/campaignManagement/shared/interfaces';

const initialState = { values: null, page: 1, totalPages: 0 };
export const auditReportReducerInitialState: IAuditReportReducer = {
  userAllocations: initialState,
};

const auditReportReducer = createSlice({
  name: 'auditReportReducer',
  initialState: auditReportReducerInitialState,
  reducers: {
    setAuditReportUserAllocations: (
      state,
      {
        payload,
      }: PayloadAction<{
        values: IUserAllocation[];
        page: number;
        totalPages: number;
      }>,
    ) => {
      state.userAllocations = {
        values: payload.values,
        page: payload.page,
        totalPages: payload.totalPages,
      };
    },
    reset: () => auditReportReducerInitialState,
  },
});

export const auditReportActions = auditReportReducer.actions;

export default auditReportReducer.reducer;
