import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchCallsCluster, ISearchCallsTopic } from '../../interfaces/call';

export const searchCallsReducerInitialState = {
  clusters: undefined as ISearchCallsCluster[] | undefined,
  topics: undefined as ISearchCallsTopic[] | undefined,
};

const searchCallsReducer = createSlice({
  name: 'searchCallsReducer',
  initialState: searchCallsReducerInitialState,
  reducers: {
    reset: () => searchCallsReducerInitialState,
    setSearchCallClusters: (
      state,
      { payload }: PayloadAction<ISearchCallsCluster[] | undefined>,
    ) => {
      state.clusters = payload;
    },
    setSearchCallTopics: (state, { payload }: PayloadAction<ISearchCallsTopic[] | undefined>) => {
      state.topics = payload;
    },
  },
});

export const searchCallsActions = searchCallsReducer.actions;

export default searchCallsReducer.reducer;
