import React, { FC, lazy, Suspense } from 'react';
import DotLoader from 'react-spinners/DotLoader';

import useAuth from '../../hooks/useAuth';

import loaderStyle from '../Loader.module.scss';

const CollectiveDashboard = lazy(
  () => import(/* webpackChunkName: 'collectiveDashboard' */ './CollectiveDashboard'),
);

const CollectiveDashboardWrapper: FC = () => {
  const { userInfo } = useAuth().authInfo;

  if (!userInfo) return null;

  return (
    <Suspense
      fallback={
        <div className={loaderStyle.loaderTotalCenter}>
          <DotLoader />
        </div>
      }>
      <CollectiveDashboard />
    </Suspense>
  );
};

export default CollectiveDashboardWrapper;
