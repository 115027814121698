export const buttonVariant = {
  warning: 'warning',
  default: 'default',
  defaultReversed: 'defaultReversed',
  primary: 'primary',
  secondary: 'secondary',
  linkPrimary: 'linkPrimary',
  linkSecondary: 'linkSecondary',
  linkThird: 'linkThird',
} as const;

export const buttonSize = {
  extraSmall: 'extraSmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  searchSubmit: 'searchSubmit',
} as const;
