import { useDispatch, useSelector } from 'react-redux';
import { campaignActions } from '../../store/reducers/campaignReducer';
import { campaignSelectors } from '../../store/selectors';
import storageUtils from '../../utils/storageUtils';

const useCampaignId = (): [
  string,
  (id: string | null, name?: string, reference?: string) => void,
  string,
  string,
] => {
  const dispatch = useDispatch();
  const currentCampaignId = useSelector(campaignSelectors.getCurrentCampaignId);
  const currentCampaignName = useSelector(campaignSelectors.getCurrentCampaignName);
  const currentCampaignReference = useSelector(campaignSelectors.getCurrentCampaignReference);

  const setCampaignId = (campaignId: string | null, campaignName = '', reference = ''): void => {
    if (!campaignId) {
      storageUtils.removeLocalStorageValue('campaignId');
      storageUtils.removeLocalStorageValue('campaignName');
      storageUtils.removeLocalStorageValue('campaignReference');
      dispatch(campaignActions.setCurrentCampaign(null));
      return;
    }

    if (campaignId !== currentCampaignId) {
      storageUtils.setLocalStorageValue('campaignId', campaignId);
      storageUtils.setLocalStorageValue('campaignName', campaignName);
      storageUtils.setLocalStorageValue('campaignReference', reference);
      dispatch(
        campaignActions.setCurrentCampaign({ id: campaignId, name: campaignName, reference }),
      );
    }
  };

  return [currentCampaignId, setCampaignId, currentCampaignName, currentCampaignReference];
};

export default useCampaignId;
