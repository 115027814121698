import React, { FC, lazy, Suspense } from 'react';
import { Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';

import {
  ALL_ROLES,
  ALL_ROLES_WITHOUT_AGENT,
  ROLES_ALLOWED_TO_ACCESS_TICKETS,
  ROLES_ALLOWED_TO_EDIT_USER_ASSESSMENT_PLANS,
} from '../constants/security';
import useLoadLocations from '../hooks/locations/useLoadLocations';
import { useAsyncError } from '../hooks/useAsyncError';

import useAuth from '../hooks/useAuth';
import ChangeCampaignIntermediary from '../pages/utils/ChangeCampaignIntermediary';

import { DataPermissionType, Role } from '../providers/IPopulatorProvider';
import storageUtils from '../utils/storageUtils';
import { getDefaultHomePageFor, ROUTE_PATHS } from './pathConstants';

import paths from './routePaths';
import { APP_CONFIG } from '../constants/appConfig';
import useUserAssessmentTypePermissions from '../hooks/useUserAssessmentTypePermissions';
import UserTicketPlaceholderPage from '../components/userTicket/UserTicketPlaceholderPage';

import loaderStyle from '../components/Loader.module.scss';

const CallDetails = lazy(
  () => import(/* webpackChunkName: 'callDetails' */ '../pages/callDetails/CallDetailsPage'),
);
const Calls = lazy(() => import(/* webpackChunkName: 'calls' */ '../components/calls/Calls'));
const AnnouncementPage = lazy(
  () => import(/* webpackChunkName: 'anouncements' */ '../pages/announcements/AnnouncementPage'),
);
const Assessments = lazy(
  () => import(/* webpackChunkName: 'assessments' */ '../components/assessments/Assessments'),
);
const AssessmentWithoutCall = lazy(
  () =>
    import(
      /* webpackChunkName: 'assessmentsWithoutCall' */ '../components/assessments/assessmentWithoutCall/AssessmentWithoutCall'
    ),
);
const Questionnaire = lazy(
  () =>
    import(
      /* webpackChunkName: 'questionnaire' */ '../components/questionnaire/QuestionnaireWithSegmentsWrapper'
    ),
);
const QuestionnaireDispute = lazy(
  () =>
    import(
      /* webpackChunkName: 'questionnaireDispute' */ '../components/questionnaire/dispute/QuestionnaireDisputeWithSegmentsWrapper'
    ),
);
const AssessmentForms = lazy(
  () =>
    import(
      /* webpackChunkName: 'assessmentForms' */ '../components/assessmentForms/AssessmentForms'
    ),
);
const FormBuilder = lazy(
  () => import(/* webpackChunkName: 'formBuilder' */ '../components/formBuilder/FormBuilder'),
);
const FormView = lazy(
  () =>
    import(/* webpackChunkName: 'formView' */ '../components/assessmentForms/formView/FormView'),
);
const CampaignManagement = lazy(
  () =>
    import(
      /* webpackChunkName: 'campaignManagement' */ '../components/campaignManagement/CampaignManagement'
    ),
);
const LocationManagement = lazy(() => import('../components/location/LocationManagement'));
const CampaignView = lazy(
  () =>
    import(
      /* webpackChunkName: 'campaignView' */ '../components/campaignManagement/campaignView/CampaignView'
    ),
);
const CampaignAssessmentConfigurationView = lazy(
  () =>
    import(
      '../components/campaignManagement/assessmentConfigurationView/AssessmentConfigurationView'
    ),
);
const UserManagement = lazy(
  () =>
    import(/* webpackChunkName: 'campaignView' */ '../components/userManagement/UserManagement'),
);
const UserDetails = lazy(
  () =>
    import(
      /* webpackChunkName: 'campaignView' */ '../components/userManagement/userDetails/UserDetails'
    ),
);
const Dashboard = lazy(
  () => import(/* webpackChunkName: 'dashboard' */ '../components/dashboard/Dashboard'),
);

// calibration
const CalibrationPage = lazy(
  () =>
    import(/* webpackChunkName: 'calibrationPage' */ '../components/calibration/CalibrationPage'),
);
const CalibrationWithoutCall = lazy(
  () =>
    import(
      /* webpackChunkName: 'calibrationWithoutCall' */ '../components/calibration/CreateCalibration/CalibrationWithoutCall'
    ),
);
const CalibrationFromCall = lazy(
  () =>
    import(
      /* webpackChunkName: 'calibrationFromCall' */ '../components/calibration/CreateCalibration/CalibrationFromCall'
    ),
);
const InitiateCalibration = lazy(
  () =>
    import(
      /* webpackChunkName: 'initiateCalibration' */ '../components/calibration/CreateCalibration/InitiateCalibration'
    ),
);
const Calibration = lazy(
  () =>
    import(
      /* webpackChunkName: 'calibration'*/ '../components/calibration/calibrationFlow/CalibrationWithSegments'
    ),
);
const CalibrationSubmit = lazy(
  () =>
    import(
      /* webpackChunkName: 'calibrationSubmit'*/ '../components/calibration/calibrationFlow/CalibrationSubmit'
    ),
);
const ViewSession = lazy(
  () =>
    import(
      /* webpackChunkName: 'viewSession' */ '../components/calibration/calibrationFlow/CalibrationSessionWithSegments'
    ),
);
const CalibrationFromAssessment = lazy(
  () =>
    import(
      /* webpackChunkName: 'calibrationFromAssessment' */ '../components/calibration/CreateCalibration/CalibrationFromAssessments'
    ),
);
const CalibrationSessionAddCalibrators = lazy(
  () =>
    import(
      /* webpackChunkName: 'calibrationFromAssessment' */ '../components/calibration/CreateCalibration/CalibrationSessionAddCalibrators'
    ),
);

const TelephonyLoginPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'telephonyLoginsPage' */ '../pages/telephonyLogins/TelephonyLoginPage'
    ),
);

const UnrestrictedCalls = lazy(
  () =>
    import(
      /* webpackChunkName: 'calls' */ '../components/calls/unrestrictedCalls/UnrestrictedCalls'
    ),
);

const AuditReportUserAllocations = lazy(
  () => import('../components/auditReport/AuditReportUserAllocations'),
);

const AuditReportVdnConnections = lazy(
  () => import('../components/auditReport/AuditReportVdnConnections'),
);

const AuditReportDefaultRoleMapping = lazy(
  () => import('../components/auditReport/AuditReportDefaultRoleMapping'),
);

const UserTicketPage = lazy(() => import('../components/userTicket/UserTicketPage'));

const AssessmentPlanPage = lazy(() => import('../components/assessmentPlan/AssessmentPlanPage'));

const NoMatch: FC = () => {
  const location = useLocation();
  const throwError = useAsyncError();
  const { userInfo } = useAuth().authInfo;

  if (
    !storageUtils.getLocalStorageValue('campaignId') &&
    location.pathname === paths.collectiveDashboard
  ) {
    throwError(new Error('404'));
  }

  return <Redirect to={getDefaultHomePageFor(userInfo)} />;
};

interface IProtectedRoute extends RouteProps {
  roles?: Role[];
  dataPermission?: DataPermissionType;
  dataPermissions?: DataPermissionType[];
  shouldHaveAtLeastOneScoringAssessmentType?: boolean;
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({
  roles = ALL_ROLES_WITHOUT_AGENT,
  dataPermission,
  dataPermissions,
  shouldHaveAtLeastOneScoringAssessmentType,
  ...props
}) => {
  const { hasRoleOrPermission } = useAuth();
  const { hasAtLeastOneScoringAssessmentType } = useUserAssessmentTypePermissions();
  const hasValidAssessmentTypePermissions = shouldHaveAtLeastOneScoringAssessmentType
    ? hasAtLeastOneScoringAssessmentType
    : true;

  if (
    hasRoleOrPermission(roles, dataPermission ?? dataPermissions ?? []) &&
    hasValidAssessmentTypePermissions
  ) {
    return <Route {...props} />;
  }

  return <Route {...props} component={NoMatch} />;
};

const MainRoutes: FC = () => {
  useLoadLocations();

  return (
    <Suspense
      fallback={
        <div className={loaderStyle.loaderTotalCenter}>
          <DotLoader />
        </div>
      }>
      <Switch>
        <Route path={ROUTE_PATHS.util.changeCampaign} component={ChangeCampaignIntermediary} />
        <ProtectedRoute
          dataPermission="REPORT"
          roles={ALL_ROLES}
          path={paths.dashboard}
          component={Dashboard}
        />
        <ProtectedRoute
          dataPermission="CALL"
          roles={ALL_ROLES}
          exact
          path={ROUTE_PATHS.calls.list}
          component={Calls}
        />
        <ProtectedRoute
          roles={ALL_ROLES}
          dataPermission="CALL"
          exact
          path={ROUTE_PATHS.calls.details}
          component={CallDetails}
        />
        <ProtectedRoute
          component={AuditReportUserAllocations}
          exact
          path={ROUTE_PATHS.auditReport.userAllocations}
          roles={[Role.ADMIN, Role.SUPER_ADMIN]}></ProtectedRoute>
        <ProtectedRoute
          component={AuditReportDefaultRoleMapping}
          exact
          path={ROUTE_PATHS.auditReport.defaultRoleMapping}
          roles={[Role.SUPER_ADMIN]}></ProtectedRoute>
        <ProtectedRoute
          component={AuditReportVdnConnections}
          exact
          path={ROUTE_PATHS.auditReport.vdnConnection}
          roles={[Role.SUPER_ADMIN]}></ProtectedRoute>
        <ProtectedRoute
          dataPermission={'ASSESSMENT_READ'}
          roles={ALL_ROLES}
          exact
          path={ROUTE_PATHS.assessments.list}
          component={Assessments}
        />
        <ProtectedRoute exact path={paths.assessmentForms} component={AssessmentForms} />
        <ProtectedRoute
          exact
          path={ROUTE_PATHS.campaignManagement.list}
          component={CampaignManagement}
          roles={[Role.ADMIN, Role.SUPER_ADMIN]}
        />
        {APP_CONFIG.IS_LOCATION_MANAGEMENT_ENABLED && (
          <ProtectedRoute
            exact
            path={ROUTE_PATHS.campaignManagement.locations}
            component={LocationManagement}
            roles={[Role.ADMIN, Role.SUPER_ADMIN]}
          />
        )}
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.withoutCall}
          component={CalibrationWithoutCall}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.fromCall}
          component={CalibrationFromCall}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.fromAssessment}
          component={CalibrationFromAssessment}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.addCalibrators}
          component={CalibrationSessionAddCalibrators}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.initiate}
          component={InitiateCalibration}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.edit}
          component={Calibration}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.submit}
          component={CalibrationSubmit}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.view}
          component={() => <Calibration isView />}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.viewSession}
          component={ViewSession}
        />
        <ProtectedRoute
          path={paths.assessmentForms + paths.formBuilder}
          component={FormBuilder}
          roles={[Role.QA_MANAGER, Role.ADMIN, Role.SUPER_ADMIN]}
        />
        <ProtectedRoute
          dataPermission="CALIBRATION"
          exact
          path={ROUTE_PATHS.calibration.list}
          component={CalibrationPage}
        />
        <ProtectedRoute
          dataPermission={'ASSESSMENT_EDIT'}
          path={paths.assessments + paths.detailsComplete}
          component={AssessmentWithoutCall}
        />
        <ProtectedRoute
          roles={ALL_ROLES}
          dataPermission={'ASSESSMENT_READ'}
          exact
          path={paths.assessments + paths.questionnaire}
          component={Questionnaire}
        />
        <Route
          path={paths.assessments + paths.questionnaire + '/:caseId'}
          component={Questionnaire}
        />
        <ProtectedRoute
          roles={ALL_ROLES_WITHOUT_AGENT}
          dataPermission={'ASSESSMENT_READ'}
          exact
          path={paths.assessments + paths.questionnaireDispute}
          component={QuestionnaireDispute}
        />
        <ProtectedRoute
          roles={ALL_ROLES}
          dataPermission={'ASSESSMENT_READ'}
          path={paths.assessments + paths.questionnaireDispute + '/:caseId'}
          component={QuestionnaireDispute}
        />
        <ProtectedRoute path={paths.assessmentForms + '/:formId'} component={FormView} />
        <ProtectedRoute
          path={paths.campaigns + '/:campaignId'}
          component={CampaignView}
          roles={[Role.ADMIN, Role.SUPER_ADMIN]}
        />
        <ProtectedRoute
          path={ROUTE_PATHS.campaignAssessmentConfiguration.index}
          component={CampaignAssessmentConfigurationView}
          roles={[Role.ADMIN, Role.SUPER_ADMIN, Role.QA_MANAGER]}
        />
        <ProtectedRoute
          exact
          path={ROUTE_PATHS.userManagement.list}
          component={UserManagement}
          roles={[Role.SUPER_ADMIN]}
        />
        <ProtectedRoute
          path={ROUTE_PATHS.userManagement.user}
          component={UserDetails}
          roles={[Role.SUPER_ADMIN, Role.ADMIN]}
        />
        <ProtectedRoute
          path={ROUTE_PATHS.telephonyLogin.list}
          component={TelephonyLoginPage}
          roles={[Role.SUPER_ADMIN, Role.ADMIN]}
        />
        <ProtectedRoute
          path={ROUTE_PATHS.announcement.list}
          component={AnnouncementPage}
          roles={[Role.SUPER_ADMIN]}
        />
        <ProtectedRoute
          path={ROUTE_PATHS.tickets.list}
          component={UserTicketPlaceholderPage}
          roles={ROLES_ALLOWED_TO_ACCESS_TICKETS}
        />
        <ProtectedRoute
          path={ROUTE_PATHS.assessmentPlans}
          component={AssessmentPlanPage}
          roles={ROLES_ALLOWED_TO_EDIT_USER_ASSESSMENT_PLANS}
        />
        <ProtectedRoute
          roles={[Role.SUPER_ADMIN]}
          path={ROUTE_PATHS.calls.unrestrictedList}
          exact
          component={UnrestrictedCalls}
        />
        <NoMatch />
      </Switch>
    </Suspense>
  );
};

export default MainRoutes;
