import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TStorageDownloadToken } from '../../interfaces/report';
import storageUtils from '../../utils/storageUtils';

export const reportDownloadReducerInitialState: Record<
  TStorageDownloadToken,
  { triggered: boolean; ready: boolean }
> = {
  formDashboardExportToken: {
    triggered: Boolean(storageUtils.getLocalStorageValue('formDashboardExportToken')),
    ready: false,
  },
  campaignDashboardExportToken: {
    triggered: Boolean(storageUtils.getLocalStorageValue('campaignDashboardExportToken')),
    ready: false,
  },
  assessmentExportToken: {
    triggered: Boolean(storageUtils.getLocalStorageValue('assessmentExportToken')),
    ready: false,
  },
  calibrationDashboardExportToken: {
    triggered: Boolean(storageUtils.getLocalStorageValue('calibrationDashboardExportToken')),
    ready: false,
  },
  telephonyLoginExportToken: {
    triggered: Boolean(storageUtils.getLocalStorageValue('telephonyLoginExportToken')),
    ready: false,
  },
  userAuditExportToken: {
    triggered: Boolean(storageUtils.getLocalStorageValue('userAuditExportToken')),
    ready: false,
  },
  auditReportExportToken: {
    triggered: Boolean(storageUtils.getLocalStorageValue('auditReportExportToken')),
    ready: false,
  },
  userTicketExportToken: {
    triggered: Boolean(storageUtils.getLocalStorageValue('userTicketExportToken')),
    ready: false,
  },
};

const reportDownloadReducer = createSlice({
  name: 'reportDownloadReducer',
  initialState: reportDownloadReducerInitialState,
  reducers: {
    markAsReady: (state, { payload }: PayloadAction<TStorageDownloadToken>) => {
      state[payload].ready = true;
    },
    markAsNotReady: (state, { payload }: PayloadAction<TStorageDownloadToken>) => {
      state[payload].ready = false;
    },
    markAsTriggered: (state, { payload }: PayloadAction<TStorageDownloadToken>) => {
      state[payload].triggered = true;
    },
    markAsNotTriggered: (state, { payload }: PayloadAction<TStorageDownloadToken>) => {
      state[payload].triggered = false;
    },
    reset: (state, { payload }: PayloadAction<TStorageDownloadToken>) => {
      state[payload].triggered = false;
      state[payload].ready = false;
    },
  },
});

export const reportDownloadActions = reportDownloadReducer.actions;

export default reportDownloadReducer.reducer;
