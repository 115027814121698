import { useState, useCallback, Dispatch, SetStateAction } from 'react';

export const useAsyncError = (): Dispatch<SetStateAction<Error>> => {
  const [, setError] = useState();

  return useCallback(
    (error) => {
      setError(() => {
        throw error;
      });
    },
    [setError],
  );
};
