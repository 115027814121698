import { IStore } from '..';
import { UUID } from '../../interfaces';

export const getCalibration = (state: IStore) => state.calibrationReducer.calibration.details;
export const getAnsweredQuestions = (state: IStore) =>
  state.calibrationReducer.calibration.answeredQuestions;
export const getModalController = (state: IStore) => state.calibrationReducer.modals;
export const getSessionId = (state: IStore) => state.calibrationReducer.sessionId;
export const getSession = (state: IStore) => state.calibrationReducer.session;
export const getQuestionById = (questionId: UUID) => (state: IStore) =>
  state.calibrationReducer.session?.questions[questionId];
export const getQuestions = (state: IStore) => state.calibrationReducer.session?.questions;
export const getCalibrationsCountRefreshFlag = (state: IStore) =>
  state.calibrationReducer.calibrationsCountRefreshFlag;
