import i18n from 'i18next';

import {
  IConditionalQuestion,
  IFormValues,
} from '../../questionnaire/questionnaireForm/IQuestionnaireForm';
import { IKeyObject, IName, ISelectOption } from './IHelpers';
import { MailTemplateType, STATUS_TYPES } from '../constants';
import { Role } from '../../../providers/IPopulatorProvider';
import { IRoles } from '../../campaignManagement/ICampaignManagement';

interface IQuestionResponse {
  comment: string | undefined;
  selectedAnswerIds: string[];
}

export function transformQuestionToResponse(
  selectedAnswerId: string[],
  key: string,
  comment?: string,
): IKeyObject<IQuestionResponse> {
  return {
    [key]: {
      selectedAnswerIds: [selectedAnswerId].flat(),
      comment,
    },
  };
}

export function transformConditionalQuestions(
  conditionalQuestion: IConditionalQuestion,
  selectedAnswerId: string[],
): IKeyObject<IQuestionResponse>[] {
  return Object.keys(conditionalQuestion)
    .filter((conditionalAnswer) => selectedAnswerId.includes(conditionalAnswer))
    .map((answerId) => conditionalQuestion[answerId])
    .map((elem) => {
      const conditionalQuestionId: string = Object.keys(elem).toString();
      const conditionalQuestionAnswers = elem[conditionalQuestionId].selectedAnswerId;

      return (
        conditionalQuestionAnswers &&
        transformQuestionToResponse(conditionalQuestionAnswers, conditionalQuestionId)
      );
    })
    .filter((e) => !!e);
}

/*
  @values
  ADD COMMENT - strange function for formik
*/
export const transformToResponse = (values: IFormValues): {} => {
  const questionAnswers = {};

  const additionalQuestions = values.additionalQuestions.flatMap((question) => {
    const firstKey = Object.keys(question)[0];
    return [
      transformQuestionToResponse(question[firstKey].selectedAnswerId, firstKey),
      ...transformConditionalQuestions(
        question[firstKey].conditionalQuestion || {},
        question[firstKey].selectedAnswerId,
      ),
    ];
  });

  Object.assign(questionAnswers, ...additionalQuestions);

  for (let i = 0; i < values.sections.length; i++) {
    const { questions } = values.sections[i];
    const selectedQuestions = questions.flatMap((question) => {
      for (const key in question) {
        const { selectedAnswerId, comment, conditionalQuestion } = question[key];
        if (!(selectedAnswerId || comment)) {
          return;
        }
        return [
          transformQuestionToResponse(selectedAnswerId, key, comment),
          ...transformConditionalQuestions(conditionalQuestion || {}, selectedAnswerId),
        ];
      }
    });

    Object.assign(questionAnswers, ...selectedQuestions);
  }

  return questionAnswers;
};

export const createName = (name: IName): string => {
  return name.firstName + ' ' + name.lastName;
};

export const concatStrings = (strings: (number | string)[], separator = ', '): string => {
  return strings.filter((s) => !!s).join(separator);
};

export const formatRole = (role: string): string => {
  let formatedRole = '';

  role.includes('_') ? (formatedRole = role.replace('_', ' ')) : (formatedRole = role);

  return formatedRole;
};

export const transformMailTemplateType = (type: MailTemplateType): string => {
  switch (type) {
    case MailTemplateType.ASSESSMENT_NOTIFICATION:
      return i18n.t('mail.template.notification');
    case MailTemplateType.ASSESSMENT_SUMMARY:
      return i18n.t('mail.template.summary');
    case MailTemplateType.ASSESSMENT_RESULT_SUMMARY:
      return i18n.t('mail.template.resultSummary');
    default:
      return type;
  }
};

export const transformStatus = (status: string): string => {
  switch (status) {
    case STATUS_TYPES.IN_REVIEW:
      return i18n.t('list.status.reviewInProgress');
    case STATUS_TYPES.DRAFT:
      return i18n.t('list.status.draft');
    case STATUS_TYPES.DISPUTE_REQUESTED:
      return i18n.t('list.status.disputeRequested');
    case STATUS_TYPES.DISPUTE_CLOSED:
      return i18n.t('list.status.disputeClosed');
    case STATUS_TYPES.DISPUTE_IN_PROGRESS:
      return i18n.t('list.status.disputeInProgress');
    case STATUS_TYPES.DISPUTE_DRAFT:
      return i18n.t('list.status.disputeDraft');
    case STATUS_TYPES.IN_PROGRESS:
      return i18n.t('list.status.inProgress');
    case STATUS_TYPES.SUBMITTED:
      return i18n.t('list.status.submitted');
    case STATUS_TYPES.FEEDBACK_REQUIRED:
      return i18n.t('list.status.supervisorFeedbackRequired');
    case STATUS_TYPES.DONE:
      return i18n.t('list.status.complete');
    default:
      return status;
  }
};

export const transformResult = (result: string, failed: boolean | undefined): string => {
  if (result === 'true') {
    return `${i18n.t('scoring.passed')}${failed ? i18n.t('scoring.fail') : ''}`;
  } else if (result === 'false') {
    return `${i18n.t('scoring.failed')}${failed ? i18n.t('scoring.fail') : ''}`;
  }

  return `${result || ''}${failed && result ? i18n.t('scoring.fail') : ''}`;
};

export const replaceCharacter = (
  item: string | IKeyObject<string>,
  char1: string,
  char2: string,
): string => {
  return item.toString().replace(char1, char2).toLocaleLowerCase();
};

export const transformRoles = (role: string): string => {
  switch (role) {
    case Role.AGENT:
      return i18n.t('details.agent');
    case Role.QA:
      return i18n.t('list.qa');
    case Role.TEAM_LEAD:
      return i18n.t('details.teamLead');
    case Role.RESTRICTED:
      return i18n.t('role.restricted');
    case Role.DEFAULT:
      return i18n.t('campaign.role.default');
    case Role.ADMIN:
      return i18n.t('campaign.role.admin');
    case Role.QA_MANAGER:
      return i18n.t('campaign.role.qaManager');
    default:
      return role;
  }
};

export const transformRoleToMailRoleName = (role: string): string => {
  switch (role) {
    case Role.AGENT:
      return i18n.t('role.mailNotification.agent');
    case Role.QA:
      return i18n.t('role.mailNotification.qa');
    case Role.TEAM_LEAD:
      return i18n.t('role.mailNotification.teamLead');
    case Role.QA_MANAGER:
      return i18n.t('role.mailNotification.qaManager');
    default:
      return role;
  }
};

export const transformRolesToSelect = (list: IRoles[]): ISelectOption<string, string>[] => {
  return list.map(({ role }: IRoles) => {
    return {
      label: transformRoles(role),
      value: role,
    };
  });
};
