import { UUID } from '../../../interfaces';
import {
  ICallBaseWithThemeTranscriptAnalysis,
  ICallBaseWithTranscriptAnalysis,
  ISearchCallsTopic,
} from '../../../interfaces/call';
import { SectionDiscriminator } from '../../../interfaces/form';
import { IMetricDistributionReport } from '../../../interfaces/report';
import { IUser } from '../../campaignManagement/shared/interfaces';
import {
  IKeyObject,
  IPageable,
  ISelectOption,
  ISortList,
  IValueId,
} from '../../shared/helpers/IHelpers';
import { PagedSearch } from '../../shared/interfaces';
import { AGGREGATION_GRANULARITY } from '../helpers/constants';
import { ASSESSMENT_CHECK_TYPE } from '../../shared/constants';
import { IUnacceptableType } from '../../../interfaces/assessment';
import { TPercentage, TThemeId } from '../../../interfaces/general';

export interface IDashboardReducer {
  filters: IDashboardRequest;
  filtersChanged: IFiltersChanged;
  additionalTranscriptFilters: TAdditionalTranscriptFilter[];
  additionalQuestionFilter: UUID[];
  conditionalAdditionalQuestionAnswerPairFilter: TQuestionIdAnswerIdPair[];
  teamLeadsPerformance: ITeamLeadPerformance[];
  teamLeadsWithoutAssessments: ITeamLeadPerformance[];
  generalData?: IDashboardSummary;
  improvementAreaSummary?: IImprovementAreaSummary[];
  scoringTimeline?: IDashboardTimeline<IDashboardScoringTimeline[]>;
  userPerformanceTimeline: {
    teamAssessmentPerformanceTimeline?: IDashboardTimeline<
      Record<string, Record<string, IDashboardUserPerformanceTimeline>>,
      IValueId[]
    >;
    teamMinScoreAssessmentPerformanceTimeline?: IDashboardTimeline<
      Record<string, Record<string, IDashboardUserMinScorePerformanceTimeline>>,
      IValueId[]
    >;
    teamQuestionsPerformanceTimeline: IDashboardTimeline<
      Record<string, Record<string, Record<string, IDashboardAveragePerformanceTrendTimeline>>>,
      {
        sections: {
          id: string;
          name: string;
          position: number;
          questions: { id: string; content: string; position: number }[];
        }[];
        users: IValueId[];
      }
    >;
    teamSectionsPerformanceTimeline: IDashboardTimeline<
      Record<string, Record<string, Record<string, IDashboardAveragePerformanceTrendTimeline>>>,
      {
        sections: { id: string; name: string; position: number }[];
        users: IValueId[];
      }
    >;
  };
  unacceptableTypeTimeline?: IDashboardTimeline<IDashboardUnacceptableTypeTimeline[]>;
  statusTimeline?: IDashboardTimeline<IDashboardStatusTimeline[]>;
  sectionResults: ISectionResultsSummary[];
  sectionQuestionSummary: IKeyObject<ISectionQuestionSummary>;
  assessmentForm: {
    sectionsPerformance: IDashboardTimeline<IAssessmentPerformanceReport>;
    questionsPerformance: IDashboardTimeline<IQuestionsReport<TAssessmentPerformancePayload>>;
  };
  improvementAreaPerformance: IDashboardTimeline<IImprovementAreaPerformanceReport>;
  agentPerformance?: IAgentPerformance;
  scoringComparison?: IDashboardTimeline<IScoringComparison[]>;
  calibration: {
    calibrationsComparison?: IDashboardTimeline<ICalibrationReportResponse[]>;
    sectionsPerformance: IDashboardTimeline<ICalibrationSectionsPerformanceReport>;
    questionsPerformance: IDashboardTimeline<IQuestionsReport<TCalibrationScoreDifferencePayload>>;
  };
  additionalQuestions: IQuestionSummary[];
  conditionalQuestions: IKeyObject<IQuestionSummary[]>;
  presentationFilters: IDashboardFilters<ISelectOption>;
  themeData: {
    hideNAThemeLabels: boolean;
    themeFilters: TThemeFilter[];
    campaignThemes: IThemeDefinition[];
    themeSort: { field: keyof IThemeLabelCountReport };
    visibleThemesConfig: {
      themes: ISelectOption[];
      isLoading: boolean;
    };
  };
  transcriptAnalysis: {
    calls: TDashboardReportCalls;
    callsWithThemes: TDashboardReportCallsWithThemes;
    topicCount: ITopicCountReport[];
    themeLabelsCount: IThemeLabelCountReport[];
    themeLabelsTrend: IDashboardTimeline<
      Record<TThemeId, TThemeLabelTrendReport[]>,
      Record<TThemeId, IThemeDefinition>
    >;
    agentTopicsByIndex?: ISearchCallsTopic[][];
    negativityDistribution: ITranscriptAnalysisMetricsDistribution[];
    paceDistribution: ITranscriptAnalysisMetricsDistribution[];
    silenceDistribution: ITranscriptAnalysisMetricsDistribution[];
    overtalkDistribution: ITranscriptAnalysisMetricsDistribution[];
    topicsTimeline: IDashboardTimeline<ITopicTimeline[]>;
    metricsTimeline: IDashboardTimeline<TMetricsTimeLineReport[]>;
    teamMetrics: TUserMetricsReport[];
    agentNames: IKeyObject;
    agentsMetrics: Record<UUID, TUserMetricsReport[]>;
    summaryMetrics?: ITranscriptAnalysisSummaryMetrics;
  };
}

export type ITranscriptAnalysisSummaryMetrics = {
  initial: TMetricsReport;
  current: TMetricsReport;
};

export interface IFiltersChanged {
  campaignDashboard: boolean;
  calibrationDashboard: boolean;
  transcriptAnalysis: boolean;
  formDashboard: boolean;
}

export interface IDateRange {
  start: number | Date;
  end: number | Date;
}

export interface IDashboardTimeline<T, Meta = IKeyObject> {
  data: T;
  meta?: Meta;
  granularity?: AGGREGATION_GRANULARITY;
}

export interface ISectionResultsSummary {
  section: string;
  sectionId: UUID;
  position: number;
  type: SectionDiscriminator;
  data: Array<{ result: string; count: number }>;
}

export interface IAnswerCount {
  id: UUID;
  conditionalQuestionId?: UUID;
  position: number;
  label?: string;
  content: string;
  count: number;
}

export interface IQuestionSummary {
  position: number;
  id: UUID;
  content: string;
  answers: IAnswerCount[];
  hasConditionalQuestions: boolean;
}

export interface ICalibrationReportResponse {
  dateRange: IDateRange;
  total: number;
  mainScoreAverage: number;
  mainPassFailAverage: number;
  calibratorsScoreAverage: number;
  calibratorsPassFailAverage: number;
}

export interface IAssessmentPerformanceReportResponse {
  passFailSections: ISectionPerformanceSummaryResponse<SectionDiscriminator.PASS_FAIL>[];
  percentageSections: ISectionPerformanceSummaryResponse<
    SectionDiscriminator.PERCENTILE_GROUPINGS | SectionDiscriminator.SUMMED_SCORE
  >[];
}

export type ISectionsPerformance<T extends SectionDiscriminator> = {
  dateRange: IDateRange;
  sections: {
    [sectionId: string]: T extends SectionDiscriminator.PASS_FAIL
      ? { passed?: number; failed?: number }
      : number;
  };
};

export type IImprovementAreaPerformanceReport = {
  improvementAreaData: IImprovementAreaPerformance[];
  improvementAreaNames: IKeyObject;
};

export type IImprovementAreaPerformance = {
  dateRange: IDateRange;
  improvementAreas: {
    [improvementId: string]: number;
  };
};

export type TCalibrationScoreDifferencePayload = {
  mainCalibratorScore: number;
  calibratorsAverage: number;
  scoreDifference: number;
};

export type TAssessmentPerformancePayload = {
  average: number;
};

export type ITopicTimeline = {
  dateRange: IDateRange;
  topics: Record<UUID, { count: number; externalId: string }>;
};

export type ICalibrationSectionPerformance = {
  dateRange: IDateRange;
  sections: Record<UUID, TCalibrationScoreDifferencePayload>;
};

export type ICalibrationQuestionDifference = {
  dateRange: IDateRange;
  questions: Record<UUID, TCalibrationScoreDifferencePayload>;
};

export type IQuestionReport<T> = {
  dateRange: IDateRange;
  questions: Record<UUID, T>;
};

export interface IAssessmentPerformanceReport {
  passFailSections: ISectionsPerformance<SectionDiscriminator.PASS_FAIL>[];
  percentageSections: ISectionsPerformance<
    SectionDiscriminator.PERCENTILE_GROUPINGS | SectionDiscriminator.SUMMED_SCORE
  >[];
  passFailSectionNames: IKeyObject<{ name: string; position: number }>;
  percentageSectionNames: IKeyObject<{ name: string; position: number }>;
}

export interface ICalibrationSectionsPerformanceReport {
  passFailSections: ICalibrationSectionPerformance[];
  percentageSections: ICalibrationSectionPerformance[];
  passFailSectionNames: IKeyObject<{ name: string; position: number }>;
  percentageSectionNames: IKeyObject<{ name: string; position: number }>;
}

export type TQuestionMeta = {
  content: string;
  position: number;
  sectionName: string;
  questionId: UUID;
  sectionId: UUID;
  sectionPosition: number;
};

export interface ICalibrationQuestionsReport {
  passFailQuestions: ICalibrationQuestionDifference[];
  percentageQuestions: ICalibrationQuestionDifference[];
  passFailQuestionsMeta: IKeyObject<TQuestionMeta>;
  percentageQuestionsMeta: IKeyObject<TQuestionMeta>;
}

export interface IQuestionsReport<T> {
  passFailQuestions: IQuestionReport<T>[];
  percentageQuestions: IQuestionReport<T>[];
  passFailQuestionsMeta: IKeyObject<TQuestionMeta>;
  percentageQuestionsMeta: IKeyObject<TQuestionMeta>;
}

export type TSectionPerformanceData<T extends SectionDiscriminator> = {
  dateRange: IDateRange;
} & (T extends 'PASS_FAIL'
  ? {
      passed?: number;
      failed?: number;
    }
  : TAssessmentPerformancePayload);

export type TImprovementAreaPerformanceData = {
  dateRange: IDateRange;
  count: number;
};

export type TCalibrationScoreDifferenceResponse = {
  dateRange: IDateRange;
  mainCalibratorScore: number;
  calibratorsAverage: number;
  scoreDifference: number;
};

export interface ISectionPerformanceSummaryResponse<T extends SectionDiscriminator> {
  sectionName: string;
  sectionId: UUID;
  position: number;
  type: T;
  data: Array<TSectionPerformanceData<T>>;
}

export interface ICalibrationSectionPerformanceSummaryResponse {
  name: string;
  id: UUID;
  position: number;
  type: SectionDiscriminator;
  data: Array<TCalibrationScoreDifferenceResponse>;
}

export type IQuestionSummaryReportResponse<T extends { dateRange: IDateRange }> = {
  sectionId: UUID;
  sectionName: string;
  sectionPosition: number;
  sectionType: SectionDiscriminator;
  questions: {
    id: UUID;
    content: string;
    position: number;
    data: Array<T>;
  }[];
}[];

export type IImprovementAreaTimelineSummaryResponse = {
  improvementName: string;
  improvementId: UUID;
  data: TImprovementAreaPerformanceData[];
}[];

export interface ISectionQuestionSummary {
  sectionName: string;
  sectionId: UUID;
  position: number;
  questions: IQuestionSummary[];
}

export interface IDashboardFilters<T = string, Q = string> {
  form?: T;
  forms?: T[];
  campaigns: T[];
  sites: T[];
  countries: T[];
  agents?: T[];
  assessees?: T[];
  teamLeads?: T[];
  managers?: T[];
  qas?: T[];
  assessmentDateType?: AssessmentDateType;
  unacceptableTypes?: T[];
  initialAssessor?: T;
  referenceType?: T;
  sessionType?: T;
  mainCalibrators?: T[];
  calibrators?: T[];
  assessmentTypes?: T[];
  model?: T;
  activities?: T[];
  vdns?: T[];
  themes?: T[];
  assessorRoles?: T[];
  reportPeriod: {
    startDate: Q;
    endDate: Q;
  };
  channels?: T[];
  transcriptAnalysisMetric: TranscriptAnalysisMetric;
}

export enum TranscriptAnalysisMetric {
  AGENT = 'AGENT',
  CUSTOMER = 'CUSTOMER',
  ALL = 'ALL',
}

export enum AssessmentDateType {
  CREATION_DATE = 'CREATION_DATE',
  LAST_UPDATED_DATE = 'LAST_UPDATED_DATE',
  CASE_DATE = 'CASE_DATE',
}

export interface IDashboardSummary {
  completionRate: number;
  countByStatus: IKeyObject<number>;
  scoreAverage: number | null;
  passFailAverage: number | null;
  total: number;
  withCriticalFail: number;
}

export interface IImprovementAreaSummary {
  id: string;
  name: string;
  count: number;
}

export interface IPagedDashboardRequest extends IDashboardRequest {
  pageRequest: IPageable;
}

export interface IDashboardRequest {
  startDate: string;
  endDate: string;
  campaignIds?: string[];
  siteIds?: string[];
  countryIds?: string[];
  supervisorIds?: string[];
  agentIds?: string[];
  assessorIds?: string[];
  activityReferences?: string[];
  formId?: string;
  formIds?: string[];
  modelId?: string;
  searchCounter?: boolean;
  aggregationGranularity?: AGGREGATION_GRANULARITY;
  mainCalibratorIds?: string[];
  calibratorIds?: string[];
  initialAssessor?: string;
  assessmentTypes?: string[];
  referenceType?: string;
  sessionType?: string;
  assessorRoles?: string[];
  unacceptableTypes?: string[];
  vdnNames?: string[];
  themeIds?: string[];
  transcriptAnalysisMetric?: TranscriptAnalysisMetric;
  assessmentDateType?: AssessmentDateType;
  caseChannels?: string[];
}

type TAssessmentScoreResult = {
  absoluteScoreAverage: number;
  absoluteMaxScoreAverage: number;
  scoreAverage: number;
  passFailAverage: number;
  criticalFailRate: number;
};

export interface IDashboardScoringTimeline extends TAssessmentScoreResult {
  dateRange: IDateRange;
  total: number;
}

export interface IDashboardUserPerformanceTimeline {
  criticalFailAverage?: number;
  scoreAverage?: number;
  passFailAverage?: number;
}

export interface IDashboardUserMinScorePerformanceTimeline {
  countByScore: IMinScoreEntries;
  /**
   * Computed in frontend only
   */
  percentageByScoreCount: IMinScoreEntries;
}

export interface IDashboardAveragePerformanceTrendTimeline {
  average?: number;
}

export interface IDashboardUnacceptableTypeTimeline {
  dateRange: IDateRange;
  total: number;
  typesCount: Record<IUnacceptableType, number>;
}

export interface IDashboardStatusTimeline {
  countByStatus: IKeyObject<number>;
  dateRange: IDateRange;
  total: number;
}

export interface IAgentPerformance {
  bottomPerformers: {
    scoreAverage: number;
    passFailAverage: number;
    user: IValueId;
  }[];
  topPerformers: {
    scoreAverage: number;
    passFailAverage: number;
    user: IValueId;
  }[];
}

export type TLoadTeamApiCall = (
  filters: IPagedDashboardRequest,
  teamLeadId?: string,
) => Promise<PagedSearch<ITeamLeadPerformanceResponse>>;

export interface ITeamLeadPerformanceResponse {
  assessmentsCount?: number;
  checkTypeSummaries?: IAssessmentCheckSummary[];
  criticalFails?: number;
  scoreAverage?: number;
  passFailAverage?: number;
  agentCount?: number;
  user: Partial<IValueId>;
}

export interface ITeamLeadPerformance {
  assessmentsCount?: number;
  checkTypeSummaries?: IAssessmentSummaries;
  criticalFails?: number;
  scoreAverage?: number;
  passFailAverage?: number;
  agentCount?: number;
  user: Partial<IValueId>;
}

export interface IAssessmentCheckSummary {
  count: number;
  type: keyof typeof ASSESSMENT_CHECK_TYPE;
}

export type IAssessmentSummaries = {
  [key in keyof typeof ASSESSMENT_CHECK_TYPE]?: number;
};

export interface IScoringComparison extends IDashboardScoringTimeline {
  disputeResult: TAssessmentScoreResult;
  initialResult: TAssessmentScoreResult;
}

export interface ITopicCountReportResponse {
  id: UUID;
  name?: string;
  description: string[];
  alias?: string;
  externalId: string;
  count: number;
}

export type TThemeLabelType = 'RED' | 'AMBER' | 'GREEN';

export interface IThemeDefinition {
  id: UUID;
  name: string;
  description: string;
  externalId: string;
}

export interface IThemeCountReportResponse extends IThemeDefinition {
  count: { label: TThemeLabelType; count: number }[];
}

export interface IThemeLabelTrendReportResponse extends IThemeDefinition {
  count: { dateRange: IDateRange; labelCount: { label: TThemeLabelType; count: number }[] }[];
}

export interface ITopicCountReport extends ITopicCountReportResponse {
  initialCount: number;
}

export interface IThemeLabelCountReport extends IThemeDefinition {
  totalCount: number;
  initialTotalCount?: number;
  count: Record<TThemeLabelType, TPercentage>;
  initialCount?: Record<TThemeLabelType, TPercentage>;
}

export type TThemeLabelReport = Record<TThemeLabelType, number>;

export type TThemeLabelTrendReport = {
  dateRange: IDateRange;
  count: TThemeLabelReport;
};

export interface ITopicTimelineReport {
  topicId: UUID;
  name: string;
  alias: string;
  externalId: string;
  data: { dateRange: IDateRange; count: number }[];
}

export type TMetricsReport = {
  overtalkCount?: number;
  paceAvg?: number;
  silence?: number;
  negativityAvg?: number;
};
export type TTranscriptAnalysisMetrics = keyof TMetricsReport;

export type TMetricsTimeLineReport = {
  dateRange: IDateRange;
} & TMetricsReport;

export type TUserMetricsReport = {
  user?: IUser;
  count: number;
} & TMetricsReport;

export interface ITranscriptAnalysisMetricsDistribution extends IMetricDistributionReport {
  initialValue?: number;
}

export type TThemeFilter = { themeId: string; value: TThemeLabelType };

export type TChartSelectionFilter = { min: number; max?: number };
export type TAdditionalTranscriptFilter = Partial<{
  negativityAvgBounds: TChartSelectionFilter[];
  paceAvgBounds: TChartSelectionFilter[];
  silencePerMinuteBounds: TChartSelectionFilter[];
  sectionTopicIds: UUID[];
  overtalkPerMinuteBounds: TChartSelectionFilter[];
  periodBounds: { startDate: string; endDate: string }[];
  supervisorIds: UUID[];
  agentIds: UUID[];
}>;

export type TQuestionIdAnswerIdPair = { questionId: UUID; answerId: UUID };

export type TDashboardReportCalls = {
  page?: PagedSearch<ICallBaseWithTranscriptAnalysis>;
  sort?: ISortList[];
};

export type TDashboardReportCallsWithThemes = {
  page?: PagedSearch<ICallBaseWithThemeTranscriptAnalysis>;
  sort?: ISortList[];
};

export interface TQuestionPerformanceTrendResult<T extends IValueId | undefined = IValueId> {
  sectionsMetadata: {
    id: string;
    name: string;
    position: number;
    questions: { id: string; content: string; position: number }[];
  }[];
  page: PagedSearch<{
    user: T;
    sections: {
      id: string;
      questions: {
        id: string;
        data: {
          dateRange: { start: string; end: string };
          average: number;
        }[];
      }[];
    }[];
  }>;
}

export interface ISectionPerformanceTrendResult<T extends IValueId | undefined = IValueId> {
  sectionsMetadata: { id: string; name: string; position: number }[];
  page: PagedSearch<{
    user: T;
    sections: {
      id: string;
      data: {
        dateRange: { start: string; end: string };
        average: number;
      }[];
    }[];
  }>;
}

export interface IAssessmentPerformanceTrendResult<T extends IValueId | undefined = IValueId> {
  user: T;
  data: ({
    dateRange: { start: string; end: string };
  } & IDashboardUserPerformanceTimeline)[];
}

export interface IMinScoreAssessmentPerformanceTrendResult<
  T extends IValueId | undefined = IValueId
> {
  user: T;
  data: {
    dateRange: { start: string; end: string };
    countByScore: IMinScoreEntries;
    /**
     * Computed on frontend only
     */
    percentageByScoreCount: IMinScoreEntries;
  }[];
}

export type IMinScoreEntries = {
  [key: string]: number;
};

export interface ITeamQuestionsPerformanceTrendMeta {
  sections: {
    id: string;
    name: string;
    position: number;
    questions: { id: string; content: string; position: number }[];
  }[];
  users: IValueId[];
}

export interface ITeamSectionsPerformanceTrendMeta {
  sections: { id: string; name: string; position: number }[];
  users: IValueId[];
}
