import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UUID } from '../../interfaces';
import {
  IAssessmentCalibration,
  IDeleteModal,
  IDownloadModal,
  ILocationModal,
  IModal,
  IModalController,
  IReimportModal,
} from '../../interfaces/modal';
import { IEditAnnouncementRequest } from '../../interfaces/announcement';
import { IAssessmentType } from '../../interfaces/assessment';
import { IUserTicketComment, TAddUserTicket } from '../../interfaces/userTicket';
import {
  IAssessmentPlanCreateOrUpdate,
  IAssessmentPlanData,
  IAssessmentPlanDistributeEvenly,
  IAssessmentPlanFillAll,
  IAssessmentPlanHistory,
  IAssessmentPlanImportPreviousPlan,
  IAssessmentPlanAllocateVariance,
  IAssessmentPlansDiscardWarning,
  IAssessmentPlanDelete,
} from '../../components/assessmentPlan/IAssessmentPlan';

export const modalReducerInitialState: IModalController = {
  assessmentPlanModals: {
    createOrEditAssessmentPlanModal: {
      isOpen: false,
    },
    historyModal: {
      isOpen: false,
    },
    historyDetailsModal: {
      isOpen: false,
    },
    createOrEditUserAssessmentPlanModal: {
      isOpen: false,
    },
    untrackUsersModal: {
      isOpen: false,
    },
    discardWarningModal: {
      isOpen: false,
    },
    fillAllModal: {
      isOpen: false,
    },
    importPreviousPlanModal: {
      isOpen: false,
    },
    distributeEvenlyModal: {
      isOpen: false,
    },
    allocateVarianceModal: {
      isOpen: false,
    },
  },
  auditReportModal: {
    isOpen: false,
  },
  form: {
    saveAsDraftConfirmation: {
      isOpen: false,
    },
  },
  transcriptModal: {
    isOpen: false,
  },
  deleteModal: {
    isOpen: false,
  },
  formListModal: {
    isOpen: false,
  },
  formPickerWarningModal: {
    isOpen: false,
  },
  downloadCallModal: {
    isOpen: false,
  },
  createCallList: {
    isOpen: false,
  },
  assessmentModals: {
    disputeModal: {
      isOpen: false,
    },
    acknowledgementModal: {
      isOpen: false,
    },
    trackedTimeModal: {
      isOpen: false,
    },
    successModal: {
      isOpen: false,
    },
    reportingModal: {
      isOpen: false,
    },
    calibrationModal: {
      isOpen: false,
    },
  },
  telephonyLoginModals: {
    deleteModal: {
      isOpen: false,
    },
    createModal: {
      isOpen: false,
    },
    reimportModal: {
      isOpen: false,
    },
    reportingModal: {
      isOpen: false,
    },
  },
  improvementAreaModal: {
    isOpen: false,
  },
  internalActivityModal: {
    isOpen: false,
  },
  announcementModals: {
    createModal: {
      isOpen: false,
    },
    editModal: {
      isOpen: false,
    },
  },
  userTicketModals: {
    createModal: {
      isOpen: false,
      mode: null,
      ticketId: '',
      initialData: {
        description: '',
        internalDevelopment: false,
      },
    },
    reportModal: {
      isOpen: false,
    },
    reactionsModal: {
      isOpen: false,
    },
    commentEditModal: {
      isOpen: false,
    },
  },
  userAuditModal: {
    isOpen: false,
  },
  locationModal: {
    isOpen: false,
  },
};

const modalReducer = createSlice({
  name: 'modalReducer',
  initialState: modalReducerInitialState,
  reducers: {
    openUserAssessmentPlanModal: (
      state,
      { payload }: PayloadAction<IAssessmentPlanCreateOrUpdate | undefined>,
    ) => {
      state.assessmentPlanModals.createOrEditUserAssessmentPlanModal = {
        isOpen: true,
        data: payload,
      };
    },
    closeUserAssessmentPlanModal: (state) => {
      state.assessmentPlanModals.createOrEditUserAssessmentPlanModal =
        modalReducerInitialState.assessmentPlanModals.createOrEditUserAssessmentPlanModal;
    },
    openAssessmentPlanModal: (
      state,
      { payload }: PayloadAction<IAssessmentPlanData | undefined>,
    ) => {
      state.assessmentPlanModals.createOrEditAssessmentPlanModal = {
        isOpen: true,
        data: payload,
      };
    },
    closeAssessmentPlanModal: (state) => {
      state.assessmentPlanModals.createOrEditAssessmentPlanModal =
        modalReducerInitialState.assessmentPlanModals.createOrEditAssessmentPlanModal;
    },
    openUserAssessmentPlanHistoryModal: (state, payload: PayloadAction<IAssessmentType>) => {
      state.assessmentPlanModals.historyModal = {
        isOpen: true,
        data: payload.payload,
      };
    },
    closeUserAssessmentPlanHistoryModal: (state) => {
      state.assessmentPlanModals.historyModal =
        modalReducerInitialState.assessmentPlanModals.historyModal;
    },

    openUserAssessmentPlanHistoryDetailsModal: (
      state,
      payload: PayloadAction<IAssessmentPlanHistory>,
    ) => {
      state.assessmentPlanModals.historyDetailsModal = {
        isOpen: true,
        data: payload.payload,
      };
    },
    closeUserAssessmentPlanHistoryDetailsModal: (state) => {
      state.assessmentPlanModals.historyDetailsModal =
        modalReducerInitialState.assessmentPlanModals.historyDetailsModal;
    },
    openAssessmentPlanUntrackUsersModal: (state, payload: PayloadAction<IAssessmentPlanDelete>) => {
      state.assessmentPlanModals.untrackUsersModal = {
        isOpen: true,
        data: payload.payload,
      };
    },
    closeAssessmentPlanUntrackUsersModal: (state) => {
      state.assessmentPlanModals.untrackUsersModal =
        modalReducerInitialState.assessmentPlanModals.untrackUsersModal;
    },
    openAssessmentPlansDiscardWarningModal: (
      state,
      payload: PayloadAction<IAssessmentPlansDiscardWarning>,
    ) => {
      state.assessmentPlanModals.discardWarningModal = {
        isOpen: true,
        data: payload.payload,
      };
    },
    closeAssessmentPlansDiscardWarningModal: (state) => {
      state.assessmentPlanModals.discardWarningModal =
        modalReducerInitialState.assessmentPlanModals.discardWarningModal;
    },
    openUserAssessmentPlanFillAllModal: (state, payload: PayloadAction<IAssessmentPlanFillAll>) => {
      state.assessmentPlanModals.fillAllModal = {
        isOpen: true,
        data: payload.payload,
      };
    },
    closeUserAssessmentPlanFillAllModal: (state) => {
      state.assessmentPlanModals.fillAllModal =
        modalReducerInitialState.assessmentPlanModals.fillAllModal;
    },
    openUserAssessmentPlanImportPreviousPlanModal: (
      state,
      payload: PayloadAction<IAssessmentPlanImportPreviousPlan>,
    ) => {
      state.assessmentPlanModals.importPreviousPlanModal = {
        isOpen: true,
        data: payload.payload,
      };
    },
    closeUserAssessmentPlanImportPreviousPlanModal: (state) => {
      state.assessmentPlanModals.importPreviousPlanModal =
        modalReducerInitialState.assessmentPlanModals.importPreviousPlanModal;
    },
    openUserAssessmentPlanDistributeEvenlyModal: (
      state,
      payload: PayloadAction<IAssessmentPlanDistributeEvenly>,
    ) => {
      state.assessmentPlanModals.distributeEvenlyModal = {
        isOpen: true,
        data: payload.payload,
      };
    },
    closeUserAssessmentPlanDistributeEvenlyModal: (state) => {
      state.assessmentPlanModals.distributeEvenlyModal =
        modalReducerInitialState.assessmentPlanModals.distributeEvenlyModal;
    },
    openUserAssessmentPlanAllocateVarianceModal: (
      state,
      payload: PayloadAction<IAssessmentPlanAllocateVariance>,
    ) => {
      state.assessmentPlanModals.allocateVarianceModal = {
        isOpen: true,
        data: payload.payload,
      };
    },
    closeUserAssessmentPlanAllocateVarianceModal: (state) => {
      state.assessmentPlanModals.allocateVarianceModal =
        modalReducerInitialState.assessmentPlanModals.allocateVarianceModal;
    },
    openTranscriptModal: (state, { payload }: PayloadAction<string>) => {
      state.transcriptModal = {
        isOpen: true,
        data: payload,
      };
    },
    closeTranscriptModal: (state) => {
      state.transcriptModal = modalReducerInitialState.transcriptModal;
    },
    setDeleteModal: (state, { payload }: PayloadAction<IModal<IDeleteModal>>) => {
      state.deleteModal = payload;
    },
    setVisibilityDeleteModal: (state, { payload }: PayloadAction<boolean>) => {
      state.deleteModal.isOpen = payload;
    },
    setVisibilityCreateCallListModal: (state, { payload }: PayloadAction<boolean>) => {
      state.createCallList.isOpen = payload;
    },
    openFormListModal: (
      state,
      { payload }: PayloadAction<{ caseId: string; assessmentType: IAssessmentType }>,
    ) => {
      state.formListModal = { isOpen: true, data: payload };
    },
    closeFormListModal: (state) => {
      state.formListModal = modalReducerInitialState.formListModal;
    },
    setVisibilityFormPickerWarningModal: (state, { payload }: PayloadAction<boolean>) => {
      state.formPickerWarningModal.isOpen = payload;
    },
    openDownloadModal: (state, { payload }: PayloadAction<IDownloadModal>) => {
      state.downloadCallModal = {
        isOpen: true,
        data: payload,
      };
    },
    setVisibilitySaveAsDraftConfirmationModal: (state, { payload }: PayloadAction<boolean>) => {
      state.form.saveAsDraftConfirmation.isOpen = payload;
    },
    closeDownloadModal: (state) => {
      state.downloadCallModal = modalReducerInitialState.downloadCallModal;
    },
    setVisibilityAssessmentDisputeModal: (state, { payload }: PayloadAction<boolean>) => {
      state.assessmentModals.disputeModal.isOpen = payload;
    },
    setVisibilityAssessmentAcknowledgementModal: (state, { payload }: PayloadAction<boolean>) => {
      state.assessmentModals.acknowledgementModal.isOpen = payload;
    },
    setVisibilityAssessmentSuccessModal: (state, { payload }: PayloadAction<boolean>) => {
      state.assessmentModals.successModal.isOpen = payload;
    },
    setVisibilityAssessmentReportingModal: (state, { payload }: PayloadAction<boolean>) => {
      state.assessmentModals.reportingModal.isOpen = payload;
    },
    setVisibilityUserAuditReportingModal: (state, { payload }: PayloadAction<boolean>) => {
      state.userAuditModal.isOpen = payload;
    },
    openTelephonyLoginDeleteModal: (state, { payload }: PayloadAction<UUID>) => {
      state.telephonyLoginModals.deleteModal = {
        isOpen: true,
        data: payload,
      };
    },
    openAnnouncementEditModal: (state, { payload }: PayloadAction<IEditAnnouncementRequest>) => {
      state.announcementModals.editModal = {
        isOpen: true,
        data: payload,
      };
    },
    closeTelephonyLoginDeleteModal: (state) => {
      state.telephonyLoginModals.deleteModal =
        modalReducerInitialState.telephonyLoginModals.deleteModal;
    },
    setVisibilityTelephonyLoginCreateModal: (state, { payload }: PayloadAction<boolean>) => {
      state.telephonyLoginModals.createModal.isOpen = payload;
    },
    setVisibilityAnnouncementCreateModal: (state, { payload }: PayloadAction<boolean>) => {
      state.announcementModals.createModal.isOpen = payload;
    },
    setVisibilityUserTicketCreateModal: (state, { payload }: PayloadAction<boolean>) => {
      state.userTicketModals.createModal.isOpen = payload;
      state.userTicketModals.createModal.mode = 'create';
    },
    openUserTicketEditModal: (
      state,
      { payload }: PayloadAction<{ ticketId: UUID; initialData: TAddUserTicket }>,
    ) => {
      state.userTicketModals.createModal = { ...payload, isOpen: true, mode: 'edit' };
    },
    closeUserTicketEditModal: (state) => {
      state.userTicketModals.createModal.isOpen = false;
    },
    openUserTicketCommentEditModal: (state, { payload }: PayloadAction<IUserTicketComment>) => {
      state.userTicketModals.commentEditModal = { data: payload, isOpen: true };
    },
    closeUserTicketCommentEditModal: (state) => {
      state.userTicketModals.commentEditModal.isOpen = false;
    },
    setVisibilityUserTicketReportingModal: (state, { payload }: PayloadAction<boolean>) => {
      state.userTicketModals.reportModal.isOpen = payload;
    },
    setVisibilityAuditReportModal: (state, { payload }: PayloadAction<boolean>) => {
      state.auditReportModal.isOpen = payload;
    },
    openUserTicketReactionsModal: (state, { payload }: PayloadAction<UUID>) => {
      state.userTicketModals.reactionsModal.data = payload;
      state.userTicketModals.reactionsModal.isOpen = true;
    },
    closeUserTicketReactionsModal: (state) => {
      state.userTicketModals.reactionsModal.isOpen = false;
    },
    setVisibilityAssessmentTrackedTimeModal: (state, { payload }: PayloadAction<boolean>) => {
      state.assessmentModals.trackedTimeModal.isOpen = payload;
    },
    setVisibilityAnnouncementEditModal: (state, { payload }: PayloadAction<boolean>) => {
      state.announcementModals.editModal.isOpen = payload;
    },
    setVisibilityTelephonyLoginReportingModal: (state, { payload }: PayloadAction<boolean>) => {
      state.telephonyLoginModals.reportingModal.isOpen = payload;
    },
    openAssessmentCalibrationModal: (state, { payload }: PayloadAction<IAssessmentCalibration>) => {
      state.assessmentModals.calibrationModal = {
        isOpen: true,
        data: payload,
      };
    },
    openTelephonyLoginReimportModal: (state, { payload }: PayloadAction<IReimportModal>) => {
      state.telephonyLoginModals.reimportModal = {
        isOpen: true,
        data: payload,
      };
    },
    closeAssessmentCalibrationModal: (state) => {
      state.assessmentModals.calibrationModal =
        modalReducerInitialState.assessmentModals.calibrationModal;
    },
    closeTelephonyLoginReimportModal: (state) => {
      state.telephonyLoginModals.reimportModal =
        modalReducerInitialState.telephonyLoginModals.reimportModal;
    },
    setVisibilityImprovementAreaModal: (state, { payload }: PayloadAction<boolean>) => {
      state.improvementAreaModal.isOpen = payload;
    },
    setVisibilityInternalActivityModal: (state, { payload }: PayloadAction<boolean>) => {
      state.internalActivityModal.isOpen = payload;
    },
    openLocationModal: (state, { payload }: PayloadAction<ILocationModal>) => {
      state.locationModal = {
        isOpen: true,
        data: payload,
      };
    },
    closeLocationModal: (state) => {
      state.locationModal = {
        isOpen: false,
      };
    },
  },
});

export const modalActions = modalReducer.actions;

export default modalReducer.reducer;
