import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storageUtils from '../../utils/storageUtils';
import { PagedSearch } from '../../components/shared/interfaces';
import { ICampaignPreviewData } from '../../providers/ICampaignPreviewProvider';
import { IFormList } from '../../interfaces/form';
import { ITranscriptAnalysisSettingsView } from '../../interfaces/campaign';
import { IAnnouncement } from '../../interfaces/announcement';
import { Role } from '../../providers/IPopulatorProvider';

interface ICampaignSettings {
  transcriptAnalysis?: ITranscriptAnalysisSettingsView;
}

export const campaignReducerInitialState = {
  currentCampaignId: storageUtils.getLocalStorageValue('campaignId'),
  currentCampaignName: storageUtils.getLocalStorageValue('campaignName'),
  currentCampaignReference: storageUtils.getLocalStorageValue('campaignReference'),
  campaignPreviews: {
    elements: [],
    totalNumberOfPages: 0,
    pageNumber: 0,
  } as PagedSearch<ICampaignPreviewData>,
  formList: [] as IFormList[],
  settings: {} as ICampaignSettings,
  announcements: [] as IAnnouncement[],
  announcementsRefreshFlag: false,
  assessorAnonymizedForRoles: [] as Role[],
};

const campaignReducer = createSlice({
  name: 'campaignReducer',
  initialState: campaignReducerInitialState,
  reducers: {
    setCurrentCampaign: (
      state,
      { payload }: PayloadAction<{ id: string; name: string; reference: string } | null>,
    ) => {
      state.currentCampaignId = payload?.id || '';
      state.currentCampaignName = payload?.name || '';
      state.currentCampaignReference = payload?.reference || '';
    },
    setCampaignPreviews: (state, { payload }: PayloadAction<PagedSearch<ICampaignPreviewData>>) => {
      state.campaignPreviews = payload;
    },
    setFormList: (state, { payload }: PayloadAction<IFormList[]>) => {
      state.formList = payload;
    },
    setTranscriptAnalysisSettings: (
      state,
      { payload }: PayloadAction<ITranscriptAnalysisSettingsView | undefined>,
    ) => {
      state.settings.transcriptAnalysis = payload;
    },
    setAnnouncements: (state, { payload }: PayloadAction<IAnnouncement[]>) => {
      state.announcements = payload;
    },
  },
});

export const campaignActions = campaignReducer.actions;

export default campaignReducer.reducer;
