import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CallImportErrorTypeValues,
  ICallImportError,
  ICallImportErrorReducer,
  ICallImportErrorType,
} from '../../components/callErrors/IMainSearch';

const initialState = { values: null, page: 1, totalPages: 0 };
export const callImportErrorReducerInitialState: ICallImportErrorReducer = {
  importErrorsByType: Object.keys(CallImportErrorTypeValues).reduce(
    (acc, key) => ({
      ...acc,
      [key]: initialState,
    }),
    {} as ICallImportErrorReducer['importErrorsByType'],
  ),
};

const callImportErrorsReducer = createSlice({
  name: 'importCallErrorsReducer',
  initialState: callImportErrorReducerInitialState,
  reducers: {
    setCallImportErrors: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: ICallImportErrorType;
        values: ICallImportError[];
        page: number;
        totalPages: number;
      }>,
    ) => {
      state.importErrorsByType[payload.type] = {
        values: payload.values,
        page: payload.page,
        totalPages: payload.totalPages,
      };
    },
    reset: () => callImportErrorReducerInitialState,
  },
});

export const callImportErrorsActions = callImportErrorsReducer.actions;

export default callImportErrorsReducer.reducer;
