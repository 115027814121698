import React, { FC } from 'react';
import useCampaignId from './hooks/campaign/useCampaignId';

import MainRoutes from './routes/MainRoutes';
import LoginRoutes from './routes/loginRoutes';

import Header from './components/header/Header';
import SideNav from './components/sideNav/SideNav';
import TriggerDownloadReportModal from './components/assessments/report/TriggerDownloadReportModal';
import PreAuthHeader from './components/header/PreAuthHeader';
import AnnouncementAcknowledgeModals from './components/announcement/AnnouncementAcknowledgeModals';

import { PopulatorProvider } from './providers/PopulatorProvider';
import storageUtils from './utils/storageUtils';
import PreAuthorizedRoutes from './routes/PreAuthorizedRoutes';
import { useLoggedInState } from './hooks/shared/UseLoggedInState';

import style from './App.module.scss';

const App: FC = () => {
  const loggedIn = useLoggedInState();
  const [campaignId] = useCampaignId();
  const localStorageValue = storageUtils.getLocalStorageValue('campaignId');
  const hasNoSelectedCampaign = !localStorageValue && !campaignId && loggedIn;

  if (!loggedIn) return <LoginRoutes />;

  if (hasNoSelectedCampaign)
    return (
      <>
        <PreAuthHeader />
        <div className={style.mainWithDefaultAccess} id="main">
          <PreAuthorizedRoutes />
        </div>
      </>
    );

  return (
    <div className={style.wrapper}>
      <AnnouncementAcknowledgeModals />
      <div className={style.columnLeft}>
        <SideNav />
      </div>
      <div className={style.columnRight}>
        <Header />
        <div className={style.main} id="main">
          <PopulatorProvider>
            <MainRoutes />
          </PopulatorProvider>
        </div>
        <TriggerDownloadReportModal token="formDashboardExportToken" />
        <TriggerDownloadReportModal token="assessmentExportToken" />
        <TriggerDownloadReportModal token="campaignDashboardExportToken" />
        <TriggerDownloadReportModal token="calibrationDashboardExportToken" />
        <TriggerDownloadReportModal token="telephonyLoginExportToken" />
        <TriggerDownloadReportModal token="userAuditExportToken" />
        <TriggerDownloadReportModal token="auditReportExportToken" />
        <TriggerDownloadReportModal token="userTicketExportToken" />
      </div>
    </div>
  );
};

export default App;
