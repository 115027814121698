import { RecursiveAllValues } from '../interfaces';
import { DataPermissionType, Role } from '../providers/IPopulatorProvider';
import { IUserInfo } from '../interfaces/authentication';
import { ASSESSMENTS_WITH_NO_SCORE } from '../interfaces/assessment';

export type TRoutePath = RecursiveAllValues<typeof ROUTE_PATHS>;

export const ROUTE_PATHS = {
  util: {
    changeCampaign: '/change-campaign/:campaignId?',
    loginTest: '/loginTest',
    login: '/login',
    loggedOutB2C: '/loggedout-b2c',
    collectiveDashboard: '/collective-dashboard',
  },
  campaignManagement: {
    transcriptAnalysisSettings: '/campaign-management/:campaignId/transcript-analysis',
    vdnConnection: '/campaign-management/:campaignId/vdn-connection',
    userAllocations: '/campaign-management/:campaignId/user-allocations',
    roleMapping: '/campaign-management/:campaignId/role-mapping',
    details: '/campaign-management/:campaignId',
    list: '/campaign-management',
    locations: '/campaign-management/locations',
  },
  campaignAssessmentConfiguration: {
    index: '/campaign-assessment-configuration/:subMenu',
    improvementArea: '/campaign-assessment-configuration/improvement-area',
    internalActivities: '/campaign-assessment-configuration/internal-activities',
    anonymization: '/campaign-assessment-configuration/anonymization-settings',
    mailNotification: '/campaign-assessment-configuration/mail-notification',
  },
  calibration: {
    edit: '/calibration/:calibrationId/edit',
    submit: '/calibration/:calibrationId/submit',
    view: '/calibration/:calibrationId/view',
    viewSession: '/calibration/:calibrationId/view-session',
    initiate: '/calibration/initiate',
    fromCall: '/calibration/from-call',
    withoutCall: '/calibration/without-call',
    list: '/calibration',
    fromAssessment: '/calibration/from-assessment',
    addCalibrators: '/calibration/add-calibrators',
  },
  forms: {
    list: '/assessment-forms',
    view: '/assessment-forms/:formId',
  },
  assessments: {
    list: '/assessments',
    createAssessment: '/assessments/questionnaire',
    viewAssessment: '/assessments/questionnaire/:assessmentId',
    questionnaireDispute: '/assessments/questionnaireDispute/:assessmentId',
  },
  ownAssessments: {
    list: '/own-assessments',
    viewAssessment: '/own-assessments/questionnaire/:assessmentId',
  },
  calls: {
    list: '/search-calls',
    unrestrictedList: '/calls/unrestricted-search',
    details: '/calls/:callId/details',
  },
  callErrors: {
    list: '/call-import-errors',
    missingVdn: '/call-import-errors/missing-vdn',
    missingLoginCode: '/call-import-errors/missing-login-code',
    vdnNotConfigured: '/call-import-errors/vdn-not-configured',
    missingAllocations: '/call-import-errors/missing-allocations',
    vdnMultipleCampaigns: '/call-import-errors/vdn-multiple-campaigns',
    anyError: '/call-import-errors/any-error',
  },
  auditReport: {
    list: '/audit-report',
    userAllocations: '/audit-report/user-allocations',
    vdnConnection: '/audit-report/vdn-connection',
    defaultRoleMapping: '/audit-report/default-role-mapping',
  },
  dashboard: {
    /**
     * Used for e2e tests only
     */
    base: '/dashboard',
    calibration: '/dashboard/calibration-dashboard',
    transcriptAnalysis: '/dashboard/transcript-analysis',
    campaign: '/dashboard/campaign-dashboard',
    form: '/dashboard/form-dashboard',
  },
  telephonyLogin: {
    list: '/telephony-login',
  },
  userManagement: {
    list: '/user-management',
    user: '/user-management/:userId',
  },
  announcement: {
    list: '/announcements',
  },
  tickets: {
    list: '/ideas',
  },
  assessmentPlans: '/assessment-plans',
} as const;

const DEFAULT_ROLE_HOMEPAGE: Record<Role, TRoutePath> = {
  ADMIN: ROUTE_PATHS.dashboard.campaign,
  RESTRICTED: ROUTE_PATHS.dashboard.campaign,
  DEFAULT: ROUTE_PATHS.dashboard.campaign,
  QA: ROUTE_PATHS.dashboard.campaign,
  QA_MANAGER: ROUTE_PATHS.dashboard.campaign,
  SUPERVISOR: ROUTE_PATHS.dashboard.campaign,
  SUPER_ADMIN: ROUTE_PATHS.dashboard.campaign,
  TEAM_LEAD: ROUTE_PATHS.dashboard.campaign,
  AGENT: ROUTE_PATHS.dashboard.form,
};

export const getDefaultHomePageFor = (userInfo: IUserInfo | null) => {
  const role = userInfo?.role || Role.AGENT;
  const dataPermissions = userInfo?.permissions.dataPermissions;
  const assessmentTypePermissions = userInfo?.permissions.assessmentTypePermissions;
  const hasAtLeastOneScoringAssessmentType =
    !assessmentTypePermissions ||
    assessmentTypePermissions.some((type) => !ASSESSMENTS_WITH_NO_SCORE.includes(type));

  if (role !== Role.RESTRICTED || !dataPermissions) {
    return DEFAULT_ROLE_HOMEPAGE[role];
  }
  if (dataPermissions.includes('CALIBRATION')) {
    return ROUTE_PATHS.calibration.list;
  }
  if (
    (['REPORT', 'ASSESSMENT_READ'] as DataPermissionType[]).every((permission) =>
      dataPermissions.includes(permission),
    ) &&
    hasAtLeastOneScoringAssessmentType
  ) {
    return ROUTE_PATHS.dashboard.campaign;
  }
  if (dataPermissions.includes('ASSESSMENT_READ')) {
    return ROUTE_PATHS.assessments.list;
  }
  if (dataPermissions.includes('CALL')) {
    return ROUTE_PATHS.calls.list;
  }
  return ROUTE_PATHS.dashboard.campaign;
};
