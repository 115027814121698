import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IReactionType,
  ITicketStatusType,
  IUserTicketComment,
  IUserTicketListElement,
  IUserTicketReducer,
} from '../../interfaces/userTicket';
import { UUID } from '../../interfaces';

export const ticketReducerInitialState: IUserTicketReducer = {
  ticketsCountRefreshFlag: false,
  loadedTickets: [],
  loadedComments: [],
};

const ticketsReducer = createSlice({
  name: 'ticketReducer',
  initialState: ticketReducerInitialState,
  reducers: {
    reloadPendingTicketsCount: (state) => {
      state.ticketsCountRefreshFlag = !state.ticketsCountRefreshFlag;
    },
    setLoadedTickets: (state, { payload }: PayloadAction<IUserTicketListElement[]>) => {
      state.loadedTickets = payload;
    },
    reactOnTicket: (state, { payload }: PayloadAction<{ id: UUID; reaction: IReactionType }>) => {
      const ticket = state.loadedTickets.find((t) => t.id === payload.id);
      if (!ticket) {
        return state;
      }
      const previousReaction = ticket.ownReaction;
      const isRemovingReaction = previousReaction && previousReaction === payload.reaction;
      ticket.ownReaction = isRemovingReaction ? undefined : payload.reaction;
      if (previousReaction) {
        ticket.countByReaction[previousReaction] =
          (ticket.countByReaction[previousReaction] || 0) - 1;
      }
      if (!isRemovingReaction) {
        ticket.countByReaction[payload.reaction] =
          (ticket.countByReaction[payload.reaction] || 0) + 1;
      }
    },
    changeTicketStatus: (
      state,
      { payload }: PayloadAction<{ id: UUID; status: ITicketStatusType }>,
    ) => {
      const ticket = state.loadedTickets.find((t) => t.id === payload.id);
      if (!ticket) {
        return;
      }
      ticket.status = payload.status;
    },
    changeTicketBookmark: (
      state,
      { payload }: PayloadAction<{ id: UUID; bookmarked: boolean }>,
    ) => {
      const ticket = state.loadedTickets.find((t) => t.id === payload.id);

      if (!ticket) {
        return;
      }

      ticket.bookmarked = payload.bookmarked;
    },
    changeTicketInternalDevelopment: (
      state,
      { payload }: PayloadAction<{ id: UUID; internalDevelopment: boolean }>,
    ) => {
      const ticket = state.loadedTickets.find((t) => t.id === payload.id);

      if (!ticket) {
        return;
      }

      ticket.internalDevelopment = payload.internalDevelopment;
    },
    setLoadedComments: (state, { payload }: PayloadAction<IUserTicketComment[]>) => {
      state.loadedComments = payload;
    },
    changeCommentVisibility: (state, { payload }: PayloadAction<{ id: UUID; hidden: boolean }>) => {
      const comment = state.loadedComments.find((comment) => comment.id === payload.id);

      if (!comment) {
        return;
      }

      comment.hidden = payload.hidden;
    },
  },
});

export const ticketActions = ticketsReducer.actions;

export default ticketsReducer.reducer;
