import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '../shared/modal/Modal';
import Button from '../shared/button/Button';
import storageUtils from '../../utils/storageUtils';
import { buttonVariant } from '../shared/button/constants';
import { campaignSelectors } from '../../store/selectors';

import style from './AnnouncementAcknowledgeModals.module.scss';

const AnnouncementAcknowledgeModals = () => {
  const [acknowledgedAnnouncements, setAcknowledgedAnnouncements] = useState<string[]>(
    storageUtils.getLocalStorageValue('acknowledgedAnnouncements', [], 'object'),
  );
  const announcements = useSelector(campaignSelectors.getCurrentRegionAnnouncements);
  const { t } = useTranslation();

  const unacknowledgedAnnouncements = useMemo(
    () =>
      announcements
        .filter((announcement) => announcement.showPopup)
        .filter((announcement) => !acknowledgedAnnouncements.includes(announcement.id)),
    [announcements, acknowledgedAnnouncements],
  );

  if (!unacknowledgedAnnouncements?.length) {
    return null;
  }

  return (
    <Modal
      key={unacknowledgedAnnouncements[0].id}
      show={!acknowledgedAnnouncements.includes(unacknowledgedAnnouncements[0].id)}
      type="primary"
      className={style.announcement}>
      <h3 className={style.title}>{t('announcement.modal.title')}</h3>
      <div className={`${style.messageWrapper} smallScroll`}>
        <p className={style.message}>{unacknowledgedAnnouncements[0].value}</p>
      </div>
      <Button
        id={'close-announcement-modal'}
        onClick={() => {
          storageUtils.setLocalStorageValue('acknowledgedAnnouncements', [
            ...acknowledgedAnnouncements,
            unacknowledgedAnnouncements[0].id,
          ]);
          setAcknowledgedAnnouncements([
            ...acknowledgedAnnouncements,
            unacknowledgedAnnouncements[0].id,
          ]);
        }}
        variant={buttonVariant.primary}>
        {t('button.close')}
      </Button>
    </Modal>
  );
};

export default AnnouncementAcknowledgeModals;
