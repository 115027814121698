import useAuth from '../useAuth';
import { useEffect } from 'react';
import i18n from '../../i18n';
import { APP_CONFIG } from '../../constants/appConfig';
import storageUtils from '../../utils/storageUtils';

export function useLoggedInState() {
  const { loggedIn } = useAuth().authInfo;
  useEffect(() => {
    const language = localStorage.getItem('language');
    language && i18n.changeLanguage(language);
  }, [loggedIn]);

  if (APP_CONFIG.IS_B2C_LOGIN_ENABLED) {
    return loggedIn && !!storageUtils.getLocalStorageValue('b2cToken');
  }

  return loggedIn;
}
