import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../../providers/IPopulatorProvider';

export const userReducerInitialState = {
  roles: [] as Role[],
  workTrackerId: undefined as string | undefined,
};

const userReducer = createSlice({
  name: 'userReducer',
  initialState: userReducerInitialState,
  reducers: {
    setUserRoles: (state, { payload }: PayloadAction<Role[]>) => {
      state.roles = payload;
    },
    setUserWorkTracker: (state, { payload }: PayloadAction<string | undefined>) => {
      state.workTrackerId = payload;
    },
  },
});

export const userActions = userReducer.actions;

export default userReducer.reducer;
