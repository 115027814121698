import {
  Configuration,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import {
  AUTHORITY,
  AUTHORITY_DOMAIN,
  CLIENT_ID,
  LOGIN_PROMPT,
  LOGIN_REDIRECT_URI,
  OPENID_SCOPE,
  SCOPES,
} from '../../constants/authentication';

const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID, //This is your client ID
    authority: AUTHORITY, //This is your tenant info
    redirectUri: LOGIN_REDIRECT_URI,
    knownAuthorities: AUTHORITY_DOMAIN ? [AUTHORITY_DOMAIN] : [],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

// in order to login directly on a single User journey a hint must be added to the request, should be documented
export const loginRequest: RedirectRequest = {
  scopes: [OPENID_SCOPE],
  prompt: LOGIN_PROMPT,
};

export const tokenRequest: SilentRequest = {
  scopes: SCOPES || [],
};

export const msalInstance = new PublicClientApplication(msalConfig);
