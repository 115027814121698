import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';
import {
  AssessmentDateType,
  IAgentPerformance,
  IAssessmentPerformanceReport,
  ICalibrationReportResponse,
  ICalibrationSectionsPerformanceReport,
  IDashboardAveragePerformanceTrendTimeline,
  IDashboardFilters,
  IDashboardReducer,
  IDashboardRequest,
  IDashboardScoringTimeline,
  IDashboardStatusTimeline,
  IDashboardSummary,
  IDashboardTimeline,
  IDashboardUnacceptableTypeTimeline,
  IDashboardUserMinScorePerformanceTimeline,
  IDashboardUserPerformanceTimeline,
  IImprovementAreaPerformanceReport,
  IImprovementAreaSummary,
  IQuestionsReport,
  IQuestionSummary,
  IScoringComparison,
  ISectionQuestionSummary,
  ISectionResultsSummary,
  ITeamLeadPerformance,
  ITeamQuestionsPerformanceTrendMeta,
  ITeamSectionsPerformanceTrendMeta,
  IThemeDefinition,
  IThemeLabelCountReport,
  ITopicCountReport,
  ITopicTimeline,
  ITranscriptAnalysisMetricsDistribution,
  ITranscriptAnalysisSummaryMetrics,
  TAdditionalTranscriptFilter,
  TAssessmentPerformancePayload,
  TCalibrationScoreDifferencePayload,
  TDashboardReportCalls,
  TDashboardReportCallsWithThemes,
  TMetricsTimeLineReport,
  TQuestionIdAnswerIdPair,
  TranscriptAnalysisMetric,
  TThemeLabelTrendReport,
  TThemeLabelType,
  TUserMetricsReport,
} from '../../components/dashboard/interfaces/IDashboard';
import { onlyDate, parseDate } from '../../components/shared/helpers/dateHelpers';
import { IKeyObject, ISelectOption, IValueId } from '../../components/shared/helpers/IHelpers';
import { UUID } from '../../interfaces';
import { ISearchCallsTopic } from '../../interfaces/call';
import { formatDate } from '../../utils/dateUtils';
import storageUtils from '../../utils/storageUtils';
import { campaignActions } from './campaignReducer';
import { AGGREGATION_GRANULARITY } from '../../components/dashboard/helpers/constants';
import { TThemeId } from '../../interfaces/general';

function getCampaignIdsFromLocalStorage() {
  return storageUtils.getLocalStorageValue('campaignId')
    ? [storageUtils.getLocalStorageValue('campaignId')]
    : [];
}

export const dashboardReducerInitialState: IDashboardReducer = {
  filters: {
    campaignIds: getCampaignIdsFromLocalStorage(),
    startDate: onlyDate(parseDate(subMonths(new Date(), 1))),
    endDate: onlyDate(new Date().toISOString()),
    assessmentDateType: AssessmentDateType.CREATION_DATE,
    searchCounter: false,
  },
  filtersChanged: {
    campaignDashboard: true,
    formDashboard: true,
    calibrationDashboard: true,
    transcriptAnalysis: true,
  },
  additionalQuestionFilter: [],
  conditionalAdditionalQuestionAnswerPairFilter: [],
  additionalTranscriptFilters: [],
  sectionResults: [],
  sectionQuestionSummary: {},
  userPerformanceTimeline: {
    teamAssessmentPerformanceTimeline: {
      data: {},
      granularity: AGGREGATION_GRANULARITY.WEEK,
    },
    teamMinScoreAssessmentPerformanceTimeline: {
      data: {},
      granularity: AGGREGATION_GRANULARITY.WEEK,
    },
    teamSectionsPerformanceTimeline: {
      data: {},
      granularity: AGGREGATION_GRANULARITY.WEEK,
    },
    teamQuestionsPerformanceTimeline: {
      data: {},
      granularity: AGGREGATION_GRANULARITY.WEEK,
    },
  },
  assessmentForm: {
    sectionsPerformance: {
      data: {
        passFailSections: [],
        percentageSections: [],
        percentageSectionNames: {},
        passFailSectionNames: {},
      },
    },
    questionsPerformance: {
      data: {
        percentageQuestions: [],
        passFailQuestions: [],
        passFailQuestionsMeta: {},
        percentageQuestionsMeta: {},
      },
    },
  },
  calibration: {
    sectionsPerformance: {
      data: {
        passFailSections: [],
        percentageSections: [],
        percentageSectionNames: {},
        passFailSectionNames: {},
      },
    },
    questionsPerformance: {
      data: {
        percentageQuestions: [],
        passFailQuestions: [],
        passFailQuestionsMeta: {},
        percentageQuestionsMeta: {},
      },
    },
  },
  teamLeadsPerformance: [],
  teamLeadsWithoutAssessments: [],
  additionalQuestions: [],
  conditionalQuestions: {},
  presentationFilters: {
    reportPeriod: {
      startDate: formatDate(new Date(), 'dd.MM.yyyy'),
      endDate: formatDate(new Date(), 'dd.MM.yyyy'),
    },
    campaigns: [],
    countries: [],
    sites: [],
    activities: [],
    transcriptAnalysisMetric: TranscriptAnalysisMetric.AGENT,
  },
  transcriptAnalysis: {
    calls: {},
    callsWithThemes: {},
    topicCount: [],
    themeLabelsCount: [],
    themeLabelsTrend: { data: {} },
    negativityDistribution: [],
    paceDistribution: [],
    silenceDistribution: [],
    overtalkDistribution: [],
    metricsTimeline: { data: [] },
    teamMetrics: [],
    agentNames: {},
    agentsMetrics: {},
    topicsTimeline: {
      data: [],
    },
  },
  themeData: {
    hideNAThemeLabels: false,
    themeFilters: [],
    campaignThemes: [],
    visibleThemesConfig: {
      isLoading: true,
      themes: [],
    },
    themeSort: {
      field: 'name',
    },
  },
  improvementAreaPerformance: {
    data: {
      improvementAreaData: [],
      improvementAreaNames: {},
    },
  },
};

type TAdditionalTranscriptFilterIndexed = TAdditionalTranscriptFilter & { index?: number };

const dashboardReducer = createSlice({
  name: 'dashboardReducer',
  initialState: dashboardReducerInitialState,
  reducers: {
    setFilters: (state, { payload }: PayloadAction<IDashboardRequest>) => {
      const newSearchCounter = !state.filters.searchCounter;
      state.filters = payload;
      state.filters.searchCounter = newSearchCounter;
      state.additionalQuestionFilter = [];
      state.conditionalAdditionalQuestionAnswerPairFilter = [];
      state.filtersChanged = {
        campaignDashboard: true,
        formDashboard: true,
        calibrationDashboard: true,
        transcriptAnalysis: true,
      };
      state.additionalTranscriptFilters = dashboardReducerInitialState.additionalTranscriptFilters;
      state.themeData = {
        ...dashboardReducerInitialState.themeData,
        hideNAThemeLabels: state.themeData.hideNAThemeLabels,
        campaignThemes: state.themeData.campaignThemes,
      };
      state.transcriptAnalysis = dashboardReducerInitialState.transcriptAnalysis;
      state.userPerformanceTimeline = dashboardReducerInitialState.userPerformanceTimeline;
    },
    unmarkCampaignDashboardFlag: (state) => {
      state.filtersChanged.campaignDashboard = false;
    },
    unmarkCalibrationDashboardFlag: (state) => {
      state.filtersChanged.calibrationDashboard = false;
    },
    unmarkFormDashboardFlag: (state) => {
      state.filtersChanged.formDashboard = false;
    },
    unmarkTranscriptAnalysisDashboardFlag: (state) => {
      state.filtersChanged.transcriptAnalysis = false;
    },
    setGeneralData: (state, { payload }: PayloadAction<IDashboardSummary>) => {
      state.generalData = payload;
    },
    setImprovementAreaSummary: (state, { payload }: PayloadAction<IImprovementAreaSummary[]>) => {
      state.improvementAreaSummary = payload;
    },
    setScoringTimeline: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<IDashboardScoringTimeline[]>>,
    ) => {
      state.scoringTimeline = payload;
    },
    setTeamAssessmentsPerformanceTimeline: (
      state,
      {
        payload,
      }: PayloadAction<
        IDashboardTimeline<
          Record<string, Record<string, IDashboardUserPerformanceTimeline>>,
          IValueId[]
        >
      >,
    ) => {
      state.userPerformanceTimeline.teamAssessmentPerformanceTimeline = payload;
    },
    setTeamMinScoreAssessmentsPerformanceTimeline: (
      state,
      {
        payload,
      }: PayloadAction<
        IDashboardTimeline<
          Record<string, Record<string, IDashboardUserMinScorePerformanceTimeline>>,
          IValueId[]
        >
      >,
    ) => {
      state.userPerformanceTimeline.teamMinScoreAssessmentPerformanceTimeline = payload;
    },
    setTeamSectionsPerformanceTimeline: (
      state,
      {
        payload,
      }: PayloadAction<
        IDashboardTimeline<
          Record<string, Record<string, Record<string, IDashboardAveragePerformanceTrendTimeline>>>,
          ITeamSectionsPerformanceTrendMeta
        >
      >,
    ) => {
      state.userPerformanceTimeline.teamSectionsPerformanceTimeline = payload;
    },

    setTeamQuestionsPerformanceTimeline: (
      state,
      {
        payload,
      }: PayloadAction<
        IDashboardTimeline<
          Record<string, Record<string, Record<string, IDashboardAveragePerformanceTrendTimeline>>>,
          ITeamQuestionsPerformanceTrendMeta
        >
      >,
    ) => {
      state.userPerformanceTimeline.teamQuestionsPerformanceTimeline = payload;
    },
    setUnacceptableTypeTimeline: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<IDashboardUnacceptableTypeTimeline[]>>,
    ) => {
      state.unacceptableTypeTimeline = payload;
    },
    setStatusTimeline: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<IDashboardStatusTimeline[]>>,
    ) => {
      state.statusTimeline = payload;
    },
    setSectionResults: (state, { payload }: PayloadAction<ISectionResultsSummary[]>) => {
      state.sectionResults = payload;
    },
    setSectionQuestionsSummary: (
      state,
      { payload }: PayloadAction<IKeyObject<ISectionQuestionSummary>>,
    ) => {
      state.sectionQuestionSummary = payload;
    },
    setSectionsPerformance: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<IAssessmentPerformanceReport>>,
    ) => {
      state.assessmentForm.sectionsPerformance = payload;
    },
    setTopicCount: (state, { payload }: PayloadAction<ITopicCountReport[]>) => {
      state.transcriptAnalysis.topicCount = payload;
    },
    setThemeLabelsCount: (state, { payload }: PayloadAction<IThemeLabelCountReport[]>) => {
      state.transcriptAnalysis.themeLabelsCount = payload;
    },
    setCampaignThemes: (state, { payload }: PayloadAction<IThemeDefinition[]>) => {
      state.themeData.campaignThemes = payload;
    },
    setThemeSort: (state, { payload }: PayloadAction<keyof IThemeLabelCountReport>) => {
      state.themeData.themeSort.field = payload;
    },
    setVisibleThemesConfig: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.themeData.visibleThemesConfig = { themes: payload, isLoading: payload.length === 0 };
    },
    setHideNAThemeLabels: (state, { payload }: PayloadAction<boolean>) => {
      state.themeData.hideNAThemeLabels = payload;
    },
    setNegativityDistribution: (
      state,
      { payload }: PayloadAction<ITranscriptAnalysisMetricsDistribution[]>,
    ) => {
      state.transcriptAnalysis.negativityDistribution = payload;
    },
    setPaceDistribution: (
      state,
      { payload }: PayloadAction<ITranscriptAnalysisMetricsDistribution[]>,
    ) => {
      state.transcriptAnalysis.paceDistribution = payload;
    },
    setSummaryMetrics: (
      state,
      { payload }: PayloadAction<ITranscriptAnalysisSummaryMetrics | undefined>,
    ) => {
      state.transcriptAnalysis.summaryMetrics = payload;
    },
    setSilenceDistribution: (
      state,
      { payload }: PayloadAction<ITranscriptAnalysisMetricsDistribution[]>,
    ) => {
      state.transcriptAnalysis.silenceDistribution = payload;
    },
    putAdditionalTranscriptFilters: (
      state,
      { payload: { index, ...payload } }: PayloadAction<TAdditionalTranscriptFilterIndexed>,
    ) => {
      let newFilters = state.additionalTranscriptFilters;
      if (index != undefined) {
        newFilters = state.additionalTranscriptFilters.slice(0, index);
      }
      state.additionalTranscriptFilters = [...newFilters, payload];
    },
    removeAdditionalTranscriptFilters: (state, { payload }: PayloadAction<number>) => {
      state.additionalTranscriptFilters = state.additionalTranscriptFilters.slice(0, payload);
    },
    toggleThemeFilter: (
      state,
      { payload: [themeId, value] }: PayloadAction<[string, TThemeLabelType]>,
    ) => {
      const indexOfExistingTheme = state.themeData.themeFilters.findIndex(
        (f) => f.themeId === themeId && f.value === value,
      );
      if (indexOfExistingTheme === -1) {
        state.themeData.themeFilters = [...state.themeData.themeFilters, { themeId, value }];
        return;
      }

      state.themeData.themeFilters = state.themeData.themeFilters
        .slice(0, indexOfExistingTheme)
        .concat(state.themeData.themeFilters.slice(indexOfExistingTheme + 1));
    },
    setOvertalkDistribution: (
      state,
      { payload }: PayloadAction<ITranscriptAnalysisMetricsDistribution[]>,
    ) => {
      state.transcriptAnalysis.overtalkDistribution = payload;
    },
    setAgentTopicsByIndex: (state, { payload }: PayloadAction<ISearchCallsTopic[][]>) => {
      state.transcriptAnalysis.agentTopicsByIndex = payload;
    },
    setAgentPerformance: (state, { payload }: PayloadAction<IAgentPerformance>) => {
      state.agentPerformance = payload;
    },
    setTeamLeadPerformance: (state, { payload }: PayloadAction<ITeamLeadPerformance[]>) => {
      state.teamLeadsPerformance = payload;
    },
    setTeamLeadsWithoutAssessments: (state, { payload }: PayloadAction<ITeamLeadPerformance[]>) => {
      state.teamLeadsWithoutAssessments = payload;
    },
    setScoringComparison: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<IScoringComparison[]>>,
    ) => {
      state.scoringComparison = payload;
    },
    setCalibrationsComparison: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<ICalibrationReportResponse[]>>,
    ) => {
      state.calibration.calibrationsComparison = payload;
    },
    setCalibrationSectionsPerformance: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<ICalibrationSectionsPerformanceReport>>,
    ) => {
      state.calibration.sectionsPerformance = payload;
    },
    setImprovementAreaPerformance: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<IImprovementAreaPerformanceReport>>,
    ) => {
      state.improvementAreaPerformance = payload;
    },
    setCalibrationQuestionReport: (
      state,
      {
        payload,
      }: PayloadAction<IDashboardTimeline<IQuestionsReport<TCalibrationScoreDifferencePayload>>>,
    ) => {
      state.calibration.questionsPerformance = payload;
    },
    setAssessmentFormQuestionReport: (
      state,
      {
        payload,
      }: PayloadAction<IDashboardTimeline<IQuestionsReport<TAssessmentPerformancePayload>>>,
    ) => {
      state.assessmentForm.questionsPerformance = payload;
    },
    setAdditionalQuestions: (state, { payload }: PayloadAction<IQuestionSummary[]>) => {
      state.additionalQuestions = payload;
    },
    addSelectedAdditionalAnswerIdFilter: (state, { payload }: PayloadAction<UUID>) => {
      state.additionalQuestionFilter = [...state.additionalQuestionFilter, payload];
    },
    addSelectedAdditionalAndConditionalAnswerFilter: (
      state,
      {
        payload: { additionalAnswerIds, conditionalAdditionalQuestionAnswerPairs },
      }: PayloadAction<{
        additionalAnswerIds?: UUID;
        conditionalAdditionalQuestionAnswerPairs: TQuestionIdAnswerIdPair;
      }>,
    ) => {
      if (additionalAnswerIds) {
        state.additionalQuestionFilter = [...state.additionalQuestionFilter, additionalAnswerIds];
      }
      state.conditionalAdditionalQuestionAnswerPairFilter = [
        ...state.conditionalAdditionalQuestionAnswerPairFilter,
        conditionalAdditionalQuestionAnswerPairs,
      ];
    },
    setTopicsTimeLine: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<ITopicTimeline[]>>,
    ) => {
      state.transcriptAnalysis.topicsTimeline = payload;
    },
    setMetricsTimeLine: (
      state,
      { payload }: PayloadAction<IDashboardTimeline<TMetricsTimeLineReport[]>>,
    ) => {
      state.transcriptAnalysis.metricsTimeline = payload;
    },
    setThemeLabelsTrend: (
      state,
      {
        payload,
      }: PayloadAction<
        IDashboardTimeline<
          Record<TThemeId, TThemeLabelTrendReport[]>,
          Record<TThemeId, IThemeDefinition>
        >
      >,
    ) => {
      state.transcriptAnalysis.themeLabelsTrend = payload;
    },
    setTeamMetrics: (state, { payload }: PayloadAction<TUserMetricsReport[]>) => {
      state.transcriptAnalysis.teamMetrics = payload;
    },
    setTeamAgentsNames: (state, { payload }: PayloadAction<IKeyObject>) => {
      state.transcriptAnalysis.agentNames = { ...state.transcriptAnalysis.agentNames, ...payload };
    },
    setReportCalls: (state, { payload }: PayloadAction<TDashboardReportCalls>) => {
      state.transcriptAnalysis.calls = payload;
    },
    setReportCallsWithThemes: (
      state,
      { payload }: PayloadAction<TDashboardReportCallsWithThemes>,
    ) => {
      state.transcriptAnalysis.callsWithThemes = payload;
    },
    resetTopicsTimeLine: (state) => {
      state.transcriptAnalysis.topicsTimeline =
        dashboardReducerInitialState.transcriptAnalysis.topicsTimeline;
    },
    removeSelectedAdditionalAnswerIdFilter: (
      state,
      {
        payload: { additionalAnswerId, conditionalQuestionId },
      }: PayloadAction<{
        additionalAnswerId: UUID;
        conditionalQuestionId?: UUID;
      }>,
    ) => {
      state.additionalQuestionFilter = state.additionalQuestionFilter.filter(
        (id) => id !== additionalAnswerId,
      );

      state.conditionalAdditionalQuestionAnswerPairFilter = state.conditionalAdditionalQuestionAnswerPairFilter.filter(
        ({ questionId }) => questionId !== conditionalQuestionId,
      );
    },
    removeSelectedConditionalAdditionalAnswerIdFilter: (
      state,
      { payload }: PayloadAction<UUID>,
    ) => {
      state.conditionalAdditionalQuestionAnswerPairFilter = state.conditionalAdditionalQuestionAnswerPairFilter.filter(
        ({ answerId }) => answerId !== payload,
      );
    },
    setConditionalQuestions: (
      state,
      { payload }: PayloadAction<{ questionId: UUID; data: IQuestionSummary[] }>,
    ) => {
      state.conditionalQuestions = {
        ...state.conditionalQuestions,
        [payload.questionId]: payload.data,
      };
    },
    setPresentationFilters: (
      state,
      { payload }: PayloadAction<IDashboardFilters<ISelectOption>>,
    ) => {
      state.presentationFilters = payload;
    },
    resetConditionalQuestions: (state) => {
      state.conditionalQuestions = {};
    },
    reset: () => ({
      ...dashboardReducerInitialState,
      filters: {
        ...dashboardReducerInitialState.filters,
        campaignIds: getCampaignIdsFromLocalStorage(),
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(campaignActions.setCurrentCampaign, (state, payload) => {
      if (payload.payload?.id) {
        state.filters.campaignIds = [payload.payload?.id];
      }
    });
  },
});

export const dashboardActions = dashboardReducer.actions;

export default dashboardReducer.reducer;
