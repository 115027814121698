import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTriggerDownloadReport } from '../../../hooks/report/UseTriggerDownloadReport';
import { TStorageDownloadToken } from '../../../interfaces/report';

import Button from '../../shared/button/Button';

import { buttonVariant } from '../../shared/button/constants';
import Modal from '../../shared/modal/Modal';

import style from './ReportModal.module.scss';

const TriggerDownloadReportModal = ({ token }: { token: TStorageDownloadToken }) => {
  const { t } = useTranslation();
  const { showDownloadReady, triggerDownload } = useTriggerDownloadReport(token);

  return (
    <Modal show={showDownloadReady} type="primary" className={style.report}>
      <h3 className={style.title}>{t('report.downloadReady')}</h3>
      <Button onClick={triggerDownload} variant={buttonVariant.primary}>
        {t('report.download')}
      </Button>
    </Modal>
  );
};

export default TriggerDownloadReportModal;
