import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { CSSTransition } from 'react-transition-group';

import LanguageSelector from '../languageSelector/LanguageSelector';
import AnnouncementLabel from '../announcement/AnnouncementLabel';
import AnnouncementDetailsModal from '../announcement/AnnouncementDetailsModal';
import Loader from '../shared/loader/Loader';
import useCampaignId from '../../hooks/campaign/useCampaignId';
import useShowCurrentCampaigns from '../../hooks/campaign/useShowCurrentCampaigns';
import useOutsideClick from '../../hooks/shared/useOutsideClick';
import useAuth from '../../hooks/useAuth';
import { formatRole } from '../shared/helpers/transformToResponse';
import { IAnnouncement } from '../../interfaces/announcement';

import style from './Header.module.scss';

const Header: FC = () => {
  const {
    authInfo: { userInfo },
    redirectToChangeCampaign,
    logout,
  } = useAuth();

  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [announcement, setAnnouncement] = useState<IAnnouncement>();
  const [showAnnouncementDetails, setShowAnnouncementDetails] = useState(false);
  const [currentCampaignId, , campaignName, campaignReference] = useCampaignId();

  const { campaignPreviews, isLoading, hasAllCampaigns } = useShowCurrentCampaigns();

  const handleDropdownState = (): void => {
    setShowDropdown(!showDropdown);
  };

  useOutsideClick(dropdownRef, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  return (
    <>
      {announcement && (
        <AnnouncementDetailsModal
          announcement={announcement}
          show={showAnnouncementDetails}
          onClose={() => setShowAnnouncementDetails(false)}
        />
      )}
      <div className={style.header}>
        <LanguageSelector />
        <AnnouncementLabel
          showDetails={showAnnouncementDetails}
          onShowDetails={(announcement) => {
            setShowAnnouncementDetails(true);
            setAnnouncement(announcement);
          }}
        />
        <div className={style.wrapper}>
          <label>{t('header.current')}</label>
          <p>
            {(campaignReference ? campaignReference + ' - ' : '') + campaignName || (
              <Loader size={10} />
            )}
          </p>
          <span className={style.role}>{userInfo?.role && formatRole(userInfo.role)}</span>
          <div className={style.name} onClick={handleDropdownState}>
            {userInfo && userInfo.firstName}
            {showDropdown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </div>
          {
            <CSSTransition in={showDropdown} timeout={300} unmountOnExit classNames={'dropdown'}>
              <ul className={style.dropDown} ref={dropdownRef}>
                {hasAllCampaigns || campaignPreviews.length >= 1 ? (
                  <>
                    <li onClick={() => redirectToChangeCampaign()} className={style.bold}>
                      {t('dropdown.campaigns')}
                    </li>
                    <Loader size={20} isLoading={isLoading}>
                      {campaignPreviews
                        .sort((a, b) => a.campaignName.localeCompare(b.campaignName))
                        .map(({ campaignId, campaignName, campaignReference }) => (
                          <li
                            key={campaignId}
                            id={campaignId}
                            onClick={() => {
                              redirectToChangeCampaign(campaignId, campaignName, campaignReference);
                              handleDropdownState();
                            }}
                            className={campaignId === currentCampaignId ? style.active : ''}>
                            {campaignName}
                          </li>
                        ))}
                    </Loader>
                  </>
                ) : null}
                <li onClick={logout} className={style.bold}>
                  {t('logout')}
                </li>
              </ul>
            </CSSTransition>
          }
        </div>
      </div>
    </>
  );
};

export default Header;
