import React, { FC, useMemo, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCountPendingCalibrationsCount } from '../../hooks/calibration/UseCountPendingCalibrationsCount';
import useCampaignId from '../../hooks/campaign/useCampaignId';
import useAuth from '../../hooks/useAuth';

import routePaths from '../../routes/routePaths';

import { Role } from '../../providers/IPopulatorProvider';
import { ROUTE_PATHS } from '../../routes/pathConstants';
import { APP_CONFIG } from '../../constants/appConfig';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useUnbalancedPlan } from '../../hooks/assessmentPlan/useUnbalancedPlan';
import { HiOutlineLightBulb } from 'react-icons/all';
import {
  ALL_ROLES,
  ALL_ROLES_WITHOUT_AGENT,
  ROLES_ALLOWED_TO_ACCESS_TICKETS,
  ROLES_ALLOWED_TO_CREATE_ASSESSMENT_PLANS,
} from '../../constants/security';
import { formatDate } from '../../utils/dateUtils';
import HomeLogo from '../shared/logo/HomeLogo';
import DividerWithText from './DividerWithText';
import useUserAssessmentTypePermissions from '../../hooks/useUserAssessmentTypePermissions';

import style from './SideNav.module.scss';

const SideNav: FC = () => {
  const { t } = useTranslation();
  const history = useHistory<any>();
  const setCampaignId = useCampaignId()[1];
  const location = useLocation();
  const [showCampaignManagement, setShowCampaignManagement] = useState(false);
  const {
    authInfo: { userInfo },
    hasRole,
    hasRoleOrPermission,
  } = useAuth();
  const { hasAtLeastOneScoringAssessmentType } = useUserAssessmentTypePermissions();

  const hasAccessToCampaign = hasRoleOrPermission(ALL_ROLES_WITHOUT_AGENT, [
    'REPORT',
    'ASSESSMENT_READ',
  ]);
  const hasAccessToForm = hasRoleOrPermission(ALL_ROLES, ['REPORT', 'ASSESSMENT_READ']);

  const hasAccessToCalibration = hasRoleOrPermission(ALL_ROLES, ['REPORT', 'CALIBRATION']);

  const pendingCalibrationsCount = useCountPendingCalibrationsCount();

  const { isLoading, isUnbalancedPlan } = useUnbalancedPlan(
    hasRole(ALL_ROLES_WITHOUT_AGENT) ? formatDate(new Date()) : '',
  );

  const redirectToDashboard = (): void => {
    setCampaignId(null);
    history.push(ROUTE_PATHS.dashboard.campaign);
  };

  const isAdminOrSuperAdmin = ([Role.SUPER_ADMIN, Role.ADMIN] as string[]).includes(
    userInfo?.role as string,
  );
  const isRestrictedRole = ([Role.RESTRICTED] as string[]).includes(userInfo?.role as string);

  const dashboardRoute = useMemo(() => {
    return hasAccessToCampaign && hasAtLeastOneScoringAssessmentType
      ? ROUTE_PATHS.dashboard.campaign
      : hasAccessToForm && hasAtLeastOneScoringAssessmentType
      ? ROUTE_PATHS.dashboard.form
      : ROUTE_PATHS.dashboard.calibration;
  }, [JSON.stringify(userInfo)]);

  const isCampaignsRoute = useMemo(() => {
    return (
      location.pathname.includes(ROUTE_PATHS.campaignManagement.list) &&
      !location.pathname.includes(ROUTE_PATHS.campaignManagement.locations)
    );
  }, [location]);

  const isLocationsRoute = useMemo(() => {
    return location.pathname.includes(ROUTE_PATHS.campaignManagement.locations);
  }, [location]);

  const isAuditReportRoute = useMemo(() => {
    return location.pathname.includes(ROUTE_PATHS.auditReport.list);
  }, [location]);

  const isAssessmentConfigurationRoute = useMemo(() => {
    return [
      ROUTE_PATHS.campaignAssessmentConfiguration.improvementArea,
      ROUTE_PATHS.campaignAssessmentConfiguration.internalActivities,
      ROUTE_PATHS.campaignAssessmentConfiguration.anonymization,
      ROUTE_PATHS.campaignAssessmentConfiguration.mailNotification,
    ].some((path) => location.pathname.includes(path));
  }, [location]);

  const isPreviousRouteCampaigns = useMemo(
    () => history.location?.state?.prevPath?.includes(ROUTE_PATHS.campaignManagement.list),
    [history.location.state],
  );

  const isPreviousRouteUserManagement = useMemo(
    () => history.location?.state?.prevPath?.includes(ROUTE_PATHS.userManagement.list),
    [history.location.state],
  );

  const shouldDashboardBeVisible =
    ((hasAccessToCampaign || hasAccessToForm) && hasAtLeastOneScoringAssessmentType) ||
    hasAccessToCalibration;

  return (
    <div className={style.sideNav}>
      <HomeLogo size="medium" onClick={redirectToDashboard} />
      {APP_CONFIG.APP_VERSION ? (
        <span className={style.version}>{APP_CONFIG.APP_VERSION}</span>
      ) : null}
      <div className={`${style.navWrapper} smallScroll`}>
        <div className={style.navUpperZoneWrapper}>
          {shouldDashboardBeVisible ? (
            <NavLink
              to={dashboardRoute}
              activeClassName={style.active}
              isActive={(_, { pathname }) => pathname.includes(routePaths.dashboard)}
              exact={false}>
              {t('navbar.dashboard')}
            </NavLink>
          ) : null}
          {hasRoleOrPermission(ALL_ROLES, 'CALL') ? (
            <NavLink to={ROUTE_PATHS.calls.list} activeClassName={style.active}>
              {t('navbar.searchCalls')}
            </NavLink>
          ) : null}
          {hasRoleOrPermission(ALL_ROLES, 'ASSESSMENT_READ') ? (
            <NavLink to={ROUTE_PATHS.assessments.list} activeClassName={style.active}>
              {t('navbar.assessments')}
            </NavLink>
          ) : null}
          {hasRole(ALL_ROLES_WITHOUT_AGENT) && (
            <NavLink to={ROUTE_PATHS.assessmentPlans} activeClassName={style.active}>
              {t('navbar.assessmentPlan')}
              {hasRole(ROLES_ALLOWED_TO_CREATE_ASSESSMENT_PLANS) &&
                !isLoading &&
                isUnbalancedPlan && <span className={style.failed}>!</span>}
            </NavLink>
          )}
          {userInfo && userInfo.role !== Role.AGENT && !isRestrictedRole && (
            <NavLink to={routePaths.assessmentForms} activeClassName={style.active}>
              {t('navbar.assessmentForms')}
            </NavLink>
          )}
          {hasRoleOrPermission(ALL_ROLES_WITHOUT_AGENT, 'CALIBRATION') ? (
            <NavLink to={routePaths.calibration} activeClassName={style.active}>
              {t('navbar.calibration')}
              {pendingCalibrationsCount !== 0 ? ` (${pendingCalibrationsCount})` : null}
            </NavLink>
          ) : null}
          {(isAdminOrSuperAdmin || hasRole(Role.QA_MANAGER)) && (
            <NavLink
              to={ROUTE_PATHS.campaignAssessmentConfiguration.improvementArea}
              isActive={() => isAssessmentConfigurationRoute}>
              {t('navbar.assessmentConfiguration')}
            </NavLink>
          )}
          {isAdminOrSuperAdmin && <DividerWithText text={t('user.admin')} />}
          {userInfo && userInfo.role === Role.SUPER_ADMIN && (
            <NavLink
              to={routePaths.userManagement}
              activeClassName={style.active}
              isActive={(_, { pathname }) =>
                isPreviousRouteUserManagement || pathname === ROUTE_PATHS.userManagement.list
              }>
              {t('navbar.userManagement')}
            </NavLink>
          )}
          {isAdminOrSuperAdmin && (
            <>
              {APP_CONFIG.IS_LOCATION_MANAGEMENT_ENABLED ? (
                <>
                  <div
                    className={`${style.campaignManagementDropdown} ${
                      showCampaignManagement && style.menuOpen
                    } ${
                      (isCampaignsRoute || isLocationsRoute || isPreviousRouteCampaigns) &&
                      style.dropdownActive
                    }`}
                    onClick={() => setShowCampaignManagement(!showCampaignManagement)}>
                    {t('navbar.campaignManagement')}
                    {showCampaignManagement ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                  </div>
                  {showCampaignManagement && (
                    <div className={style.menuWrapper}>
                      <NavLink
                        className={style.menuItem}
                        to={routePaths.campaigns}
                        isActive={() => isCampaignsRoute}
                        activeClassName={style.active}>
                        {t('navbar.campaign')}
                      </NavLink>
                      <NavLink
                        className={style.menuItem}
                        to={ROUTE_PATHS.campaignManagement.locations}
                        isActive={() => isLocationsRoute}
                        activeClassName={style.active}>
                        {t('navbar.location')}
                      </NavLink>
                    </div>
                  )}
                </>
              ) : (
                <NavLink to={routePaths.campaigns} activeClassName={style.active}>
                  {t('navbar.campaignManagement')}
                </NavLink>
              )}
            </>
          )}
          {(APP_CONFIG.IS_TELEPHONY_LOGIN_PAGE_SUPER_ADMIN_ONLY
            ? userInfo?.role === Role.SUPER_ADMIN
            : isAdminOrSuperAdmin) && (
            <NavLink to={ROUTE_PATHS.telephonyLogin.list} activeClassName={style.active}>
              {t('navbar.telephonyLogin')}
            </NavLink>
          )}
          {userInfo && userInfo.role === Role.SUPER_ADMIN && (
            <NavLink to={ROUTE_PATHS.announcement.list} activeClassName={style.active}>
              {t('navbar.announcement')}
            </NavLink>
          )}
          {isAdminOrSuperAdmin && (
            <NavLink
              to={ROUTE_PATHS.auditReport.userAllocations}
              isActive={() => isAuditReportRoute}
              activeClassName={style.active}>
              {t('navbar.auditReport')}
            </NavLink>
          )}
        </div>
        <div className={style.navLowerZoneWrapper}>
          {hasRole(ROLES_ALLOWED_TO_ACCESS_TICKETS) && (
            <NavLink
              to={ROUTE_PATHS.tickets.list}
              className={style.navTicket}
              activeClassName={style.active}>
              <HiOutlineLightBulb className={style.svg} />
              {t('navbar.userTicket')}
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
