import { IKeyObject } from './helpers/IHelpers';

export const PAGE_MAX_SIZE = 200;
export const CHART_MAX_MESSAGE_LENGTH = 50;
export const CHART_DEFAULT_OPACITY = 1;
export const CHART_SELECTED_OPACITY = 0.8;
export const CHART_DESELECTED_OPACITY = 0.2;
export const ASC = 'ASC';
export const DESC = 'DESC';
export const NA = 'N/A';

export const INPUT_TYPES = {
  RADIO_BUTTON: 'RADIO_BUTTON',
  CHECKBOX: 'CHECKBOX',
  SELECT: 'DROPDOWN',
};

export const DROPDOWN_VALUES = {
  ALL: 'dropdown.all',
  ALL_AGENTS: 'dropdown.agents',
  ALL_CHANNELS: 'dropdown.channels',
  ALL_COUNTRIES: 'dropdown.countries',
  ALL_BEHAVIORS: 'dropdown.behaviors',
  ALL_TYPES: 'dropdown.types',
  ALL_THEMES: 'dropdown.themes',
  ALL_IMPROVEMENT_AREAS: 'dropdown.improvementAreas',
  ALL_SECTIONS: 'dropdown.sections',
  ALL_LABELS: 'dropdown.labels',
  ALL_UNACCEPTABLE_TYPES: 'dropdown.unacceptableTypes',
  ALL_QUESTIONS: 'dropdown.questions',
  ALL_QA: 'dropdown.qa',
  ALL_SITES: 'dropdown.sites',
  ALL_TEAM_LEAD: 'dropdown.teamLead',
  NO: 'dropdown.no',
  NONE: 'dropdown.none',
  YES: 'dropdown.yes',
  ALL_VDNS: 'dropdown.vdn',
  ALL_CAMPAIGNS: 'dropdown.campaigns',
  ALL_STATUSES: 'dropdown.statuses',
  ALL_FORMS: 'dropdown.forms',
  ALL_ROLES: 'dropdown.roles',
  ALL_TELEPHONY_LOGINS: 'dropdown.telephonyLogins',
  ALL_USERS: 'dropdown.users',
  ALL_CALL_LISTS: 'dropdown.callLists',
  ALL_CALL_CLUSTERS: 'dropdown.callClusters',
  ALL_CALL_TOPICS: 'dropdown.callTopics',
  ALL_REGIONS: 'dropdown.regions',
  ALL_ORIGINS: 'dropdown.cloudBucketOrigins',
};

export const STATUS_TYPES = {
  IN_REVIEW: 'IN_REVIEW',
  DRAFT: 'DRAFT',
  DISPUTE_REQUESTED: 'DISPUTE_REQUESTED',
  DISPUTE_CLOSED: 'DISPUTE_CLOSED',
  DISPUTE_IN_PROGRESS: 'DISPUTE_IN_PROGRESS',
  DISPUTE_DRAFT: 'DISPUTE_DRAFT',
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED: 'SUBMITTED',
  FEEDBACK_REQUIRED: 'FEEDBACK_REQUIRED',
  DONE: 'DONE',
} as const;

export enum MailTemplateType {
  ASSESSMENT_NOTIFICATION = 'ASSESSMENT_NOTIFICATION',
  ASSESSMENT_SUMMARY = 'ASSESSMENT_SUMMARY',
  ASSESSMENT_RESULT_SUMMARY = 'ASSESSMENT_RESULT_SUMMARY',
}

export const ASSESSMENT_STATUS = {
  START: 'start',
  CANCEL: 'cancel',
  FINISH: 'finish',
};

export const ASSESSMENT_CHECK_TYPE = {
  DISPUTE: 'DISPUTE',
  CALIBRATION: 'CALIBRATION',
  SECOND_LINE_CHECK: 'SECOND_LINE_CHECK',
};

export const FIRST_PAGE_NUMBER = 1;
export const FIRST_PAGINATION_SIZE = 10;
export const SELECT_PAGINATION_SIZE = 20;
export const AGENT_FIRST_PAGINATION_SIZE = 5;
export const USER_MANAGEMENT_PAGINATION_SIZE = 50;
export const ASSESSMENT_PLAN_AGENTS_PAGINATION_SIZE = 200;
export const ASSESSMENT_PLAN_FETCH_SIZE = 100;

export const paginationSize = [
  { label: '10', value: FIRST_PAGINATION_SIZE },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
];

export const DEFAULT_VALUE_PERCENTAGE_IGNORE_SCORE = 0;

export const DEFAULT_SELECT_VALUE = {
  label: '',
  value: '',
};

export const DEFAULT_SORT_VALUE = {
  direction: '',
  property: '',
};

export const FIELD_NAME = {
  AGENT: 'agent',
  ASSESSOR_ROLES: 'assessorRoles',
  CASE_REFERENCE: 'caseReference',
  ASSESSMENT_TYPE: 'assessmentType',
  ASSESSMENT_TYPES: 'assessmentTypes',
  ASSESSMENT_TYPE_PERMISSIONS: 'assessmentTypePermissions',
  CHANNEL: 'channel',
  CHANNELS: 'channels',
  CHECK_TYPE: 'checkType',
  COUNTRY: 'country',
  COUNTRIES: 'countries',
  CUSTOMER_REFERENCE: 'customerReference',
  DATE_TIME: 'dateTime',
  DIRECTIONS: 'directions',
  DURATIONS: 'durations',
  OVERTALK_LIMIT: 'overtalkLimit',
  EMAIL: 'email',
  END_DATE: 'endDate',
  FEEDBACK: 'feedback',
  FORM: 'form',
  REGIONS: 'regions',
  CALL_ERROR_TYPE: 'callErrorType',
  REGION_PERMISSIONS: 'regionPermissions',
  INTERACTION_LANGUAGE: 'interactionLanguage',
  IS_EXTERNAL: 'isExternal',
  INCLUDE_ONLY_BOOKMARKED: 'includeOnlyBookmarked',
  INCLUDE_ONLY_INTERNAL_DEVELOPMENT: 'includeOnlyInternalDevelopment',
  INTERNAL_DEVELOPMENT: 'internalDevelopment',
  HAS_RESTRICTED_ACCESS: 'hasRestrictedAccess',
  ACTIVITY: 'activity',
  ACTIVITIES: 'activities',
  QA: 'qa',
  SITE: 'site',
  SITES: 'sites',
  START_DATE: 'startDate',
  TEAM_LEAD: 'teamLead',
  CALLER_ID: 'callerId',
  EXTERNAL_CALL_ID: 'externalCallId',
  TRANSFERRED_CALL: 'transferredCall',
  VDNS: 'vdns',
  THEMES: 'themes',
  VDN_NAME_PREFIX: 'vdnNamePrefix',
  FORM_NAME: 'formName',
  CAMPAIGN: 'campaign',
  CAMPAIGNS: 'campaigns',
  STATUS: 'status',
  STATUSES: 'statuses',
  CREATION_START_DATE: 'creationDateLowerBound',
  CREATION_END_DATE: 'creationDateUpperBound',
  CAMPAIGN_ID: 'campaignId',
  CAMPAIGN_NAME: 'campaignName',
  CALL_LIST_NAME: 'callListName',
  CALL_LIST_SIZE: 'callListSize',
  CALL_LIST_SELECT_ALL: 'callListSelectAll',
  CAMPAIGN_REFERENCE: 'campaignReference',
  VDN_CONNECTION: 'vdnConnections',
  MANUALLY_CONFIGURED: 'manuallyConfigured',
  ROLE: 'role',
  ROLES: 'roles',
  USER: 'user',
  USERS: 'users',
  TELEPHONY_LOGIN: 'telephonyLogin',
  UNACCEPTABLE_TYPES: 'unacceptableTypes',
  USER_NAME_OR_REFERENCE: 'nameOrReference',
  HAS_ADMIN_PERMISSION: 'hasAdminPermission',
  IMPROVEMENT_FEEDBACK: 'improvementFeedback',
  SESSION_TYPE: 'sessionType',
  IMPROVEMENT_AREA: 'improvementArea',
  SUPERVISOR: 'supervisor',
  INCLUDE_QUESTION_SCORES: 'includeQuestionScores',
  ANONYMIZE_USER_DATA: 'anonymizeUserData',
  USE_ANSWER_LABELS_WHERE_POSSIBLE: 'useAnswerLabelsWherePossible',
  INCLUDE_ONLY_PENDING_TICKETS: 'includeOnlyPending',
  DISPUTER_VIEWED_INITIAL_ASSESSMENT: 'disputerViewedInitialAssessment',
  ACTIVE_ANNOUNCEMENT: 'active',
  MATCH_EXACT_CODE: 'matchExactCode',
  USER_TICKET_TYPES: 'types',
  CLOUD_BUCKET_ORIGIN: 'bucketOrigin',
  REPORT_PERIOD: 'reportPeriod',
  VDN_NAME: 'vdnName',
  VDN_NUMBER: 'vdnNumber',
  FILE_NAME: 'fileName',
  TELEPHONY_LOGIN_CODE: 'telephonyLoginCode',
};

export const languageDescription: IKeyObject<string> = {
  RM: 'romansh',
  RO: 'romanian',
  BG: 'bulgarian',
  CZ: 'czech',
  SK: 'slovak',
  DA: 'danish',
  FI: 'finnish',
  SW: 'swedish',
  DE: 'german',
  HU: 'hungarian',
  FR: 'french',
  IT: 'italian',
  EL: 'greek',
  NL: 'dutch',
  NB: 'norwegian',
  PL: 'polish',
  PT: 'portuguese',
  EN: 'english',
  ES: 'spanish',
  TR: 'turkish',
  HE: 'hebrew',
  AR: 'arabic',
  HR: 'croatian',
  SR: 'serbian',
  MK: 'macedonian',
  SL: 'slovenian',
  RU: 'russian',
  UK: 'ukrainian',
  CNR: 'montenegrin',
  BS: 'bosnian',
  HI: 'hindi',
  IN: 'indonesian',
  JA: 'japanese',
  KO: 'korean',
  MS: 'malay',
  TH: 'thai',
  VI: 'vietnamese',
  CMN: 'mandarin',
  YUE: 'cantonese',
  UR: 'urdu',
};

export const SECTION_NAME = {
  QUALITY: 'Quality',
  MIN_SCORE: 'MinScore',
};

export const TRUE = 'true';
export const FALSE = 'false';

export type ITheme = 'light' | 'dark';

export const YesNoNA = {
  YES: 'YES',
  NO: 'NO',
  NA: 'NA',
};

export const FORM_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DRAFT: 'DRAFT',
} as const;

export const NATURAL_NUMBER_REGEX_LIST = /^[0-9 ,]*$/;
export const NATURAL_NUMBER_REGEX = /^[1-9][0-9]{0,9}$/;
export const NATURAL_NUMBER_ZERO_ALLOWED_REGEX = /^[0-9]{0,10}$/;

export const COMMA_DELIMITER = ',';
