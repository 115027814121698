import dateFnsFormat from 'date-fns/format';
import { enGB, fr, es, it, de, tr } from 'date-fns/locale';
import { TLanguageValues } from '../components/languageSelector/constants';
import { STANDARD_DATE_FORMAT } from '../constants/date';

const SUPPORTED_LOCALES: { [key in TLanguageValues]: Locale } = {
  en: enGB,
  fr,
  it,
  de,
  tr,
  es,
};

/**
 * Proxy for date-fns format method, in order to perform appropriate translation for dates
 * @param date
 * @param format
 */
export const formatDate = (date: Date | number, format = STANDARD_DATE_FORMAT) => {
  const currentLanguage = localStorage.getItem('language') as TLanguageValues;
  return dateFnsFormat(date, format, {
    locale: SUPPORTED_LOCALES[currentLanguage],
  });
};

export const formatTimelineDate = (date: Date | number) => {
  return formatDate(date, 'dd-MMM');
};
