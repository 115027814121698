import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAssessmentResponse } from '../../components/assessments/IAssessment';
import { ISelectOption } from '../../components/shared/helpers/IHelpers';

export const assessmentReducerInitialState = {
  caseIdWithStartedAssessmentsFromSegment: [] as string[],
  currentAssessment: undefined as IAssessmentResponse | undefined,
  availableChannels: undefined as ISelectOption[] | undefined,
  availableActivities: [] as ISelectOption<string, string>[],
};

const assessmentReducer = createSlice({
  name: 'assessmentReducer',
  initialState: assessmentReducerInitialState,
  reducers: {
    setCurrentAssessment: (state, { payload }: PayloadAction<IAssessmentResponse | undefined>) => {
      state.currentAssessment = payload;
    },
    setAvailableActivities: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.availableActivities = payload;
    },
    setAvailableChannels: (state, { payload }: PayloadAction<ISelectOption[] | undefined>) => {
      state.availableChannels = payload;
    },
    addCaseIdWithStartedAssessment: (state, { payload }: PayloadAction<string>) => {
      state.caseIdWithStartedAssessmentsFromSegment = [
        ...state.caseIdWithStartedAssessmentsFromSegment,
        payload,
      ];
    },
    resetState: () => assessmentReducerInitialState,
    resetQuestionnaireState: (state) => {
      state.currentAssessment = assessmentReducerInitialState.currentAssessment;
      state.caseIdWithStartedAssessmentsFromSegment =
        assessmentReducerInitialState.caseIdWithStartedAssessmentsFromSegment;
      state.availableActivities = assessmentReducerInitialState.availableActivities;
    },
  },
});

export const assessmentActions = assessmentReducer.actions;

export default assessmentReducer.reducer;
