import { AxiosPromise } from 'axios';

import { axiosInstance } from './axiosClient';
import apiPaths from './apiPaths';

export const languageApi: {
  setLanguage: (lang: string) => AxiosPromise;
} = {
  setLanguage: (lang: string) => {
    return axiosInstance.put(
      apiPaths.users + apiPaths.language,
      {},
      {
        params: {
          lang,
        },
      },
    );
  },
};
