import { useEffect } from 'react';
import { announcementsApi } from '../../api/announcementsApi';

import { useDispatch, useSelector } from 'react-redux';
import { campaignSelectors } from '../../store/selectors';
import useCampaignId from '../campaign/useCampaignId';
import { campaignActions } from '../../store/reducers/campaignReducer';

const useRegionAnnouncements = (): void => {
  const refreshFlag = useSelector(campaignSelectors.getAnnouncementsRefreshFlag);
  const [campaignId] = useCampaignId();
  const dispatch = useDispatch();

  useEffect(() => {
    announcementsApi
      .getRegionAnnouncements()
      .then(({ data }) => {
        dispatch(campaignActions.setAnnouncements(data));
      })
      .catch(console.error);
  }, [refreshFlag, campaignId]);
};

export default useRegionAnnouncements;
