import { Role } from '../providers/IPopulatorProvider';

export const QA_EQUIVALENT_ROLES: Role[] = [Role.QA, Role.QA_MANAGER];

export const ALL_ROLES = [
  Role.AGENT,
  Role.TEAM_LEAD,
  Role.QA,
  Role.QA_MANAGER,
  Role.ADMIN,
  Role.SUPER_ADMIN,
];

export const ALL_ROLES_WITHOUT_AGENT = [
  Role.TEAM_LEAD,
  Role.QA,
  Role.QA_MANAGER,
  Role.ADMIN,
  Role.SUPER_ADMIN,
];

export const ROLES_ALLOWED_TO_ACCESS_CALIBRATION = [...ALL_ROLES_WITHOUT_AGENT];

export const ROLES_ALLOWED_TO_ACCESS_TICKETS = [...ALL_ROLES];
export const ROLES_ALLOWED_TO_CREATE_TICKETS = [Role.QA_MANAGER, Role.ADMIN, Role.SUPER_ADMIN];

export const ROLES_ALLOWED_TO_EDIT_FORM = [Role.QA_MANAGER, Role.ADMIN, Role.SUPER_ADMIN];
export const ROLES_ALLOWED_TO_CREATE_ASSESSMENT_PLANS = [
  Role.QA_MANAGER,
  Role.ADMIN,
  Role.SUPER_ADMIN,
];
export const ROLES_ALLOWED_TO_EDIT_USER_ASSESSMENT_PLANS = [
  Role.TEAM_LEAD,
  Role.QA,
  ...ROLES_ALLOWED_TO_CREATE_ASSESSMENT_PLANS,
];
