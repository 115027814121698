import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import style from './UserTicketPlaceholder.module.scss';
import { ConcentrixWebhelpMyQALogo } from '../../assets/images';
import { getDefaultHomePageFor } from '../../routes/pathConstants';
import useAuth from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';

const UserTicketPlaceholderPage: FC = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth().authInfo;
  const history = useHistory();

  return (
    <div className={style.genericDisplay}>
      <img
        src={ConcentrixWebhelpMyQALogo}
        alt="Logo"
        className={style.webhelpLogo}
        onClick={() => history.push(getDefaultHomePageFor(userInfo))}
      />
      <h2 className={style.message}>{t('userTicket.placeholder.message')}</h2>
    </div>
  );
};

export default UserTicketPlaceholderPage;
