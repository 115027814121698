import { STATUS_TYPES } from '../components/shared/constants';

export const AssessmentTypeValues = {
  MONTHLY_CHECK: 'MONTHLY_CHECK',
  CLIENT_REQUESTED: 'CLIENT_REQUESTED',
  DEEP_DIVE: 'DEEP_DIVE',
  TARGET_COACHING: 'TARGET_COACHING',
  ASSURANCE_CHECK: 'ASSURANCE_CHECK',
  NON_SCORING: 'NON_SCORING',
} as const;

export type IAssessmentType = typeof AssessmentTypeValues[keyof typeof AssessmentTypeValues];

export const ASSESSMENTS_WITH_NO_SCORE: IAssessmentType[] = ['NON_SCORING'];

export type IAssessmentStatus = typeof STATUS_TYPES[keyof typeof STATUS_TYPES];

export const ASSESSMENTS_AVAILABLE_TO_AGENT: IAssessmentStatus[] = ['SUBMITTED', 'DONE'];

export const ASSESSMENT_STATUSES_HANDLED_BY_CASE_AGENT: IAssessmentStatus[] = [
  'DONE',
  'SUBMITTED',
  'DISPUTE_CLOSED',
];

export const DISPUTED_ASSESSMENTS: IAssessmentStatus[] = [
  'DISPUTE_REQUESTED',
  'DISPUTE_IN_PROGRESS',
  'DISPUTE_DRAFT',
  'DISPUTE_CLOSED',
];

export const UnacceptableTypeValues = {
  BASIC: 'BASIC',
  BUSINESS: 'BUSINESS',
  COMPLIANCE: 'COMPLIANCE',
  CUSTOMER: 'CUSTOMER',
} as const;

export type IUnacceptableType = typeof UnacceptableTypeValues[keyof typeof UnacceptableTypeValues];

export interface IAddAssesseeFeedbackRequest {
  assesseeFeedback: string;
}

export type TScoringGroupResult = {
  index: number;
  result?: string;
  name: string;
};
