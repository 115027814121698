import React, { FC, lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';

import paths from './routePaths';

import loaderStyle from '../components/Loader.module.scss';
import { ROUTE_PATHS } from './pathConstants';
import { APP_CONFIG } from '../constants/appConfig';

const Login = lazy(() => import(/* webpackChunkName: 'login' */ '../components/login/Login'));
const LoginB2C = lazy(
  () => import(/* webpackChunkName: 'loginB2c' */ '../components/login/LoginB2C'),
);
const LoginTest = lazy(
  () => import(/* webpackChunkName: 'loginTest' */ '../components/login/LoginTest'),
);

const LoginRoutes: FC = () => {
  return (
    <Suspense
      fallback={
        <div className={loaderStyle.loaderTotalCenter}>
          <DotLoader />
        </div>
      }>
      <Switch>
        <Route
          path={ROUTE_PATHS.util.login}
          component={APP_CONFIG.IS_B2C_LOGIN_ENABLED ? LoginB2C : Login}
        />
        {(!process.env.REACT_APP_ENV_TYPE ||
          process.env.REACT_APP_ENV_TYPE === 'development' ||
          process.env.REACT_APP_ENV_TYPE === 'pre-production') && (
          <Route path={paths.loginTest} component={LoginTest} />
        )}
        <Redirect to={ROUTE_PATHS.util.login} />
      </Switch>
    </Suspense>
  );
};

export default LoginRoutes;
