import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { CSSTransition } from 'react-transition-group';
import useOutsideClick from '../../hooks/shared/useOutsideClick';

import useAuth from '../../hooks/useAuth';

import LanguageSelector from '../languageSelector/LanguageSelector';

import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from '../../routes/pathConstants';
import HomeLogo from '../shared/logo/HomeLogo';

import style from './Header.module.scss';

const PreAuthHeader: FC = () => {
  const {
    authInfo: { userInfo },
    redirectToChangeCampaign,
    logout,
  } = useAuth();

  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();

  const handleDropdownState = (): void => {
    setShowDropdown(!showDropdown);
  };

  useOutsideClick(dropdownRef, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });
  if (location.pathname === ROUTE_PATHS.util.collectiveDashboard) {
    return null;
  }

  return (
    <div className={style.preAuthHeader}>
      <div className={style.preAuthHeaderLeftWrapper}>
        <HomeLogo size="small" onClick={() => redirectToChangeCampaign()} />
        <LanguageSelector />
      </div>
      <div className={style.wrapper}>
        <span className={style.role}>{t('default')}</span>
        <div className={style.name} onClick={handleDropdownState}>
          {userInfo && userInfo.firstName}
          {showDropdown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </div>
        {
          <CSSTransition in={showDropdown} timeout={300} unmountOnExit classNames={'dropdown'}>
            <ul className={style.dropDown} ref={dropdownRef}>
              <>
                <li
                  onClick={() => {
                    setShowDropdown(false);
                    redirectToChangeCampaign();
                  }}
                  className={style.bold}>
                  {t('dropdown.campaigns')}
                </li>
              </>
              <li onClick={logout} className={style.bold}>
                {t('logout')}
              </li>
            </ul>
          </CSSTransition>
        }
      </div>
    </div>
  );
};

export default PreAuthHeader;
