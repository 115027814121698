import React, { FC } from 'react';

import { IButton } from './IButton';

import styles from './Button.module.scss';

const Button: FC<IButton> = ({
  children,
  id,
  type = 'button',
  onClick = () => {
    return;
  },
  variant,
  size,
  disabled,
  isNotInProgress,
  lowercase,
  className,
  dataTip,
  dataFor,
}: IButton) => (
  <button
    id={id}
    className={`
      ${styles.btn}
      ${styles['btn-' + variant]}
      ${styles['btn-' + size]}
      ${disabled ? styles['btn-disabled'] : ''}
      ${isNotInProgress ? styles['btn-disabled-notInProgress'] : ''}
      ${lowercase ? styles['btn-lowercase'] : ''}
      ${className || ''}
    `}
    onClick={!disabled ? onClick : () => ({})}
    disabled={disabled}
    type={type}
    data-tip={dataTip}
    data-for={dataFor}>
    {children}
  </button>
);

export default Button;
