import { useEffect, useState } from 'react';

import { useAsyncError } from '../useAsyncError';
import useAuth from '../useAuth';

import { campaignsApi } from '../../api/campaignsApi';
import { ASC, PAGE_MAX_SIZE } from '../../components/shared/constants';
import { PROPERTY_NAMES } from '../../reducers/actionType';
import { Role } from '../../providers/IPopulatorProvider';
import { ICampaignPreviewData } from '../../providers/ICampaignPreviewProvider';

interface ICampaignPreviews {
  isLoading: boolean;
  campaignPreviews: ICampaignPreviewData[];
  hasAllCampaigns: boolean;
}

const searchCampaigns = {
  pageRequest: {
    page: 1,
    size: PAGE_MAX_SIZE,
  },
  sortingOptions: {
    direction: ASC,
    property: PROPERTY_NAMES.CAMPAIGN_NAME,
  },
};

const useShowCurrentCampaigns = (): ICampaignPreviews => {
  const {
    authInfo: { loggedIn },
    hasRole,
  } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const throwError = useAsyncError();
  const hasAllCampaigns = hasRole([Role.SUPER_ADMIN, Role.ADMIN]);
  const [campaignPreviews, setCampaignPreviews] = useState<ICampaignPreviewData[]>([]);

  useEffect(() => {
    if (!loggedIn || hasAllCampaigns) {
      return setIsLoading(false);
    }

    setIsLoading(true);
    campaignsApi
      .getCampaignPreviews(searchCampaigns)
      .then(({ data }) => setCampaignPreviews(data.elements))
      .catch(({ response }) => throwError(new Error(response.status)))
      .finally(() => setIsLoading(false));
  }, [loggedIn]);

  return { campaignPreviews, isLoading, hasAllCampaigns };
};

export default useShowCurrentCampaigns;
