import { IValueId, ICountryLocations, ISelectOption } from './IHelpers';
import i18n from 'i18next';
import { IUser } from '../../campaignManagement/shared/interfaces';

export const findSelectOptionByLabel = (
  label: string,
  options?: ISelectOption<string, string>[] | null,
): ISelectOption<string, string> | undefined => options?.find((option) => option.label === label);

export const findSelectOption = (
  value: string,
  options?: ISelectOption<string, string>[] | null,
): ISelectOption<string, string> | undefined => options?.find((option) => option.value === value);

export const findMultiSelectOption = <Value extends any, Label extends any = string>(
  values?: string[],
  options?: ISelectOption<string, string>[] | null,
): ISelectOption<string, string>[] =>
  values && values.length > 0 && options
    ? options.filter((option) => values.includes(option.value))
    : [];

export const transformToSelectOption = <T extends string>(
  value: T,
  label: T = value,
): ISelectOption<string, string> => {
  return { label, value };
};

export const transformToSelect = (list: IValueId[]): ISelectOption<string, string>[] => {
  return list.map((el: IValueId) => {
    const { value, id } = el;

    return transformToSelectOption(id, value);
  });
};

export const getAllLabels = (list: ISelectOption<string, string>[]): string[] => {
  const labels: string[] = [];
  list &&
    list.forEach((el: ISelectOption<string, string>) => {
      if (el.value !== '') labels.push(el.label);
    });

  return labels;
};

export const getAllValues = (list: ISelectOption<string, string>[]): string[] => {
  const values: string[] = [];
  list.forEach((el: ISelectOption<string, string>) => {
    if (el.value !== '') values.push(el.value);
  });

  return values;
};

export const populateSite = (
  countries: ISelectOption<string, string>[] | null | [{ value: string | undefined }],
  locations: ICountryLocations[] | null,
): ISelectOption<string, string>[] | null => {
  let site: ISelectOption<string, string>[] = [];

  if (!countries) {
    return site;
  }

  if (countries.length === 0) {
    locations &&
      locations.forEach((element) => {
        site = site.concat(element.sites);
      });
  }

  countries.forEach((el: ISelectOption<string, string> | { value: string | undefined }) => {
    locations &&
      locations.length > 0 &&
      locations.forEach((element) => {
        if (element.country.value === el.value) {
          site = site.concat(element.sites);
        }
      });
  });

  if (site.length === 0) return null;
  return site;
};

export const addLabelWithTranslation = (
  prefix: string,
  select: ISelectOption[],
): ISelectOption[] => {
  return select.map(({ label, value }: ISelectOption) => {
    return {
      label: i18n.t(`${prefix}.${label}`),
      value,
    };
  });
};

/**
 * Sorts given list of select options by label. Sorting is done in place.
 */
export const sortOptions = (
  options: ISelectOption<string, string>[],
): ISelectOption<string, string>[] => {
  options.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
  return options;
};

export function getUserNameWithReference(user: {
  userReference: string;
  firstName: string;
  lastName: string;
}) {
  return `${user.userReference} - ${user.firstName} ${user.lastName}`;
}

/**
 * Transforms a given user in an object of {@code ISelectOption} form used for Selectors
 *
 * @param user
 */
export const prepareUserForSelect = (user?: IUser): ISelectOption<string, string> => {
  if (!user) {
    return {
      value: '',
      label: '',
    };
  }
  return {
    value: user.id,
    label: getUserNameWithReference(user),
  };
};

export const createOption = <T>(label: T): ISelectOption<string, T> => ({
  label: `${label}`,
  value: label,
});
