import { IStore } from '..';
import { IAssessmentResponse } from '../../components/assessments/IAssessment';
import { ISelectOption } from '../../components/shared/helpers/IHelpers';

export const getCurrentAssessment = (state: IStore): IAssessmentResponse | undefined =>
  state.assessmentReducer.currentAssessment;
export const getAvailableActivities = (state: IStore): ISelectOption<string, string>[] =>
  state.assessmentReducer.availableActivities;
export const getAvailableChannels = (state: IStore): ISelectOption[] | undefined =>
  state.assessmentReducer.availableChannels;
export const getCurrentCasesUsedForStartAssessments = (state: IStore): string[] =>
  state.assessmentReducer.caseIdWithStartedAssessmentsFromSegment;
