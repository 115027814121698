import { IUser } from '../campaignManagement/shared/interfaces';
import { IPageable, ISortList, IValueId } from '../shared/helpers/IHelpers';
import { IAssessmentType } from '../../interfaces/assessment';
import { UUID } from '../../interfaces';
import { IUserNameReference } from '../../interfaces/user';
import { TPlanId, TUserId } from '../../interfaces/general';

export interface IPage {
  sortingOptions: ISortList[];
  pageRequest: IPageable;
}

export interface IAssessmentPlanSearch {
  onSubmit: (values: IPage) => void;
  initialValues: Partial<IPage>;
}

export type TAssessmentType = string;

export const UserAssessmentPlanType = {
  ASSESSOR: 'ASSESSOR',
  ASSESSEE: 'ASSESSEE',
} as const;

export type IUserAssessmentPlanType = typeof UserAssessmentPlanType[keyof typeof UserAssessmentPlanType];

export interface IUserAssessmentPlanSearch extends IPage {
  id: string;
  referencePlanId?: string;
  userAssessmentPlanType: IUserAssessmentPlanType;
  type: IAssessmentType;
}

export type TAddAssessmentPlan = {
  period: {
    startDate: string | Date;
    endDate: string | Date;
  };
  title: string;
};

export interface IAssessmentPlanRequest extends IPage {
  campaignId: string;
}

export interface IAssessmentPlanResponse {
  campaign: IValueId;
  creator: IUser;
  id: string;
  startDate: string;
  endDate: string;
  title: string;
}

export interface IAssessmentPlanGeneralInfo {
  assessmentType: IAssessmentType;
  planType: IUserAssessmentPlanType;
  plannedAssessments: number;
  completedAssessments: number;
}

export interface IAssessmentPlanCreate {
  startDate: string;
  endDate: string;
  title: string;
}

export interface IAssessmentPlanData {
  startDate: string;
  endDate: string;
  title: string;
  id: string;
}

export interface IAssessmentPlanHistory {
  id: string;
  created: string;
  editor: IUserNameReference;
  message: string;
  users: { user: IUserNameReference; previousPlanned: number; currentPlanned: number }[];
}

export interface ICurrentPlannedUserAssessmentPlan
  extends Omit<IPerformanceUserAssessmentPlan, 'userId'> {
  user: IUser;
  supervisor: IUser;
  plannedAssessments: number;
  completedAssessments: number;
}

export interface IUserData {
  user: IUser;
  supervisor?: IUser;
}

export interface IUserAssessmentPlanData extends IPerformanceUserAssessmentPlan {
  plannedAssessments: number;
  completedAssessments: number;
}

export interface IUserPlannedData {
  plannedAssessments: number;
  completedAssessments: number;
}

export interface IUpdateUserPlanData {
  userId: string;
  planned: number;
}

export interface IUserPlanDataComparison {
  user: IUserNameReference;
  currentPlanned: number;
  nextPlanned: number;
}

export interface IAssessmentPlanCreateOrUpdate {
  planId: string;
  assessmentType: IAssessmentType;
  type: IUserAssessmentPlanType;
  users: IUpdateUserPlanData[];
}

export interface IAssessmentPlanDelete {
  planId: string;
  assessmentType: IAssessmentType;
  type: IUserAssessmentPlanType;
  users: TUserId[];
}

export interface IAssessmentPlansDiscardWarning {
  users: IUserNameReference[];
  type: IUserAssessmentPlanType;
}

export interface IAssessmentPlanFillAll {
  usersPlanDataComparison: IUserPlanDataComparison[];
  type: IUserAssessmentPlanType;
}

export interface IAssessmentPlanImportPreviousPlan {
  usersPlanDataComparison: IUserPlanDataComparison[];
  type: IUserAssessmentPlanType;
}

export interface IAssessmentPlanDistributeEvenly {
  initialValue: number;
}

export interface IAssessmentPlanAllocateVariance {
  initialValue: number;
}

export interface IAssessmentPlanCreateOrUpdateRequest extends IAssessmentPlanCreateOrUpdate {
  message: string;
}

export interface IAssessmentPlanDeleteRequest extends IAssessmentPlanDelete {
  message: string;
}

export interface IPerformanceUserAssessmentPlan {
  userId: UUID;
  scorePercentage?: number;
  criticalFailRate?: number;
  passFailPercentage?: number;
}

export interface IAssessmentPlanReducer {
  selectedPlans: TPlanId[];
  plans: Record<TPlanId, IAssessmentPlanData>;
  assesseePlannedUserData: IUserData[];
  assesseeUnplannedUserData: IUserData[];
  assesseeVisibleUserData: IUserData[];
  assessorPlannedUserData: IUserData[];
  assessorUnplannedUserData: IUserData[];
  assessorVisibleUserData: IUserData[];
  assesseePlansData: Record<TPlanId, Record<TUserId, IUserAssessmentPlanData>>;
  assessorPlansData: Record<TPlanId, Record<TUserId, IUserAssessmentPlanData>>;
  assesseeChangedPlanData: Record<TUserId, number>;
  assessorChangedPlanData: Record<TUserId, number>;
  selectedAssessees: TUserId[];
  selectedAssessors: TUserId[];
  shouldUnplannedAssesseesLoad: boolean;
  shouldUnplannedAssessorsLoad: boolean;
  assessmentPlanGeneralTable: Record<TPlanId, Record<TAssessmentType, IUserPlannedData>>;
  unbalancedPlanRefreshFlag: boolean;
  showOnlyPlanned: IUserAssessmentPlanType[];
}
