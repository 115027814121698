import { AxiosPromise } from 'axios';

import { axiosInstance } from './axiosClient';
import apiPaths from './apiPaths';

export const authApi: {
  login: (email: string, password: string) => AxiosPromise;
  loginWithB2CToken: (token: string) => AxiosPromise<string>;
  changeCampaign: (campaignId: string) => AxiosPromise;
  logout: () => AxiosPromise;
} = {
  login: (email: string, password: string) => {
    return axiosInstance.post(apiPaths.login, { email, password });
  },
  loginWithB2CToken: (token: string) => {
    return axiosInstance.post(apiPaths.login + '/b2c', token);
  },
  changeCampaign: (campaignId: string) => {
    return axiosInstance.post(apiPaths.login + apiPaths.changeCampaign, null, {
      params: { campaignId },
    });
  },
  logout: () => axiosInstance.post(apiPaths.logout),
};
