import React, { FC } from 'react';
import { DotLoader } from 'react-spinners';
import { TranslationKey } from '../../../translations';

import style from '../../Loader.module.scss';

import NoResultFound from '../noResults/NoResultFound';

interface IProps {
  id?: string;
  size?: number;
  label?: string;
  isLoading?: boolean;
  hasValue?: boolean;
  hasPadding?: boolean;
  hasMarginLeft?: boolean;
  noResultsLabelId?: TranslationKey;
  wrapperClassName?: string;
}

const Loader: FC<IProps> = ({
  id,
  size = 30,
  label,
  children,
  isLoading = true,
  hasValue = true,
  hasPadding = false,
  hasMarginLeft = false,
  noResultsLabelId,
  wrapperClassName,
}) => {
  if (!isLoading) {
    return (
      <NoResultFound hasValue={hasValue} label={noResultsLabelId}>
        {children}
      </NoResultFound>
    );
  }

  return (
    <div
      className={`${label ? style.loaderWithText : style.loaderAuto} ${
        hasPadding ? style.padding : ''
      } ${hasMarginLeft ? style.marginLeft : ''} ${wrapperClassName}`}
      id={id}>
      <DotLoader size={size} />
      {label && <label>{label}</label>}
    </div>
  );
};

export default Loader;
