import { AxiosPromise } from 'axios';

import { axiosInstance } from './axiosClient';
import apiPaths from './apiPaths';

import {
  ICampaignNamesRequest,
  ICampaignRequest,
  ICreateCampaignRequest,
} from '../components/campaignManagement/ICampaignManagement';
import {
  ICampaignPreviewData,
  ICampaignPreviewRequest,
} from '../providers/ICampaignPreviewProvider';
import { IUpdateUserPermission } from '../components/campaignManagement/shared/interfaces';
import { PagedSearch } from '../components/shared/interfaces';
import { IValueIdReference } from '../components/shared/helpers/IHelpers';
import { CaseChannelEnum, IFormList } from '../interfaces/form';

const {
  search,
  userPermissions,
  names,
  forms,
  activeForms,
  statuses,
  searchPreviews,
  yesNoOptions,
  campaigns,
  common,
  regions,
  create,
  availableRegions,
} = apiPaths;
export const campaignsApi: {
  getCampaignNames: (
    payload: Partial<ICampaignNamesRequest>,
  ) => AxiosPromise<PagedSearch<IValueIdReference>>;
  getForms: (formId: string) => AxiosPromise;
  getActiveFormsByChannel: (
    campaignId: string,
    channel: CaseChannelEnum,
  ) => AxiosPromise<IFormList[]>;
  getCampaignList: (payload: Partial<ICampaignRequest>) => AxiosPromise;
  getCampaignStatuses: () => AxiosPromise;
  getVdnConnections: () => AxiosPromise;
  getCampaignRegions: () => AxiosPromise;
  getAvailableRegions: () => AxiosPromise;
  createCampaign: (campaignRequest: ICreateCampaignRequest) => AxiosPromise<void>;
  updateDownloadPermission: (request: IUpdateUserPermission) => AxiosPromise;
  getCampaignPreviews: (
    payload: ICampaignPreviewRequest,
  ) => AxiosPromise<PagedSearch<ICampaignPreviewData>>;
} = {
  updateDownloadPermission: ({ campaignId, ...rest }) =>
    axiosInstance.patch(`${campaigns}/${campaignId}${userPermissions}`, rest),
  getCampaignNames: (payload: Partial<ICampaignNamesRequest>) =>
    axiosInstance.post(campaigns + search + names, payload),
  getForms: (formId: string) => axiosInstance.get(`${campaigns}/${formId}${forms}`),
  getActiveFormsByChannel: (campaignId: string, channel: CaseChannelEnum) =>
    axiosInstance.get(campaigns + '/' + campaignId + activeForms + '?channel=' + channel),
  getCampaignList: ({ sortingOptions, ...rest }: Partial<ICampaignRequest>) =>
    axiosInstance.post(campaigns + search, {
      sortingOptions: [sortingOptions],
      ...rest,
    }),
  createCampaign: (campaignRequest) =>
    axiosInstance.post(campaigns + create, { ...campaignRequest }),
  getCampaignRegions: () => axiosInstance.get(campaigns + regions),
  getCampaignStatuses: () => axiosInstance.get(campaigns + statuses),
  getAvailableRegions: () => axiosInstance.get(campaigns + availableRegions),
  getVdnConnections: () => axiosInstance.get(common + yesNoOptions),
  getCampaignPreviews: ({ sortingOptions, ...rest }) =>
    axiosInstance.post(campaigns + searchPreviews, {
      sortingOptions: [sortingOptions],
      ...rest,
    }),
};
