import { ISelectOption } from '../components/shared/helpers/IHelpers';

export enum Role {
  DEFAULT = 'DEFAULT',
  AGENT = 'AGENT',
  RESTRICTED = 'RESTRICTED',
  TEAM_LEAD = 'TEAM_LEAD',
  QA = 'QA',
  QA_MANAGER = 'QA_MANAGER',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SUPERVISOR = 'SUPERVISOR',
}

export const VIEW_EDITED_ASSESSMENT_ROLES: Role[] = [Role.QA_MANAGER, Role.ADMIN, Role.SUPER_ADMIN];

export const DELETE_CALL_FILES_ROLES: Role[] = [Role.ADMIN, Role.SUPER_ADMIN];

export type DataPermissionType = typeof DataPermissionValues[number];

export const DataPermissionValues = [
  'REPORT',
  'CALL',
  'ASSESSMENT_READ',
  'ASSESSMENT_EDIT',
  'CALIBRATION',
] as const;

export enum AdminPermission {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export type ICampaignUser = {
  [K in Role]?: ISelectOption<string, string>[];
};

export type IUserData = {
  [key: string]: { includeLeavers: boolean } & ICampaignUser;
};

export interface ICampaignsData {
  campaignsArray: ISelectOption<string, string>[];
  isLoading: boolean;
}

export interface IChannelData {
  channels: ISelectOption<string, string>[];
  isLoading: boolean;
}

export type ContextProps = {
  users: IUserData | null;
  getUsers: (campaignId: string, includeLeavers?: boolean) => Promise<void>;
};

export enum EPopulator {
  USERS = 'USERS',
  CAMPAIGNS = 'CAMPAIGNS',
  CHANNELS = 'CHANNELS',
}
