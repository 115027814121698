import React, { FC, lazy, Suspense } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';
import { ROUTE_PATHS } from './pathConstants';
import CollectiveDashboardWrapper from '../components/collectiveDashboard/CollectiveDashboardWrapper';

import paths from './routePaths';
import useAuth from '../hooks/useAuth';
import { AdminPermission, Role } from '../providers/IPopulatorProvider';

import loaderStyle from '../components/Loader.module.scss';

const OwnAssessments = lazy(
  () => import(/* webpackChunkName: 'assessments' */ '../components/assessments/OwnAssessments'),
);

const CallImportErrors = lazy(
  () => import(/* webpackChunkName: 'assessments' */ '../components/callErrors/CallErrors'),
);

const Questionnaire = lazy(
  () =>
    import(
      /* webpackChunkName: 'questionnaire' */ '../components/questionnaire/QuestionnaireWithSegmentsWrapper'
    ),
);

const UnrestrictedCalls = lazy(
  () =>
    import(
      /* webpackChunkName: 'calls' */ '../components/calls/unrestrictedCalls/UnrestrictedCalls'
    ),
);

const QuestionnaireDispute = lazy(
  () =>
    import(
      /* webpackChunkName: 'questionnaireDispute' */ '../components/questionnaire/dispute/QuestionnaireDisputeWithSegmentsWrapper'
    ),
);

export const ProtectedRoute: React.FC<{ shouldBeAccessible: boolean } & RouteProps> = ({
  shouldBeAccessible,
  ...props
}) => {
  if (shouldBeAccessible) {
    return <Route {...props} />;
  }
  return <Redirect to={paths.collectiveDashboard} />;
};

const PreAuthorizedRoutes: FC = () => {
  const { authInfo, hasRole } = useAuth();
  const isSuperAdmin =
    authInfo.userInfo?.admin === AdminPermission.SUPER_ADMIN || hasRole(Role.SUPER_ADMIN);

  return (
    <Suspense fallback={<div className={loaderStyle.loaderTotalCenter}>{<DotLoader />}</div>}>
      <Switch>
        <Route
          path={ROUTE_PATHS.util.collectiveDashboard}
          exact
          component={CollectiveDashboardWrapper}
        />
        <Route path={ROUTE_PATHS.ownAssessments.list} component={OwnAssessments} />
        <Route
          path={paths.assessments + paths.questionnaireDispute + '/:caseId'}
          component={QuestionnaireDispute}
        />
        <Route
          path={paths.assessments + paths.questionnaire + '/:caseId'}
          component={Questionnaire}
        />
        <Route
          path={paths.assessments + paths.own + paths.questionnaire + '/:caseId'}
          component={Questionnaire}
        />
        <ProtectedRoute
          path={ROUTE_PATHS.calls.unrestrictedList}
          exact
          component={UnrestrictedCalls}
          shouldBeAccessible={isSuperAdmin}
        />
        <ProtectedRoute
          path={ROUTE_PATHS.callErrors.list}
          component={CallImportErrors}
          shouldBeAccessible={isSuperAdmin}
        />
        <Redirect to={paths.collectiveDashboard} />
      </Switch>
    </Suspense>
  );
};

export default PreAuthorizedRoutes;
