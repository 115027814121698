import { ILocation, IResponseType, ICountryLocations, ISelectOption } from './IHelpers';

export const getCountryList = (
  locationList: ICountryLocations[] | null,
): ISelectOption<string, string>[] | null => {
  return locationList && locationList.map((el: ICountryLocations) => el.country);
};

export const prepareLocations = (countyList: ILocation[]): ICountryLocations[] =>
  countyList.map((country) => ({
    country: {
      label: country.name,
      value: country.id,
    },
    sites: country.sites.map((element: IResponseType) => ({
      label: element.name,
      value: element.id,
    })),
  }));
