import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountryLocations } from '../../components/shared/helpers/IHelpers';

export const locationReducerInitialState: {
  [key: string]: ICountryLocations[];
  all: ICountryLocations[];
} = { all: [] };

type TSetLocations = {
  campaignId: string;
  locations: ICountryLocations[];
};

const locationReducer = createSlice({
  name: 'locationReducer',
  initialState: locationReducerInitialState,
  reducers: {
    setLocations: (state, { payload }: PayloadAction<TSetLocations>) => {
      state[payload.campaignId] = payload.locations;
    },
    setAllLocations: (state, { payload }: PayloadAction<ICountryLocations[]>) => {
      state.all = payload;
    },
  },
});

export const locationActions = locationReducer.actions;

export default locationReducer.reducer;
