import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectOption } from '../../components/shared/helpers/IHelpers';
import { Role } from '../../providers/IPopulatorProvider';
import { MailTemplateType, STATUS_TYPES } from '../../components/shared/constants';
import { IFormQuestion } from '../../components/formBuilder/IFormBuilder';

export const formReducerInitialState = {
  conditionalQuestionModal: {
    question: undefined as IFormQuestion | undefined,
    answerId: '' as string | undefined,
    isViewMode: false as boolean | undefined,
    open: false,
  },
  questionsData: {
    scoringTypes: [] as ISelectOption[],
    questionTypes: [] as ISelectOption[],
  },
  mailSettings: [] as IMailOption[],
  interactionLanguages: [] as string[],
};

export type IMailOption = {
  assessmentStatus: typeof STATUS_TYPES[keyof typeof STATUS_TYPES];
  roles: Role[];
  mailTemplates: MailTemplateType[];
};

export type TConditionalModal =
  | undefined
  | {
      question?: IFormQuestion;
      isViewMode?: boolean;
      answerId?: string;
    };

const formReducer = createSlice({
  name: 'formReducer',
  initialState: formReducerInitialState,
  reducers: {
    setMailSettings: (state, { payload }: PayloadAction<IMailOption[]>) => {
      state.mailSettings = payload;
    },
    setInteractionLanguages: (state, { payload }: PayloadAction<string[]>) => {
      state.interactionLanguages = payload;
    },
    openConditionalQuestionModal: (state, { payload }: PayloadAction<TConditionalModal>) => {
      state.conditionalQuestionModal.question = payload?.question;
      state.conditionalQuestionModal.answerId = payload?.answerId;
      state.conditionalQuestionModal.isViewMode = payload?.isViewMode;
      state.conditionalQuestionModal.open = true;
    },
    saveConditionalQuestion: (state, { payload }: PayloadAction<TConditionalModal>) => {
      state.conditionalQuestionModal.question = payload?.question;
      state.conditionalQuestionModal.answerId = payload?.answerId;
      state.conditionalQuestionModal.open = false;
    },
    removeConditionalQuestion: (state) => {
      state.conditionalQuestionModal = formReducerInitialState.conditionalQuestionModal;
    },
    setQuestionsData: (
      state,
      { payload }: PayloadAction<typeof formReducerInitialState['questionsData']>,
    ) => {
      state.questionsData = payload;
    },
  },
});

export const formActions = formReducer.actions;

export default formReducer.reducer;
