import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../components/shared/loader/Loader';
import useCampaignId from '../../hooks/campaign/useCampaignId';
import useAuth from '../../hooks/useAuth';

export const INTERMEDIARY_CAMPAIGN_NAME = 'campaignName';
export const INTERMEDIARY_CAMPAIGN_REFERENCE = 'campaignReference';

function ChangeCampaignIntermediary() {
  const { campaignId } = useParams<{ campaignId: string }>();
  const [, setCampaignId] = useCampaignId();
  const location = useLocation();
  const history = useHistory();
  const { changeCampaign } = useAuth();

  useEffect(() => {
    if (!campaignId) {
      return setCampaignId(null);
    }
    const querySearch = new URLSearchParams(location.search);
    changeCampaign(
      campaignId,
      querySearch.get(INTERMEDIARY_CAMPAIGN_NAME) as string,
      querySearch.get(INTERMEDIARY_CAMPAIGN_REFERENCE) as string,
    ).then(() => history.push('/'));
  }, []);

  return <Loader isLoading />;
}

export default ChangeCampaignIntermediary;
