import {
  IAssessmentPlanData,
  IAssessmentPlanResponse,
  ICurrentPlannedUserAssessmentPlan,
  IUpdateUserPlanData,
  IUserAssessmentPlanData,
  IUserData,
  IUserPlanDataComparison,
} from './IAssessmentPlan';
import { formatDate } from '../../utils/dateUtils';
import { TPlanId, TUserId } from '../../interfaces/general';

export function isPlanCurrentOrInFuture(plan: IAssessmentPlanData): boolean {
  return plan && plan.endDate >= formatDate(Date.now());
}

export function findCurrentPlanId(
  planKeys: string[],
  plans: Record<TPlanId, IAssessmentPlanData>,
): string {
  const currentDate = formatDate(Date.now());
  return (
    planKeys.find(
      (planKey) => plans[planKey].startDate <= currentDate && plans[planKey].endDate >= currentDate,
    ) || ''
  );
}

export function getFuturePlanIndex(
  planKeys: string[],
  plans: Record<TPlanId, IAssessmentPlanData>,
): number {
  const currentDate = formatDate(Date.now());
  return planKeys.findIndex((planKey) => plans[planKey].startDate > currentDate);
}

export function transformPlansData(
  values: IAssessmentPlanResponse[],
): Record<TPlanId, IAssessmentPlanData> {
  return values.reduce((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {} as Record<string, IAssessmentPlanData>);
}

export function concatUserPlanData(
  planId: string,
  userPlanData: Record<TUserId, IUserAssessmentPlanData>,
  values: IUserAssessmentPlanData[],
): Record<TUserId, IUserAssessmentPlanData> {
  return {
    ...values.reduce(
      (acc, v) => {
        acc[v.userId] = v;
        return { ...acc };
      },
      { ...userPlanData },
    ),
  };
}

export const toUpdateUserPlanData = (values: Record<TUserId, number>): IUpdateUserPlanData[] => {
  if (!values || !Object.keys(values)?.length) return [];
  return Object.keys(values).map((key) => {
    return {
      userId: key,
      planned: values[key],
    } as IUpdateUserPlanData;
  });
};

export const updateUserAssessmentPlan = (
  userPlan: Record<TUserId, IUserAssessmentPlanData>,
  updatedValues: Record<TUserId, number>,
): Record<string, IUserAssessmentPlanData> => {
  if (!updatedValues || !Object.keys(updatedValues)) return userPlan;
  return Object.keys(updatedValues).reduce(
    (acc, v) => {
      return { ...acc, [v]: { ...acc[v], plannedAssessments: updatedValues[v] } };
    },
    { ...userPlan },
  );
};

export const sumTotalPlannedAssessments = (
  userPlan: Record<TUserId, IUserAssessmentPlanData>,
  changedData: Record<TUserId, number>,
): number => {
  if (!userPlan || !Object.keys(userPlan)) return 0;

  return Object.keys(userPlan).reduce(
    (acc, v) => acc + (changedData[v] ?? userPlan[v].plannedAssessments),
    0,
  );
};

export const getUpdatedPlanFromImport = (
  previousUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  currentUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  userChangedPlanData: Record<TUserId, number>,
): Record<TUserId, number> => {
  const updatedPlan = Object.keys(previousUserPlan || {}).reduce(
    (acc, v) => {
      return {
        ...acc,
        [v]: previousUserPlan[v].plannedAssessments,
      };
    },
    { ...userChangedPlanData },
  );

  return filterNonChangingUsersInPlan(currentUserPlan, updatedPlan);
};

export const getUpdatedPlanFromImportForSelectedUsers = (
  previousUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  currentUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  userChangedPlanData: Record<TUserId, number>,
  selectedUsers: TUserId[],
): Record<TUserId, number> => {
  const updatedPlan = Object.keys(previousUserPlan || {}).reduce(
    (acc, v) => {
      if (selectedUsers.includes(currentUserPlan[v]?.userId)) {
        acc[v] = previousUserPlan[v].plannedAssessments;
      }

      return acc;
    },
    { ...userChangedPlanData },
  );

  return filterNonChangingUsersInPlan(currentUserPlan, updatedPlan);
};

export const getUpdatedPlanWithValue = (
  currentUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  value: number,
): Record<TUserId, number> => {
  return Object.keys(currentUserPlan).reduce((acc, v) => {
    return {
      ...acc,
      ...(currentUserPlan[v].plannedAssessments === value ? {} : { [v]: value }),
    };
  }, {});
};

export const getUpdatedPlanWithValueForSelectedUsers = (
  currentUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  userChangedPlanData: Record<TUserId, number>,
  selectedUsers: TUserId[],
  value: number,
): Record<TUserId, number> => {
  const updatedPlan = Object.keys(currentUserPlan).reduce(
    (acc, v) => {
      if (selectedUsers.includes(currentUserPlan[v].userId)) {
        acc[v] = value;
      }

      return acc;
    },
    { ...userChangedPlanData },
  );

  return filterNonChangingUsersInPlan(currentUserPlan, updatedPlan);
};

export const getUpdatedPlanIncrementedWithValue = (
  currentUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  userChangedPlanData: Record<TUserId, number>,
  value: number,
): Record<TUserId, number> => {
  const updatedPlan = Object.keys(currentUserPlan).reduce(
    (acc, v) => {
      return {
        ...acc,
        [v]: (userChangedPlanData[v] || currentUserPlan[v].plannedAssessments) + value,
      };
    },
    { ...userChangedPlanData },
  );

  return filterNonChangingUsersInPlan(currentUserPlan, updatedPlan);
};

export const getUpdatedPlanIncrementedWithValueForSelectedUsers = (
  currentUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  userChangedPlanData: Record<TUserId, number>,
  selectedUsers: TUserId[],
  value: number,
): Record<TUserId, number> => {
  const updatedPlan = Object.keys(currentUserPlan).reduce(
    (acc, v) => {
      return {
        ...acc,
        ...(!selectedUsers.includes(currentUserPlan[v].userId)
          ? {}
          : { [v]: (userChangedPlanData[v] || currentUserPlan[v].plannedAssessments) + value }),
      };
    },
    { ...userChangedPlanData },
  );

  return filterNonChangingUsersInPlan(currentUserPlan, updatedPlan);
};

export const extractUserData = (elements: ICurrentPlannedUserAssessmentPlan[]): IUserData[] => {
  return elements.reduce((acc, { user, supervisor }) => {
    return [...acc, { user, supervisor }] as IUserData[];
  }, [] as IUserData[]);
};

export const extractUserAssessmentPlanData = (
  elements: ICurrentPlannedUserAssessmentPlan[],
): IUserAssessmentPlanData[] => {
  return elements.reduce((acc, v) => {
    acc.push({
      userId: v.user.id,
      completedAssessments: v.completedAssessments,
      plannedAssessments: v.plannedAssessments,
      scorePercentage: v.scorePercentage,
      passFailPercentage: v.passFailPercentage,
      criticalFailRate: v.criticalFailRate,
    });
    return acc;
  }, [] as IUserAssessmentPlanData[]);
};

export const getFormattedUserAssessmentPlanData = (
  elements: IUserAssessmentPlanData[],
): IUserAssessmentPlanData[] => {
  return elements.map((el) => {
    return {
      ...el,
      scorePercentage:
        el.scorePercentage != null ? Number(el.scorePercentage.toFixed(2)) : undefined,
      passFailPercentage:
        el.passFailPercentage != null ? Number(el.passFailPercentage?.toFixed(2)) : undefined,
      criticalFailRate:
        el.criticalFailRate != null ? Number(el.criticalFailRate?.toFixed(2)) : undefined,
    };
  });
};

export const getUserAssessmentPlanDataComparison = (
  previousUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  currentUserPlan: Record<TUserId, IUserAssessmentPlanData>,
  userChangedPlanData: Record<TUserId, number>,
  usersData: IUserData[],
  excludeNullUnchangingComparisons = true,
): IUserPlanDataComparison[] => {
  const userPlansComparison: IUserPlanDataComparison[] = Object.keys(currentUserPlan)
    .map((userId) => currentUserPlan[userId])
    .filter(
      (userPlan) =>
        usersData.find((userData) => userData.user.id === userPlan.userId) !== undefined,
    )
    .map((userPlan) => {
      const userData = usersData.filter((userData) => userData.user.id === userPlan.userId)[0];

      return {
        user: userData?.user,
        currentPlanned: userChangedPlanData[userData?.user.id] ?? userPlan.plannedAssessments,
        nextPlanned: userChangedPlanData[userData?.user.id] ?? userPlan.plannedAssessments,
      };
    });

  Object.keys(previousUserPlan || {}).forEach((userId) => {
    const userPlan = userPlansComparison.find((userPlan) => userPlan.user?.id === userId);
    if (userPlan) {
      userPlan.nextPlanned = previousUserPlan[userId].plannedAssessments;
    }
  });

  return !excludeNullUnchangingComparisons
    ? userPlansComparison
    : userPlansComparison.filter(
        (userPlan) => userPlan.currentPlanned !== 0 || userPlan.nextPlanned !== 0,
      );
};

const filterNonChangingUsersInPlan = (
  currentPlan: Record<TUserId, IUserAssessmentPlanData>,
  updatedPlan: Record<TUserId, number>,
): Record<TUserId, number> => {
  return Object.keys(updatedPlan)
    .filter(
      (userId) =>
        currentPlan[userId] && updatedPlan[userId] !== currentPlan[userId].plannedAssessments,
    )
    .reduce((acc, v) => Object.assign(acc, { [v]: updatedPlan[v] }), {} as Record<TUserId, number>);
};

export const getUsersPlanDataForUsers = (
  usersPlanData: Record<TUserId, IUserAssessmentPlanData>,
  usersData: IUserData[],
): Record<TUserId, IUserAssessmentPlanData> => {
  const userIds = usersData.map((userData) => userData.user.id);

  return Object.keys(usersPlanData)
    .filter((userId) => userIds.includes(userId))
    .reduce((acc, v) => Object.assign(acc, { [v]: usersPlanData[v] }), {});
};
