export type TLoginConfig = 'SAML' | 'B2C';

const isElasticSearchEnabled = process.env.REACT_APP_IS_ELASTIC_SEARCH_ENABLED === 'true';
const isManualImportSourceEnabled = (process.env.REACT_APP_IS_MANUAL_IMPORT_SOURCE_ENABLED ===
  'true' || false) as boolean;
const loginConfig: TLoginConfig = (process.env.REACT_APP_LOGIN_CONFIGURATION ||
  'SAML') as TLoginConfig;
const isAdminAllowedToEditExternalUser =
  process.env.REACT_APP_IS_ADMIN_ALLOWED_EDIT_EXTERNAL_USER === 'true';

const isMailNotificationsEnabled =
  (process.env.REACT_APP_IS_MAIL_NOTIFICATIONS_ENABLED || 'true') === 'true';

export const APP_CONFIG = {
  APP_VERSION: process.env.REACT_APP_APPLICATION_VERSION,
  IS_CREATE_CAMPAIGN_ENABLED: isManualImportSourceEnabled,
  IS_IMPORT_USERS_ENABLED: isManualImportSourceEnabled,
  IS_LOCATION_MANAGEMENT_ENABLED: isManualImportSourceEnabled,
  IS_TRANSCRIPT_ANALYSIS_DASHBOARD_ENABLED: false,
  IS_TRANSCRIPT_ANALYSIS_CONFIG_ENABLED: isElasticSearchEnabled,
  IS_TRANSCRIPT_SEARCH_ENABLED: isElasticSearchEnabled,
  IS_TRANSCRIPT_ANALYSIS_SEARCH_ENABLED: isElasticSearchEnabled,
  IS_MAIL_NOTIFICATION_ACTIVE: isMailNotificationsEnabled,
  IS_SAML_LOGIN_ENABLED: loginConfig === 'SAML',
  IS_B2C_LOGIN_ENABLED: loginConfig === 'B2C',
  IS_TELEPHONY_LOGIN_PAGE_SUPER_ADMIN_ONLY: isManualImportSourceEnabled,
  IS_ADMIN_ALLOWED_EDIT_EXTERNAL_USER: isAdminAllowedToEditExternalUser,
  B2C_LOGIN_TIMEOUT: Number(process.env.REACT_APP_B2C_LOGIN_TIMEOUT_MS) || 20_000,
};
