/**
 * @Deprecated
 */
export default {
  basename: '/frontend',
  home: '/',
  login: '/login',
  loginTest: '/loginTest',
  assessments: '/assessments',
  ownAssessments: '/own-assessments',
  detailsComplete: '/details-complete',
  questionnaire: '/questionnaire',
  questionnaireDispute: '/questionnaireDispute',
  collectiveDashboard: '/collective-dashboard',
  assessmentForms: '/assessment-forms',
  formBuilder: '/form-builder',
  formSettings: '/form-settings',
  sections: '/sections',
  minScore: '/min-score',
  questions: '/questions',
  scoring: '/scoring',
  gradingRules: '/grading-rules',
  view: '/view',
  campaigns: '/campaign-management',
  locations: '/locations',
  roleMapping: '/role-mapping',
  vdnConnection: '/vdn-connection',
  form: '/form',
  preview: '/preview',
  userManagement: '/user-management',
  userAllocations: '/user-allocations',
  additionalQuestions: '/additional-questions',
  improvementArea: '/improvement-area',
  dashboard: `/dashboard`,
  campaignDashboard: '/campaign-dashboard',
  calibrationDashboard: '/calibration-dashboard',
  formDashboard: '/form-dashboard',
  calibration: '/calibration',
  withoutCall: '/without-call',
  fromCall: '/from-call',
  initiate: '/initiate',
  session: '/session',
  submit: '/submit',
  own: '/own',
  edit: '/edit',
  draft: '/draft',
  viewSession: '/view-session',
} as const;
