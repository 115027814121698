import { IStore } from '..';
import {
  ICallImportError,
  ICallImportErrorType,
  IPagedCallImportErrors,
} from '../../components/callErrors/IMainSearch';

export const getCallImportErrors = (
  state: IStore,
): Record<ICallImportErrorType, IPagedCallImportErrors> =>
  state.callImportErrorsReducer.importErrorsByType;
