import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const caseEntityReducerInitialState = {
  currentActiveCallSegmentIndex: undefined as number | undefined,
};

const caseEntityReducer = createSlice({
  name: 'caseEntityReducer',
  initialState: caseEntityReducerInitialState,
  reducers: {
    setCurrentActiveCallSegmentIndex: (state, { payload }: PayloadAction<number | undefined>) => {
      state.currentActiveCallSegmentIndex = payload;
    },
  },
});

export const caseEntityActions = caseEntityReducer.actions;

export default caseEntityReducer.reducer;
