import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectOption } from '../../components/shared/helpers/IHelpers';

interface ICallDetailsReducer {
  themeMatchedPhraseToSearch: ISelectOption[] | null;
}

export const callDetailsReducerInitialState: ICallDetailsReducer = {
  themeMatchedPhraseToSearch: null,
};

const callDetailsReducer = createSlice({
  name: 'callDetailsReducer',
  initialState: callDetailsReducerInitialState,
  reducers: {
    setThemeMatchedPhraseToSearch: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.themeMatchedPhraseToSearch = payload;
    },
    clearThemeMatchedPhraseToSearch: (state) => {
      state.themeMatchedPhraseToSearch = null;
    },
  },
});

export const callDetailsActions = callDetailsReducer.actions;

export default callDetailsReducer.reducer;
