import React, { FC, useEffect, useState } from 'react';
import style from './AnnouncementLabel.module.scss';
import { campaignSelectors } from '../../store/selectors';
import { useSelector } from 'react-redux';
import useRegionAnnouncements from '../../hooks/announcements/useRegionAnnouncements';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import LinkButton from '../shared/button/LinkButton';
import { useTranslation } from 'react-i18next';
import { IAnnouncement } from '../../interfaces/announcement';

type Props = {
  showDetails: boolean;
  onShowDetails: (announcement: IAnnouncement) => void;
};

const AnnouncementLabel: FC<Props> = ({ showDetails, onShowDetails }) => {
  useRegionAnnouncements();
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<number>(0);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const announcements = useSelector(campaignSelectors.getCurrentRegionAnnouncements);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    const intervalID = setInterval(() => {
      !isMouseOver &&
        !showDetails &&
        setSelectedAnnouncement(
          (selectedAnnouncement) => (selectedAnnouncement + 1) % announcements.length,
        );
    }, 10000);
    return () => {
      clearInterval(intervalID);
    };
  }, [announcements, isMouseOver, showDetails]);

  return (
    <>
      {announcements && announcements.length > 1 && announcements.length && (
        <MdKeyboardArrowLeft
          className={style.defaultSvg}
          onClick={() =>
            setSelectedAnnouncement(
              (selectedAnnouncement) =>
                (announcements.length + selectedAnnouncement - 1) % announcements.length,
            )
          }
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
      <div className={style.flexWrapper}>
        {announcements && announcements.length > 0 && (
          <label className={style.countLabel}>
            {t('announcement.message')} {selectedAnnouncement + 1}/{announcements.length}
          </label>
        )}
        {announcements && announcements.length > 0 && (
          <label
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={style.label}>
            {announcements[selectedAnnouncement]?.value.length <= 150
              ? announcements[selectedAnnouncement]?.value
              : announcements[selectedAnnouncement]?.value.substr(0, 150) + '...'}
            <LinkButton
              className={style.moreDetails}
              onClick={() => {
                onShowDetails(announcements[selectedAnnouncement]);
              }}>
              {t('announcement.seeFullMessage')}
            </LinkButton>
          </label>
        )}
      </div>
      {announcements && announcements.length > 1 && (
        <MdKeyboardArrowRight
          className={style.defaultSvg}
          onClick={() =>
            setSelectedAnnouncement(
              (selectedAnnouncement) =>
                (announcements.length + selectedAnnouncement + 1) % announcements.length,
            )
          }
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </>
  );
};

export default AnnouncementLabel;
