import { AxiosPromise } from 'axios';

import { axiosInstance } from './axiosClient';
import apiPaths from './apiPaths';
import {
  ICountryRequest,
  ILocation,
  ISiteRequest,
  IValueId,
} from '../components/shared/helpers/IHelpers';
import { PagedSearch } from '../components/shared/interfaces';
import {
  ICountry,
  ICreateSiteRequest,
  ISite,
  TAddCountry,
  TCountryRequest,
  TSiteRequest,
} from '../interfaces/location';

export const locationApi: {
  getLocations: (campaignId?: string) => AxiosPromise<ILocation[]>;
  getAvailableLocations: () => AxiosPromise<ILocation[]>;
  searchSites: (siteRequest: TSiteRequest) => AxiosPromise<PagedSearch<ISite>>;
  searchCountries: (countryRequest: TCountryRequest) => AxiosPromise<PagedSearch<ICountry>>;
  addCountry: (countryRequest: TAddCountry) => AxiosPromise;
  addSite: (siteRequest: ICreateSiteRequest) => AxiosPromise;
  getSites: (siteRequest: ISiteRequest) => AxiosPromise<PagedSearch<IValueId>>;
  getCountries: (countryRequest: ICountryRequest) => AxiosPromise<PagedSearch<IValueId>>;
} = {
  getLocations: (campaignId?: string) => {
    return axiosInstance.get(apiPaths.users + apiPaths.locations, {
      params: {
        campaignId,
      },
    });
  },
  getAvailableLocations: () => {
    return axiosInstance.get(apiPaths.users + apiPaths.availableLocations);
  },
  getSites: (siteRequest: ISiteRequest) => {
    return axiosInstance.post(apiPaths.users + apiPaths.locations + apiPaths.sites, siteRequest);
  },
  getCountries: (countryRequest: ICountryRequest) => {
    return axiosInstance.post(
      apiPaths.users + apiPaths.locations + apiPaths.countries,
      countryRequest,
    );
  },
  searchCountries: (countryRequest) => {
    return axiosInstance.post(apiPaths.countries + apiPaths.search, countryRequest);
  },
  searchSites: (siteRequest) => {
    return axiosInstance.post(apiPaths.sites + apiPaths.search, siteRequest);
  },
  addCountry: (countryRequest) => {
    return axiosInstance.post(apiPaths.countries + apiPaths.create, countryRequest);
  },
  addSite: (siteRequest) => {
    return axiosInstance.post(apiPaths.sites + apiPaths.create, siteRequest);
  },
};
