import React from 'react';

import ErrorComponent from './ErrorComponent';

export class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
  };

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    this.setState({ error });
  }

  render(): React.ReactNode {
    const resetState = (): void => {
      this.setState({ hasError: false });
    };

    const { hasError, error } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorComponent error={error} resetState={resetState} /> : children;
  }
}
