import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAssessmentPlanData,
  IAssessmentPlanReducer,
  IUpdateUserPlanData,
  IUserAssessmentPlanData,
  IUserAssessmentPlanType,
  IUserData,
  IUserPlannedData,
  UserAssessmentPlanType,
} from '../../components/assessmentPlan/IAssessmentPlan';
import { concatUserPlanData } from '../../components/assessmentPlan/assessmentPlanUtils';
import { IAssessmentType } from '../../interfaces/assessment';
import { TPlanId, TUserId } from '../../interfaces/general';
import storageUtils from '../../utils/storageUtils';

export const assessmentPlanReducerInitialState: IAssessmentPlanReducer = {
  assesseePlansData: {},
  assessorPlansData: {},
  assesseeChangedPlanData: {},
  assessorChangedPlanData: {},
  selectedAssessees: [],
  selectedAssessors: [],
  shouldUnplannedAssesseesLoad: false,
  shouldUnplannedAssessorsLoad: false,
  assesseePlannedUserData: [],
  assesseeUnplannedUserData: [],
  assesseeVisibleUserData: [],
  assessorPlannedUserData: [],
  assessorUnplannedUserData: [],
  assessorVisibleUserData: [],
  selectedPlans: [],
  plans: {},
  assessmentPlanGeneralTable: {},
  unbalancedPlanRefreshFlag: false,
  showOnlyPlanned: storageUtils.getLocalStorageValue(
    'assessmentPlanConfig',
    { showOnlyPlanned: [] },
    'object',
  ).showOnlyPlanned,
};

const assessmentPlanReducer = createSlice({
  name: 'assessmentPlanReducer',
  initialState: assessmentPlanReducerInitialState,
  reducers: {
    setAssessmentUserPlansData: (
      state,
      {
        payload: [type, payload],
      }: PayloadAction<
        [IUserAssessmentPlanType, Record<TPlanId, Record<TUserId, IUserAssessmentPlanData>>]
      >,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseePlansData = payload)
        : (state.assessorPlansData = payload);
    },
    updateAssessmentUserPlan: (
      state,
      {
        payload: [type, payload],
      }: PayloadAction<
        [
          IUserAssessmentPlanType,
          {
            planId: string;
            plan: Record<TUserId, IUserAssessmentPlanData>;
          },
        ]
      >,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseePlansData[payload.planId] = payload.plan)
        : (state.assessorPlansData[payload.planId] = payload.plan);
    },
    setAssessmentUserPlan: (
      state,
      {
        payload: [type, payload],
      }: PayloadAction<
        [
          IUserAssessmentPlanType,
          {
            planId: TPlanId;
            values: IUserAssessmentPlanData[];
          },
        ]
      >,
    ) => {
      const planData = type === 'ASSESSEE' ? state.assesseePlansData : state.assessorPlansData;
      planData[payload.planId] = concatUserPlanData(
        payload.planId,
        planData[payload.planId],
        payload.values,
      );
    },
    setSelectedPlans: (state, { payload }: PayloadAction<TPlanId[]>) => {
      state.selectedPlans = payload;
    },
    setAssessmentPlans: (
      state,
      { payload }: PayloadAction<Record<TUserId, IAssessmentPlanData>>,
    ) => {
      state.plans = payload;
    },
    setPlannedUserData: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, IUserData[]]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseePlannedUserData = payload)
        : (state.assessorPlannedUserData = payload);
    },
    setUnplannedUserData: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, IUserData[]]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseeUnplannedUserData = payload)
        : (state.assessorUnplannedUserData = payload);
    },
    concatPlannedUserData: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, IUserData[]]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseePlannedUserData = [...state.assesseePlannedUserData, ...payload])
        : (state.assessorPlannedUserData = [...state.assessorPlannedUserData, ...payload]);
    },
    concatUnplannedUserData: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, IUserData[]]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseeUnplannedUserData = [...state.assesseeUnplannedUserData, ...payload])
        : (state.assessorUnplannedUserData = [...state.assessorUnplannedUserData, ...payload]);
    },
    setChangedPlanData: (
      state,
      {
        payload: [type, payload],
      }: PayloadAction<[IUserAssessmentPlanType, Record<TUserId, number>]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseeChangedPlanData = payload)
        : (state.assessorChangedPlanData = payload);
    },
    setChangedDataByUser: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, IUpdateUserPlanData]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseeChangedPlanData[payload.userId] = payload.planned)
        : (state.assessorChangedPlanData[payload.userId] = payload.planned);
    },
    deleteChangedDataByUser: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, TUserId]>,
    ) => {
      type === 'ASSESSEE'
        ? delete state.assesseeChangedPlanData[payload]
        : delete state.assessorChangedPlanData[payload];
    },
    setSelectedUsers: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, TUserId[]]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.selectedAssessees = payload)
        : (state.selectedAssessors = payload);
    },
    toggleUserSelection: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, TUserId]>,
    ) => {
      type === 'ASSESSEE'
        ? state.selectedAssessees.includes(payload)
          ? (state.selectedAssessees = state.selectedAssessees.filter(
              (assesseeId) => assesseeId !== payload,
            ))
          : state.selectedAssessees.push(payload)
        : state.selectedAssessors.includes(payload)
        ? (state.selectedAssessors = state.selectedAssessors.filter(
            (assessorId) => assessorId !== payload,
          ))
        : state.selectedAssessors.push(payload);
    },
    updateGeneralInfoTable: (
      state,
      {
        payload: [assessmentType, planType, value],
      }: PayloadAction<[IAssessmentType, IUserAssessmentPlanType, number]>,
    ) => {
      state.assessmentPlanGeneralTable[planType][assessmentType].plannedAssessments = value;
    },
    setGeneralInfoTable: (
      state,
      { payload }: PayloadAction<Record<string, Record<string, IUserPlannedData>>>,
    ) => {
      state.assessmentPlanGeneralTable = payload;
    },
    setShouldUnplannedUsersLoad: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, boolean]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.shouldUnplannedAssesseesLoad = payload)
        : (state.shouldUnplannedAssessorsLoad = payload);
    },
    forceRefreshUnbalancedPlan: (state) => {
      state.unbalancedPlanRefreshFlag = !state.unbalancedPlanRefreshFlag;
    },
    setShowOnlyPlanned: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, boolean]>,
    ) => {
      if (type === 'ASSESSEE') {
        if (payload) {
          const plannedUserIds = state.assesseePlannedUserData.map((data) => data.user.id);
          state.selectedAssessees = state.selectedAssessees.filter((userId) =>
            plannedUserIds.includes(userId),
          );

          const plannedUserChangedPlanData: Record<TUserId, number> = {};
          Object.keys(state.assesseeChangedPlanData).forEach((userId) => {
            if (plannedUserIds.includes(userId)) {
              plannedUserChangedPlanData[userId] = state.assesseeChangedPlanData[userId];
            }
          });
          state.assesseeVisibleUserData = [...state.assesseePlannedUserData];
          state.assesseeChangedPlanData = plannedUserChangedPlanData;
        } else {
          state.assesseeVisibleUserData = [
            ...state.assesseePlannedUserData,
            ...state.assesseeUnplannedUserData,
          ];
          state.selectedAssessees = [...state.selectedAssessees];
        }
      } else {
        if (payload) {
          const plannedUserIds = state.assessorPlannedUserData.map((data) => data.user.id);
          state.selectedAssessors = state.selectedAssessors.filter((userId) =>
            plannedUserIds.includes(userId),
          );

          const plannedUserChangedPlanData: Record<TUserId, number> = {};
          Object.keys(state.assessorChangedPlanData).forEach((userId) => {
            if (plannedUserIds.includes(userId)) {
              plannedUserChangedPlanData[userId] = state.assessorChangedPlanData[userId];
            }
          });
          state.assessorVisibleUserData = [...state.assessorPlannedUserData];
          state.assessorChangedPlanData = plannedUserChangedPlanData;
        } else {
          state.assessorVisibleUserData = [
            ...state.assessorPlannedUserData,
            ...state.assessorUnplannedUserData,
          ];
          state.selectedAssessors = [...state.selectedAssessors];
        }
      }

      const planTypes: IUserAssessmentPlanType[] = Object.values(UserAssessmentPlanType).reduce(
        (acc, v) => {
          return (type !== v && state.showOnlyPlanned.includes(v)) || (type === v && payload)
            ? [...acc, v]
            : [...acc];
        },
        [] as IUserAssessmentPlanType[],
      );

      storageUtils.updateLocalStorageValue('assessmentPlanConfig', {
        showOnlyPlanned: planTypes,
      });
      state.showOnlyPlanned = planTypes;
    },
    setVisibleUserData: (
      state,
      { payload: [type, payload] }: PayloadAction<[IUserAssessmentPlanType, IUserData[]]>,
    ) => {
      type === 'ASSESSEE'
        ? (state.assesseeVisibleUserData = payload)
        : (state.assessorVisibleUserData = payload);
    },
  },
});

export const assessmentPlanActions = assessmentPlanReducer.actions;

export default assessmentPlanReducer.reducer;
