import { TLanguageValues } from '../components/languageSelector/constants';
import { TPagedState } from '../reducers/filterSearchReducer';
import { IUserAllocationSearch } from '../interfaces/userAllocation';
import { TAssessmentPlanConfig } from '../components/assessmentPlan/constants';

export const storageKeys = {
  campaignId: 'campaignId',
  userAllocationFilter: 'userAllocationFilter',
  campaignName: 'campaignName',
  campaignReference: 'campaignReference',
  language: 'language',
  authToken: 'authToken',
  formDashboardExportToken: 'formDashboardExportToken',
  campaignDashboardExportToken: 'campaignDashboardExportToken',
  calibrationDashboardExportToken: 'calibrationDashboardExportToken',
  assessmentExportToken: 'assessmentExportToken',
  telephonyLoginExportToken: 'telephonyLoginExportToken',
  userAuditExportToken: 'userAuditExportToken',
  userTicketExportToken: 'userTicketExportToken',
  auditReportExportToken: 'auditReportExportToken',
  b2cToken: 'b2cToken',
  isAuthenticatingWithB2C: 'isAuthenticatingWithB2C',
  assessmentPlanConfig: 'assessmentPlanConfig',
  acknowledgedAnnouncements: 'acknowledgedAnnouncements',
} as const;

interface ILocalStorage {
  [storageKeys.campaignId]: string;
  [storageKeys.campaignName]: string;
  [storageKeys.campaignReference]: string;
  [storageKeys.language]: TLanguageValues;
  [storageKeys.authToken]: string;
  [storageKeys.formDashboardExportToken]: string;
  [storageKeys.campaignDashboardExportToken]: string;
  [storageKeys.calibrationDashboardExportToken]: string;
  [storageKeys.assessmentExportToken]: string;
  [storageKeys.telephonyLoginExportToken]: string;
  [storageKeys.userAuditExportToken]: string;
  [storageKeys.userTicketExportToken]: string;
  [storageKeys.auditReportExportToken]: string;
  [storageKeys.b2cToken]: string;
  [storageKeys.isAuthenticatingWithB2C]: boolean;
  [storageKeys.userAllocationFilter]?: TPagedState<IUserAllocationSearch>;
  [storageKeys.assessmentPlanConfig]?: TAssessmentPlanConfig;
  [storageKeys.acknowledgedAnnouncements]?: string[];
}

const defaultValues: ILocalStorage = {
  campaignId: '',
  campaignName: '',
  campaignReference: '',
  language: 'en',
  authToken: '',
  formDashboardExportToken: '',
  campaignDashboardExportToken: '',
  calibrationDashboardExportToken: '',
  assessmentExportToken: '',
  telephonyLoginExportToken: '',
  auditReportExportToken: '',
  userAuditExportToken: '',
  userTicketExportToken: '',
  b2cToken: '',
  isAuthenticatingWithB2C: false,
  assessmentPlanConfig: { showOnlyPlanned: [] },
  acknowledgedAnnouncements: [],
};

type localStorageKey = typeof storageKeys[keyof typeof storageKeys];

/**
 * Retrieves value stored locally for {@param key} or returns default value
 *
 * @param key
 * @param defaultValue
 * @param typeCast
 */
const getLocalStorageValue = <K extends localStorageKey, T = ILocalStorage[K]>(
  key: K,
  defaultValue: T = (defaultValues[key] as unknown) as T,
  typeCast = 'string',
): T => {
  try {
    const item = localStorage.getItem(key);
    const value = typeCast == 'string' ? item : JSON.parse(item || '');
    if (value != null && typeof value === typeCast) {
      return value as T;
    }
  } catch (e) {
    // do nothing
  }

  return defaultValue;
};
/**
 * Updates value for {@param key} on local storage
 * @param key
 * @param value
 */
const setLocalStorageValue = <K extends localStorageKey, T extends typeof defaultValues[K]>(
  key: K,
  value: T,
): void => {
  localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
};

const updateLocalStorageValue = <K extends localStorageKey, T extends typeof defaultValues[K]>(
  key: K,
  value: T,
): void => {
  localStorage.setItem(
    key,
    typeof value === 'string'
      ? value
      : JSON.stringify({ ...getLocalStorageValue(key, {}, 'object'), ...(value as object) }),
  );
};

/**
 * Removes provided key from storage
 * @param key
 */
const removeLocalStorageValue = <K extends localStorageKey>(key: K): void => {
  localStorage.removeItem(key);
};

/**
 * Clears all user data from the local storage
 */
const clearLocalUserData = (): void => {
  removeLocalStorageValue('campaignName');
  removeLocalStorageValue('campaignId');
  removeLocalStorageValue('campaignReference');
  removeLocalStorageValue('language');
  removeLocalStorageValue('authToken');
  removeLocalStorageValue('formDashboardExportToken');
  removeLocalStorageValue('assessmentExportToken');
  removeLocalStorageValue('userTicketExportToken');
  removeLocalStorageValue('b2cToken');
  removeLocalStorageValue('isAuthenticatingWithB2C');
  removeLocalStorageValue('userAllocationFilter');
  removeLocalStorageValue('assessmentPlanConfig');
};

export default {
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
  updateLocalStorageValue,
  clearLocalUserData,
};
