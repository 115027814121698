import { IStore } from '../index';
import { PagedSearch } from '../../components/shared/interfaces';
import { ICampaignPreviewData } from '../../providers/ICampaignPreviewProvider';
import { IFormList } from '../../interfaces/form';

export const getCampaignPreviews = (state: IStore): PagedSearch<ICampaignPreviewData> =>
  state.campaignReducer.campaignPreviews;

export const getCurrentCampaignId = (state: IStore): string =>
  state.campaignReducer.currentCampaignId;

export const getCurrentCampaignName = (state: IStore): string =>
  state.campaignReducer.currentCampaignName;

export const getCurrentCampaignReference = (state: IStore): string =>
  state.campaignReducer.currentCampaignReference;

export const getFormList = (state: IStore): IFormList[] => state.campaignReducer.formList;

export const getTranscriptAnalysisSettings = (state: IStore) =>
  state.campaignReducer.settings.transcriptAnalysis;

export const getCurrentRegionAnnouncements = (state: IStore) => state.campaignReducer.announcements;

export const getAnnouncementsRefreshFlag = (state: IStore) =>
  state.campaignReducer.announcementsRefreshFlag;
