import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  BrowserUtils,
  ClientAuthError,
  EventType,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { loginRequest, msalInstance, tokenRequest } from './msalConfig';
import {
  AUTHORITY_FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR_CODE,
  LOGIN_REDIRECT_URI,
} from '../../constants/authentication';
import storageUtils from '../../utils/storageUtils';
import { ROUTE_PATHS } from '../../routes/pathConstants';

const getHashForB2C = () =>
  window.location.hash &&
  (window.location.hash.startsWith('#40') || window.location.hash.startsWith('#50'))
    ? ''
    : window.location.hash;

const msalService = {
  getToken: async (): Promise<string> => {
    await msalInstance.handleRedirectPromise(getHashForB2C());
    const account = msalInstance.getActiveAccount();
    if (!account) {
      await msalInstance.loginRedirect({
        redirectUri: ROUTE_PATHS.util.collectiveDashboard,
        ...loginRequest,
      });

      return Promise.reject();
    }

    try {
      const { idToken } = await msalInstance.acquireTokenSilent(tokenRequest);

      return Promise.resolve(idToken);
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError || e instanceof BrowserAuthError) {
        await msalInstance.acquireTokenRedirect({
          redirectUri: ROUTE_PATHS.util.login,
          ...loginRequest,
        });
      }

      return Promise.reject();
    }
  },

  // signin and acquire a token silently with redirect flow.
  signIn: async (): Promise<void> => {
    try {
      const response = await msalInstance.handleRedirectPromise(getHashForB2C());
      if (!response) {
        await msalInstance.loginRedirect({
          state: ROUTE_PATHS.util.login,
          ...loginRequest,
        });
      } else {
        msalInstance.setActiveAccount(response.account);
        storageUtils.setLocalStorageValue('b2cToken', response.idToken);
        window.location.href = response.state || '/';
      }
    } catch (e) {
      const request = { ...loginRequest };
      const error = e as ClientAuthError;
      if (error && error.errorMessage && error.errorMessage.includes(FORGOT_PASSWORD_ERROR_CODE)) {
        request.authority = AUTHORITY_FORGOT_PASSWORD;
      }

      await msalInstance.loginRedirect(request);
    }
  },

  logout: async (idTokenHint: string): Promise<void> => {
    await msalInstance.handleRedirectPromise(getHashForB2C());
    await msalInstance.logoutRedirect({
      account: msalInstance.getActiveAccount(),
      postLogoutRedirectUri: LOGIN_REDIRECT_URI,
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      idTokenHint,
    });
  },
  isLoggedIn: (): boolean => msalInstance.getActiveAccount() !== null,
  getAccount: (): AccountInfo | null => msalInstance.getActiveAccount(),
};

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AuthenticationResult)) {
    const { account, idToken } = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(account);
    storageUtils.setLocalStorageValue('b2cToken', idToken);
  }
});

export default msalService;
